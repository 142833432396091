import jsPDF from "jspdf";
import {loadLogoByRUC} from "../../helpers/funcionesGlobales";
import {Section, Style} from "../../pages/RegistroVentas/ClasesImpresion";
import {decimalAdjust, TIPO_MOV_CAJA} from "../../Global";
import moment from "moment";

export const handleSavePDF = async (data) => {
    let infoPdf = await TraerInfoPDF();
    const mmPageSize = [80, 3276];


    const ptPageSize = mmPageSize.map(coord => coord * 2.83465);
    var doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: ptPageSize
    });

    let nombreArchivo = `${moment(new Date()).format('YYYY_MM_DD-HH_mm')}` + "-" + `${infoPdf[0][0][0].NombreCaja}`;

    const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

    const logoP = loadLogoByRUC(infoPdf[0][0][0].Ruc, 'logo', data.extImg);
    const logo = await logoP;
    doc.addImage(logo, "JPEG", 9, 5, 60, 18);


    const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
    const ComprobanteSection = new Section(
        doc,
        3,
        26,
        ComprobanteStyle,
        74,
        null,
        1
    );

    ComprobanteSection.write(["CIERRE DE CAJA".toUpperCase(), `${infoPdf[0][0][0].NombreCaja}`.toUpperCase()]); //mostrar dos string en el mismo write
    ComprobanteSection.drawBorder(false, true);

    /** Empresa Info**/

    const EmpresaTittleStyle = new Style(9, "normal", 1);
    const EmpresaTittleSection = new Section(
        doc,
        4,
        ComprobanteSection.endY + 3.2,
        EmpresaTittleStyle,
        ComprobanteSection.width
    );

    EmpresaTittleSection.write(`${infoPdf[0][0][0].Ruc}`);
    EmpresaTittleSection.write(`${infoPdf[0][0][0].RazonSocial}`.toUpperCase());

    const EmpresaDataStyle = new Style(7, "normal", 1);
    const EmpresaDataSection = new Section(
        doc,
        EmpresaTittleSection.x,
        EmpresaTittleSection.endY + 3.2,
        EmpresaDataStyle,
        EmpresaTittleSection.width
    );
    EmpresaDataSection.write(`${infoPdf[0][0][0].Direccion}`.toUpperCase());
    EmpresaDataSection.write(`${infoPdf[0][0][0].Nombre}`.toUpperCase());

    const usuarioStyle = new Style(8, "normal", 1);
    const usuarioSection = new Section(
        doc,
        EmpresaDataSection.x,
        EmpresaDataSection.endY + 4,
        usuarioStyle,
        EmpresaDataSection.width
    );

    usuarioSection.write(`FECHA DE EMISIÓN: ${moment(new Date()).format('DD/MM/YYYY HH:mm')}`);
    usuarioSection.write(`USUARIO: ${infoPdf[0][0][0].Usuario}`);
    usuarioSection.write(`EMPLEADO: ${infoPdf[0][0][0].nomEmpleado}`);
    usuarioSection.write(` `);

    const MontosStyle = new Style(10, "bold", 1.3, 'center');
    const MontosSection = new Section(
        doc,
        3,
        usuarioSection.endY + 2,
        MontosStyle,
        pageWidth - 6,
        null,
        1
    );

    MontosSection.write(`            TIPO                             MONTO`);
    MontosSection.drawBorder(false, true);

    const TipoMontoNumberStyle = new Style(9, "normal", 1, 'right');
    const TipoMontoNumberSection = new Section(
        doc,
        4,
        MontosSection.endY + 4,
        TipoMontoNumberStyle,
        MontosSection.width
    );

    const TipoMontoTextoStyle = new Style(9, "normal", 1, 'left');
    const TipoMontoTextoSection = new Section(
        doc,
        4,
        MontosSection.endY + 4,
        TipoMontoTextoStyle,
        MontosSection.width
    );

    let TOTAL = data.MontoFinalEfectivoSistema + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema + data.MontoFinalCreditoSistema;


    TipoMontoTextoSection.write(`${data.MontoFinalEfectivoSistema !== 0 ? "Ventas Efectivo            " + infoPdf[0][0][0].Simbolo : ''}`); //Ventas Efectivo -> Contienen TODAS las ventas realizadas (Incluyendo las anuladas) para levar el contro TOTAL de ventas realizadas (indiferente de si se anularon o no)           ${infoPdf[0][0][0].Simbolo}
    TipoMontoNumberSection.write(`${data.MontoFinalEfectivoSistema !== 0 ? (data.MontoFinalEfectivoSistema) : ''}`);

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaMasterCardSistema !== 0 ? "T. MasterCard              " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${data.MontoFinalTarjetaMasterCardSistema !== 0 ? (data.MontoFinalTarjetaMasterCardSistema) : ''}`);

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaVisaSistema !== 0 ? "T. Visa                          " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${data.MontoFinalTarjetaVisaSistema !== 0 ? (data.MontoFinalTarjetaVisaSistema) : ''}`);

    TipoMontoTextoSection.write(`${data.MontoFinalTarjetaAmericanSistema !== 0 ? "T. American                  " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${data.MontoFinalTarjetaAmericanSistema !== 0 ? (data.MontoFinalTarjetaAmericanSistema) : ''}`);

    TipoMontoTextoSection.write(`${data.MontoFinalOtraTarjetaSistema !== 0 ? "Otra Tarjeta                  " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${data.MontoFinalOtraTarjetaSistema !== 0 ? (data.MontoFinalOtraTarjetaSistema) : ''}`);

    TipoMontoTextoSection.write(` `);
    TipoMontoNumberSection.write(` `);

    let ventasContado = data.MontoFinalEfectivoSistema + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema;

    TipoMontoTextoSection.write(`${ventasContado !== 0 ? "Ventas Contado           " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${ventasContado !== 0 ? (ventasContado) : ''}`);


    TipoMontoTextoSection.write(`${data.MontoFinalCreditoSistema !== 0 ? "Ventas Crédito             " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${data.MontoFinalCreditoSistema !== 0 ? (data.MontoFinalCreditoSistema) : ''}`);

    TipoMontoTextoSection.write(` `);
    TipoMontoNumberSection.write(` `);


    TipoMontoTextoSection.write(`${TOTAL !== 0 ? "TOTAL VENTAS          " + infoPdf[0][0][0].Simbolo : ''}`);
    TipoMontoNumberSection.write(`${TOTAL !== 0 ? (TOTAL) : ''}`);


    const TotalStyle = new Style(8, "normal", 1);
    const TotalSection = new Section(
        doc,
        4,
        TipoMontoNumberSection.endY + 1,
        TotalStyle,
        TipoMontoNumberSection.width
    );

    const letrasTotal = await numeroALetras(decimalAdjust('floor', TOTAL, -2))

    TotalSection.write(`${TOTAL !== 0 ? "SON " + letrasTotal : ''}`);


    const EfectivoEnCajaStyle = new Style(10, "bold", 1, 'center');
    const EfectivoEnCajaSection = new Section(
        doc,
        3,
        TotalSection.endY + 4,
        EfectivoEnCajaStyle,
        pageWidth - 6,
        null,
        1
    );

    EfectivoEnCajaSection.write("MONTO EN CAJA");
    EfectivoEnCajaSection.drawBorder(false, true);


    const DatosTextoStyle = new Style(9, "normal", 1, "left");
    const DatosTextoSection = new Section(
        doc,
        4,
        EfectivoEnCajaSection.endY + 4,
        DatosTextoStyle,
        EfectivoEnCajaSection.width
    );

    const DatosNumberStyle = new Style(9, "normal", 1, "right");
    const DatosNumberSection = new Section(
        doc,
        4,
        EfectivoEnCajaSection.endY + 4,
        DatosNumberStyle,
        EfectivoEnCajaSection.width
    );


    const totalSalidas = data.totales.salidas;
    const totalEntradas = data.totales.entradas;

    DatosTextoSection.write(`${data.MontoInicial !== 0 ? "Monto Inicial                 " + infoPdf[0][0][0].Simbolo : ''}`);
    DatosNumberSection.write(`${data.MontoInicial !== 0 ? (data.MontoInicial) : ''}`);

    DatosTextoSection.write(`${totalEntradas !== 0 ? "Entradas                       " + infoPdf[0][0][0].Simbolo : ''}`);
    DatosNumberSection.write(`${totalEntradas !== 0 ? (totalEntradas) : ''}`);

    DatosTextoSection.write(`${totalSalidas !== 0 ? "Salidas                          " + infoPdf[0][0][0].Simbolo : ''}`);
    DatosNumberSection.write(`${totalSalidas !== 0 ? (totalSalidas) : ''}`);


    DatosTextoSection.write(` `);
    DatosNumberSection.write(` `);

    let efectivoCaja = data.MontoInicial + totalEntradas - totalSalidas;
    let efectivoReal = data.MontoInicial + totalEntradas - data.totales.salidasNormales


    DatosTextoSection.write(`${efectivoReal !== 0 ? "EFECTIVO EN CAJA   " + infoPdf[0][0][0].Simbolo : ''}`);
    DatosNumberSection.write(`${efectivoReal !== 0 ? (efectivoReal) : ''}`);


    const MFisicoTitleStyle = new Style(10, "bold", 1, 'center');
    const MFisicoTitleSection = new Section(
        doc,
        3,
        DatosNumberSection.endY + 4,
        MFisicoTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    MFisicoTitleSection.write("   FISICO CAJA");
    MFisicoTitleSection.drawBorder(false, true);


    const MFisicoTextoStyle = new Style(9, "normal", 1, "left");
    const MFisicoTextoSection = new Section(
        doc,
        4,
        MFisicoTitleSection.endY + 4,
        MFisicoTextoStyle,
        MFisicoTitleSection.width
    );

    const MFisicoNumberStyle = new Style(9, "normal", 1, "right");
    const MFisicoNumberSection = new Section(
        doc,
        4,
        MFisicoTitleSection.endY + 4,
        MFisicoNumberStyle,
        MFisicoTitleSection.width
    );

    let MontoTotalFisico = Number(data.MontoFinalEfectivoFisico) + Number(data.MontoFinalTarjetaMasterCardFisico) +
        Number(data.MontoFinalTarjetaVisaFisico) + Number(data.MontoFinalTarjetaAmericanFisico) +
        Number(data.MontoFinalOtraTarjetaFisico);

    let MontoTotalSistema = data.MontoFinalEfectivoSistema + data.MontoFinalTarjetaMasterCardSistema +
        data.MontoFinalTarjetaVisaSistema + data.MontoFinalTarjetaAmericanSistema +
        data.MontoFinalOtraTarjetaSistema + data.MontoFinalCreditoSistema


    MFisicoTextoSection.write(`${data.MontoFinalEfectivoFisico !== 0 ? "Arqueo(Efec.EnCaja)  " + infoPdf[0][0][0].Simbolo : ''}`);
    MFisicoNumberSection.write(`${data.MontoFinalEfectivoFisico !== 0 ? (data.MontoFinalEfectivoFisico) : ''}`);

    MFisicoTextoSection.write(`${data.MontoFinalTarjetaMasterCardFisico !== 0 ? "T. Mastercard              " + infoPdf[0][0][0].Simbolo : ''}`);
    MFisicoNumberSection.write(`${data.MontoFinalTarjetaMasterCardFisico !== 0 ? (data.MontoFinalTarjetaMasterCardFisico) : ''}`);

    MFisicoTextoSection.write(`${data.MontoFinalTarjetaVisaFisico !== 0 ? "T. Visa                         " + infoPdf[0][0][0].Simbolo : ''}`);
    MFisicoNumberSection.write(`${data.MontoFinalTarjetaVisaFisico !== 0 ? (data.MontoFinalTarjetaVisaFisico) : ''}`);

    MFisicoTextoSection.write(`${data.MontoFinalTarjetaAmericanFisico !== 0 ? "T. American                 " + infoPdf[0][0][0].Simbolo : ''}`);
    MFisicoNumberSection.write(`${data.MontoFinalTarjetaAmericanFisico !== 0 ? (data.MontoFinalTarjetaAmericanFisico) : ''}`);

    MFisicoTextoSection.write(`${data.MontoFinalOtraTarjetaFisico !== 0 ? "Otra Tarjeta                 " + infoPdf[0][0][0].Simbolo : ''}`);
    MFisicoNumberSection.write(`${data.MontoFinalOtraTarjetaFisico !== 0 ? (data.MontoFinalOtraTarjetaFisico) : ''}`);

    const DiferenciaTitleStyle = new Style(10, "bold", 1, 'center');
    const DiferenciaTitleSection = new Section(
        doc,
        3,
        MFisicoNumberSection.endY + 4,
        DiferenciaTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    DiferenciaTitleSection.write("     DIFERENCIA ");
    DiferenciaTitleSection.write("SISTEMA - FISICO");
    DiferenciaTitleSection.drawBorder(false, true);


    const DiferenciaTextoStyle = new Style(9, "normal", 1, "left");
    const DiferenciaTextoSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 4,
        DiferenciaTextoStyle,
        DiferenciaTitleSection.width
    );
    const DiferenciaNumberStyle = new Style(9, "normal", 1, "right");
    const DiferenciaNumberSection = new Section(
        doc,
        4,
        DiferenciaTitleSection.endY + 4,
        DiferenciaNumberStyle,
        DiferenciaTitleSection.width
    );


    DiferenciaTextoSection.write(`${MontoTotalSistema !== 0 ? "M. TOTAL SISTEMA  " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${MontoTotalSistema !== 0 ? (MontoTotalSistema) : ''}`);

    DiferenciaTextoSection.write(`${MontoTotalFisico !== 0 ? "M. TOTAL FISICO     " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${MontoTotalFisico !== 0 ? (MontoTotalFisico) : ''}`);

    DiferenciaTextoSection.write(`${efectivoReal !== 0 ? "Efectivo                      " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${efectivoReal !== 0 ? (efectivoReal) : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico) !== 0 ? "T. Mastercard             " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico) !== 0 ? (data.MontoFinalTarjetaMasterCardSistema - data.MontoFinalTarjetaMasterCardFisico) : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico) !== 0 ? "T. Visa                        " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico) !== 0 ? (data.MontoFinalTarjetaVisaSistema - data.MontoFinalTarjetaVisaFisico) : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico) !== 0 ? "T. American                " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico) !== 0 ? (data.MontoFinalTarjetaAmericanSistema - data.MontoFinalTarjetaAmericanFisico) : ''}`);

    DiferenciaTextoSection.write(`${(data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico) !== 0 ? "Otra Tarjeta                " + infoPdf[0][0][0].Simbolo : ''}`);
    DiferenciaNumberSection.write(`${(data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico) !== 0 ? (data.MontoFinalOtraTarjetaSistema - data.MontoFinalOtraTarjetaFisico) : ''}`);

    const RedondeoTitleStyle = new Style(10, "bold", 1, 'center');
    const RedondeoTitleSection = new Section(
        doc,
        3,
        DiferenciaTextoSection.endY + 4,
        RedondeoTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    RedondeoTitleSection.write("REDONDEOS DEL TURNO");
    RedondeoTitleSection.drawBorder(false, true);

    const RedondeoTextoStyle = new Style(9, "normal", 1, "left");
    const RedondeoTextoSection = new Section(
        doc,
        4,
        RedondeoTitleSection.endY + 4,
        RedondeoTextoStyle,
        RedondeoTitleSection.width
    );

    RedondeoTextoSection.write(`Redondeo Comprobante Aceptados: ${data.redondeoRVAceptados}`);
    RedondeoTextoSection.write(`Redondeo Comprobante Anulados:  ${data.redondeoRVAnulados}`);

    const CorrelativosTitleStyle = new Style(10, "bold", 1, 'center');
    const CorrelativosTitleSection = new Section(
        doc,
        3,
        RedondeoTextoSection.endY + 4,
        CorrelativosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    CorrelativosTitleSection.write("COMPROBANTES EMITIDOS");
    CorrelativosTitleSection.drawBorder(false, true);

    const CorrelativosStyle = new Style(9, "normal", 1);
    const CorrelativosSection = new Section(
        doc,
        4,
        CorrelativosTitleSection.endY + 4,
        CorrelativosStyle,
        CorrelativosTitleSection.width
    );

    if (infoPdf[0][1].length !== 0) {
        infoPdf[0][1].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo}`);
        })
    }
    if (infoPdf[0][2].length !== 0) {
        infoPdf[0][2].map((obj) => {
            CorrelativosSection.write(`${obj.Descripcion}`);
            CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo}`);
        })
    }


    const comprobantesAnuladosTitleStyle = new Style(10, "bold", 1, 'center');
    const comprobantesAnuladosTitleSection = new Section(
        doc,
        3,
        CorrelativosSection.endY + 4,
        comprobantesAnuladosTitleStyle,
        pageWidth - 6,
        null,
        1
    );

    comprobantesAnuladosTitleSection.write("INFO. COMPROBANTES ANULADOS");
    comprobantesAnuladosTitleSection.drawBorder(false, true);

    const compAnuladosStyle = new Style(9, "normal", 1);
    const compAnuladosSection = new Section(
        doc,
        4,
        comprobantesAnuladosTitleSection.endY + 4,
        compAnuladosStyle,
        comprobantesAnuladosTitleSection.width
    );

    const movsCajasAnulados = data.movCajas.filter(m => m.Tipo === TIPO_MOV_CAJA.SALIDA && m.Observacion.includes("Anulación del comprobante"));
    movsCajasAnulados.forEach(m => compAnuladosSection.write(`${m.Observacion}: ${m.Monto} $`))

    let sum = 0;
    movsCajasAnulados.forEach(m => sum += m.Monto);
    compAnuladosSection.write(`Monto Total de comprobantes anulados: ${sum} $`)

    doc.autoPrint(); //Imprimir
    window.open(doc.output("bloburl"), "_blank");

    doc.save(nombreArchivo + ".pdf");
}


async function TraerInfoPDF() {
    try {
        const response = await fetch(`/api/cajas/cierreTurno/InfoPDF/`);
        return await response.json();
    } catch (error) {
        return [];
    }
}

export async function numeroALetras(total) {
    try {
        const response = await fetch(`/api/cajas/numero-letras?total=${total}`);
        let data = await response.json();
        return data.desc;
    } catch (error) {
        return '';
    }
}
