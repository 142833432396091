import React from "react";
import TableToExcel from "@linways/table-to-excel";
import FiltroReportDocSunat from "../ReporteDocSunat/FiltroReportDocSunat";
import {convertObjToQueryParams} from "../../helpers/utils";
import {notificarError} from "../Almacenes/AlmacenNotify";
import moment from "moment";
import {isValidDate} from "../../helpers/validators";
import {decimalAdjust} from "../../Global";

export default class ReporteComprasCont extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            dataCompras: {compras: [], sucursales: ''},
            sucursal: null,
            showSucursales: false,
        }
        this.exportData = this.exportData.bind(this);
        this.onFilterPressedHandler = this.onFilterPressedHandler.bind(this);
        this.setCanChangeSucursal = this.setCanChangeSucursal.bind(this);
    }

    componentDidMount() {
        this.setCanChangeSucursal();
    }

    async setCanChangeSucursal() {
        let res = await fetch('/api/configFactElectronica/ChangeSucursal')
        this.setState({isLoading: true})
        if (res.ok)
            this.setState({showSucursales: true})
        else {
            const data = await this.getSessionSucursal()
            this.setState({showSucursales: false, sucursal: data ? data.sucursal : data})
        }
        this.setState({isLoading: false})
    }

    async getSessionSucursal() {
        let res = await fetch('/api/sucursales/get-session-sucursal')
        if (res.ok)
            return await res.json()
        notificarError("No se pudo obtener la sucursal en sesión")
        return null
    }

    async onFilterPressedHandler(params) {

        if (isValidDate(params.fechaInicio) && isValidDate(params.fechaFin))
            params._sucursales && params._sucursales.length ? await this.doReport(params)
                : notificarError("Debe seleccionar al menos una sucursal.")

        else
            notificarError("Las fechas ingresadas son incorrectas.")

    }

    async doReport(params) {
        const compras = await this.fetchCompras(params)

        await this.setState({
            dataCompras: {
                fechaIncio: params.fechaInicio,
                sucursales: params._sucursales.map(s => s.Nombre).join(','),
                ruc: params._sucursales[0].Ruc,
                periodo: moment(params.fechaInicio).format('MMM-YY'),
                compras: compras ? compras.map(c => {
                    const [serie, nroDoc] = this.extractSerieYDoc(c.numSerie)
                    return {...c, serie: serie, correlativo: nroDoc}
                }) : [],
                fileName: `reporte_compras_${params.fechaInicio || ""}_${params.fechaFin || ""}.xlsx`
            },
            isLoading: true
        })

        this.setState({isLoading: false})
    }

    async exportData() {
        const tablaVentas = document.getElementById('tabla-compras')

        TableToExcel.convert(tablaVentas, {
            name: this.state.dataCompras.fileName, sheet: {
                name: "REPORTE_COMPRAS"
            }
        })
    }

    async fetchCompras(params) {
        let res = await fetch(`/api/contable/reporte-compras?${convertObjToQueryParams(params)}`)
        if (res.ok)
            return await res.json()
        notificarError("No se pudo obtener los datos")
        return null;
    }

    extractSerieYDoc(nroDoc) {
        let i = nroDoc.lastIndexOf('-');
        if (i !== -1) {
            let numDoc = nroDoc.substr(i + 1, nroDoc.length);
            let serie = nroDoc.substr(0, i);
            return [serie, numDoc];
        }
        return ["", nroDoc];
    }

    render() {
        const data = this.state.dataCompras;

        return (
            <div className="container">


                <div className="alert alert-warning">
                    La duración de descarga del reporte es directamente proporcional a la cantidad de días y registros
                    comprendidos entre la fecha de inicio y fin.
                </div>

                <FiltroReportDocSunat showSucursales={this.state.showSucursales} sucursal={this.state.sucursal}
                                      isLoading={this.state.isLoading} customQueryParams={{isForMov: "1"}}
                                      onFilterPressed={this.onFilterPressedHandler}/>


                {this.state.isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status"/>
                    </div>
                ) : null}


                {data.compras.length ?
                    <button onClick={this.exportData} className="boton__verdeOscuro mt-3" style={{float: "right"}}>
                        <i className="fas fa-file-excel"/>
                    </button> :
                    <div className="alert alert-info mt-3">
                        No se han encontrado registros.
                    </div>}

                <table id="tabla-compras" hidden={true}
                       data-cols-width="14,14,14,20,20,20,30,20,20,20,20,20,20,20,20,17,17,17,20,20,20,20,20,20,20,20,20,20">

                    <tr>
                        <td data-f-bold={true}>FORMATO 14.1: REGISTRO DE VENTA E INGRESOS</td>
                    </tr>
                    <tr>

                    </tr>
                    <tr>
                        <td data-f-bold={true}>PERIODO:</td>
                        <td>{data.periodo}</td>
                    </tr>
                    <tr>
                        <td data-f-bold={true}>RUC:</td>
                        <td>{data.ruc}</td>
                    </tr>
                    <tr>
                        <td data-f-bold={true}>APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL:</td>
                        <td>{data.sucursales}</td>
                    </tr>

                    <tr>

                    </tr>

                    <tr data-height="114">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            NÚMERO CORRELATIVO DEL REGISTRO O CÓDIGO ÚNICO DE LA OPERACIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            FECHA DE EMISIÓN DEL COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            FECHA DE VENCIMIENTO O FECHA DE PAGO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={3}>
                            COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} data-a-wrap={true} rowSpan={3}>
                            N° DEL COMPROBANTE DE PAGO, DOCUMENTO, N° DE ORDEN DEL FORMULARIO FÍSICO O VIRTUAL, N° DE
                            DUA, DSI O LIQUIDACIÓN DE CORANZA U OTROS DOCUMENTOS EMITIDOS POR SUNAT PARA ACREDITAR EL
                            CRÉDITO FISCAL EN LA IMPORTACIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={3}>
                            INFORMACIÓN DEL PROVEEDOR
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2} data-a-wrap={true}>
                            ADQUISIONES GRAVADAS DESTINADAS A OPERACIONES GRAVADAS Y/O DE EXPORTACIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2} data-a-wrap={true}>
                            ADQUISIONES GRAVADAS DESTINADAS A OPERACIONES GRAVADAS Y/O DE EXPORTACIÓN Y A OPERACIONES NO
                            GRAVADAS
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2} data-a-wrap={true}>
                            ADQUISIONES GRAVADAS DESTINADAS A OPERACIONES NO GRAVADAS
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            VALOR DE LAS ADQUISICIONES NO GRAVADAS
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            ISC
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            OTROS TRIBUTOS Y CARGOS
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            IMPORTE TOTAL
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3} data-a-wrap={true}>
                            N° DE COMPROBANTE DE PAGO EMITIDO POR SUJETO NO DOMICILIADO (2)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2} data-a-wrap={true}>
                            CONSTANCIA DE DEPÓSITO DE DETRACCIÓN
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={3}>
                            TIPO DE CAMBIO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={4} data-a-wrap={true}>
                            REFERENCIA DEL COMPROBANTE DE PAGO O DOCUMENTO ORIGINAL QUE SE MODIFICA
                        </td>
                    </tr>

                    <tr data-height="35">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            TIPO (TABLA 10)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            SERIE O CÓDIGO DE LA DEPENDENCIA ADUANERA (TABLA 10)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            AÑO DE EMISIÓN DE LA DUA O DSI
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} colSpan={2}>
                            DOCUMENTO DE IDENTIDAD
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2} data-a-wrap={true}>
                            APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            BASE IMPONIBLE
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            IGV
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            BASE IMPONIBLE
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            IGV
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            BASE IMPONIBLE
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            IGV
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            NÚMERO
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true} rowSpan={2}>
                            FECHA EMISIÓN
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle">
                            FECHA
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle" data-a-wrap={true}>
                            TIPO (TABLA(10))
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle">
                            SERIE
                        </td>
                        <td data-f-bold={true} rowSpan={2} data-a-h="center" data-a-v="middle" data-a-wrap={true}>
                            N° DEL COMPROBANTE DE PAGO O DOCUMENTO
                        </td>
                    </tr>

                    <tr data-height="35">
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true}>
                            TIPO (TABLA 2)
                        </td>
                        <td data-a-h="center" data-a-v="middle" data-f-bold={true}>
                            NÚMERO
                        </td>
                    </tr>


                    {data.compras.map((c, i) =>
                        <tr key={`data_row_${i}`}>
                            <td>
                                {`00${i + 1}-${moment(data.fechaInicio).format('MM-YYYY')}`}
                            </td>
                            <td>
                                {moment(c.fechaEmision).format("DD/MM/YYYY")}
                            </td>
                            <td>
                                {c.fechaPago ? moment(c.fechaPago).format('DD/MM/YYYY') : ""}
                            </td>
                            <td>
                                {c.codigo}
                            </td>
                            <td>
                                {c.serie}
                            </td>
                            <td>

                            </td>
                            <td>
                                {c.correlativo}
                            </td>
                            <td>
                                {c.rucProveedor ? c.tipoDocProveedor : ""}
                            </td>
                            <td>
                                {c.rucProveedor ? c.rucProveedor : ""}
                            </td>
                            <td>
                                {c.rucProveedor ? c.rznSocialProveedor : ""}
                            </td>
                            <td>
                                {decimalAdjust('floor', c.gravados, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', c.igv, -2)}
                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>
                                {decimalAdjust('floor', c.exonerados + c.inafectos, -2)}
                            </td>
                            <td>

                            </td>
                            <td>
                                {decimalAdjust('floor', c.icbper, -2)}
                            </td>
                            <td>
                                {decimalAdjust('floor', c.total, -2)}
                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>
                                {c.tipoCambio ? decimalAdjust('floor', c.tipoCambio, -2) : ""}
                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                        </tr>
                    )}

                </table>


            </div>
        );
    }

}
