import React from "react";
import PageLoading from "../PageLoading.js";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import '../styles/Kardex.css';
import {BotonLetras, BotonUp, BotonCancelar} from "../Plantillas/Botones.js";
import {notificationStockInicial} from "./KardexNotify.js";
import "react-moment";
import moment from "moment";
import TablaStockInicial from "./TablaStockInicial.js";

class StockInicialSC extends React.Component {
    constructor() {
        super()
        this.state = {
            data: {
                productos: []
            },
            isLoading: true,
            error: null,
            IdProducto: null,
            stockInicial: "",
            disableButton: false,
            idSucursal: null
        }
        this.handleChange = this.handleChange.bind(this)
        this.getProductos = this.getProductos.bind(this)
        this.renderEditable = this.renderEditable.bind(this)
        this.AddMA = this.AddMA.bind(this)
    }

    componentDidMount() {
        this.getProductos();
        //this.setState({ isLoading: true });
    }

    async getProductos() {

        try {
            const response = await fetch("/api/kardex/stocksProductosSC");
            const data = await response.json();

            this.setState({
                isLoading: false,
                data: data,
                idSucursal: data.idSucursal
            });
        } catch (error) {
            this.setState({isLoading: false, error: error});
        }
    }

    handleChange(e) {
        const {name, value} = e.target;
        //console.log(name,value)
        this.setState({
            [name]: parseFloat(value)
        });

    }

    async AddMA(item) {
        if (item.Stock > 0) {
            this.setState({disableButton: true});
            try {
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        IdMovimientoAlmacen: 1,
                        Numero: "",
                        FechaRegistro: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                        FechaMovimiento: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                        Glosa: "Stock Inicial sin Comprobante",
                        IdTipoTransaccionAlmacen: 16,
                        IdAlmacen: this.state.idSucursal,
                        IdTipoDocumentoSunat: 48,
                        NroDocumentoSunat: null,
                        IdOrdenCompra: null,
                        NroOrdenCompra: null,
                        Estado: "Procesado",
                        IdProveedor: null,
                        idTipoMov: 1
                    })
                };
                const responseMA = await fetch(`/api/movimientosalmacen/add/`, config);
                const rpta = await responseMA.json();
                this.AddDetalleMA(parseInt(rpta[0][0][0].estado), item)
            } catch (error) {
                this.setState({
                    isLoading: false,
                    disableButton: false,
                    error: error,
                });
            }
        }
    }

    async AddDetalleMA(IdMovimientoAlmacen, item) {
        try {
            const config = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    IdDetalleMovimientosAlmacen: "1",
                    FechaCaducidad: null,
                    IdMovimientoAlmacen: IdMovimientoAlmacen,
                    IdProducto: item.IdProducto,
                    IdLote: null,
                    IdPresentacion: item.IdPresentacion,
                    IdUnidad: null,
                    IdStock: item.IdStock,
                    Cantidad: parseFloat(item.Stock),
                    Precio: parseFloat(item.Precio),
                    Total: parseFloat(item.Precio) * parseFloat(item.Stock),
                    idTipoTransaccion: 16
                })
            };
            const response = await fetch(`/api/movimientosalmacen/detallemovimiento/add/`, config);
            const rpta = await response.json();
            this.AddStock(item)
        } catch (error) {
            this.setState({
                isLoading: false,
                disableButton: false,
                error: error,
            });
            console.log(error)
        }
    }

    async AddStock(item) {

        if (parseFloat(item.Stock) > 0) {
            try {
                fetch(`/api/kardex/editStockCompromPreV/${item.IdStock}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        variacionStock: parseFloat(item.Stock)
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                this.ProductoConStockInicial(item)
            } catch (error) {
                this.setState({
                    isloading: false,
                    error: error,
                    disableButton: false,
                });
                console.log(error)
            }
        }
    }

    async ProductoConStockInicial(item) {
        try {
            let res = await fetch(`/api/productos/editProdStockInicial`, {
                method: "PUT",
                body: JSON.stringify({
                    IdStock: item.IdStock
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            await this.getProductos()
            this.setState({disableButton: false})

        } catch (error) {
            this.setState({
                isloading: false,
                error: error,
                disableButton: false
            });
        }
    }

    async ProcesarTodo() {
        const items = this.state.data.productos

        for (let i = 0; i < items.length; i++) {
            this.AddMA(items[i])
        }

    }

    renderEditable = (cellInfo) => {
        const cellValue = this.state.data.productos[cellInfo.index][cellInfo.column.id];

        return (
            <React.Fragment>
                <input
                    placeholder="stock inicial"
                    name="input"
                    type="number"
                    step="any"
                    min="0"
                    onChange={this.handleInputChange.bind(null, cellInfo)}
                    onFocus={(e) => e.target.select()}
                    value={cellValue}
                    style={{width: "100%"}}
                />
            </React.Fragment>
        );
    };

    handleInputChange = (cellInfo, event) => {
        let data = this.state.data.productos;
        data[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState(this.state.data);

    };

    render() {
        if (this.state.data.length == 0) {
            return (
                <React.Fragment>
                    <div>
                        <PageLoading/>
                    </div>
                </React.Fragment>
            )
        } else {
            const data = this.state.data.productos
            return (
                <React.Fragment>
                    <div className="ProcesarBoton mt-1">
                        <BotonCancelar
                            onClick={this.props.history.goBack}
                            className="ml-4"
                        />
                        {/*<BotonLetras*/}
                        {/*    onClick={() => this.ProcesarTodo()*/}
                        {/*        .then(() => notificationStockInicial())}*/}
                        {/*    className="mr-4"*/}
                        {/*>Procesar Todo*/}
                        {/*</BotonLetras>*/}
                    </div>
                    <div className="kardex__container">
                        <div className="kardex">
                            <div className="KardexDocumento">
                                <TablaStockInicial
                                    data={data}
                                    renderEditable={this.renderEditable}
                                    AddMA={this.AddMA}
                                    disableButton={this.state.disableButton}
                                />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

export default StockInicialSC;
