import React from "react";
import {decimalAdjust, TIPO_STOCK} from "../../Global";

class ListaProductosPreventa extends React.Component {


    render() {
        const showStock = 'showStock' in this.props ? this.props.showStock : true;
        return (
            <React.Fragment>
                <div id="tableProdPreventa" className="lista_productos">
                    <div className="lista_productos__item">
                        <table  hidden={this.props.load} className="table table-hover">
                            <thead>
                            <tr>
                                <th>Producto/Presentación</th>
                                <th>Precio</th>
                                {showStock ? <th>Stock</th> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.productos.map(p => {
                                    const preVentaStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                        p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE_PREVENTA).Stock :
                                        p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE_PREVENTA).Stock;

                                    const currentStock = p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ?
                                        p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE).Stock :
                                        p.stocks.find(s => s.IdTipoStock === TIPO_STOCK.SIN_COMPROBANTE).Stock;

                                    const stock = (currentStock - preVentaStock) / p.TipoCambio;
                                    
                                    return (
                                        <tr onClick={() => this.props.AddProductoInList(p)}
                                            className="prodPreventaTr"
                                            style={{cursor: "pointer"}}
                                            key={`prod-${p.IdProducto}-${p.IdPresentacion}`}
                                            title={`${p.descripcion}-${p.unidadMedida}`}
                                        >
                                            <td style={{fontSize: "14px"}}
                                                bgcolor={p.IdTipoStock === TIPO_STOCK.CON_COMPROBANTE ? "" : "#f78b2d"}>
                                                {p.descripcion} {p.unidadMedida}
                                            </td>
                                            <td bgcolor={"#01a59c"}
                                                style={{padding: 0}}>

                                                <label
                                                    style={{color: "white"}}>{p.moneda}</label>

                                                <div className="col-12">
                                                    <div style={{color: "white", textAlign: "end"}}>
                                                        {decimalAdjust('floor', p.precio, -2)}
                                                    </div>
                                                </div>
                                            </td>
                                            {showStock ? (
                                                <td style={{
                                                    backgroundColor: "rgb(53,96,90,0.18)",
                                                    borderTop: "1px solid white"
                                                }}>
                                                    <div style={{textAlign: "end"}}>
                                                        {
                                                            decimalAdjust('floor', stock, -2)
                                                        }
                                                    </div>
                                                </td>
                                            ) : null}
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ListaProductosPreventa;
