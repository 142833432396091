import React from "react";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError, notificarMsg} from "../Almacenes/AlmacenNotify";
import PreInvoiceForm from "./PreInvoiceForm";
import _ from "lodash";

export default class PreInvoiceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            conf: null
        }
    }

    componentDidMount() {
        this.fetchConfiguration();
    }

    fetchConfiguration() {
        pfetch({
            fetch: {
                url: "/api/pre-invoice/config",
                method: "GET",
                headers: defaultGetHeaders(),
            },
            before: () => this.setState({isLoading: true}),
            then: (data) => this.setState({conf: data}),
            finally: () => this.setState({isLoading: false}),
            error: () => notificarError("Ha ocurrido un error al momento de extraer la configuración")
        })
    }

    render() {
        if (this.state.isLoading || _.isNull(this.state.conf))
            return <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                </div>
            </div>

        return (
            <div className="preventa__container">
                <PreInvoiceForm conf={this.state.conf} onSuccessAdd={(v) => {
                    notificarMsg("Se ha realizado con éxito")
                    this.props.history.push(`/pagos/${v.IdPreventa}`)
                }}/>
            </div>
        );
    }
}
