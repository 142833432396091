import React from "react";
import {BotonAddCliente, BotonSearchCliente} from "../Plantillas/Botones";
import ModalCliente from '../clientes/ClienteModal';
import ClienteNuevo from "../clientes/ClientesNuevo";
import Modal from "../Modal";
import ModalBuscarCliente from "../Preventas/ModalBuscarCliente";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {notificarError} from "../Almacenes/AlmacenNotify";

export default class ClientPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showAddClient: false,
            showSearchClient: false,
            doc: "",
            isLoading: false
        }
        this.onSelectClient = this.onSelectClient.bind(this)
        this.onSearchChange = this.onSearchChange.bind(this)
    }

    componentDidMount() {
        this.fetchClient("99999999")
    }

    onSelectClient(c) {
        this.setState({showAddClient: false, showSearchClient: false})
        let cli = {...c}
        if (Object.keys(cli).length) {
            cli.RazonSocial = cli.RazonSocial && cli.RazonSocial.length ? cli.RazonSocial : `${cli.PrimerNombre} ${cli.SegundoNombre} ${cli.ApellidoPaterno} ${cli.ApellidoMaterno}`
            this.props.onSelectClient(cli)
        }
    }

    onSearchChange(e) {
        const val = e.target.value;
        this.setState({
            doc: val
        })
        if (val.length === 8 || val.length === 11)
            this.fetchClient(val)
    }

    fetchClient(val) {
        pfetch({
            fetch: {
                url: `/api/preVentas/traerCliente/${val}`,
                method: "GET",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.onSelectClient(d[0]),
            error: () => notificarError("No se pudo buscar al cliente"),
            finally: () => this.setState({isLoading: false})
        })
    }

    render() {
        return (
            <>
                <BotonAddCliente
                    onClick={() => this.setState({showAddClient: true})}
                    className="mr-2"
                    title="Nuevo Cliente"
                />

                <BotonSearchCliente
                    className="ml-2 mr-2"
                    onClick={() => this.setState({showSearchClient: true})}
                    title="Buscar Cliente en la lista"
                />

                <input
                    className="DocClientePreventa mr-2"
                    style={{width: "30%"}}
                    onChange={this.onSearchChange}
                    type="text"
                    value={this.state.doc}
                    placeholder="DNI/RUC"
                    disabled={this.state.isLoading}
                />

                <ModalCliente isOpen={this.state.showAddClient}
                              onClose={() => this.setState({showAddClient: false})}
                              title="NUEVO CLIENTE">
                    <ClienteNuevo onClose={() => this.setState({showAddClient: false})}
                                  AsignarValorDoc={this.onSelectClient}
                    />
                </ModalCliente>

                <Modal title="BUSCAR CLIENTE" isOpen={this.state.showSearchClient}
                       onClose={() => this.setState({showSearchClient: false})}>
                    <ModalBuscarCliente
                        GetCliente={this.onSelectClient}
                    />
                </Modal>

            </>
        );
    }

}
