import React, {Component} from "react";
// import logo from "../componentes/images/mifacturaperu.png";
import "./styles/Dashboard.css";
import "react-moment";
import moment from "moment";
import {PieChart, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Pie} from 'recharts';
import {round} from "../Global";
import {
    BarChart, Bar, Cell, Legend,
} from 'recharts';

const RADIAN = Math.PI / 180;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        const conf = JSON.parse(window.localStorage.getItem('extraConf'));
        this.moneda = conf.moneda
        this.state = {
            TotalVentas: null,
            TotalProductosAgotarStock: null,
            TotalProductosVales: null,
            ReporteVentasRango: [],
            dataVentasDia: [],
            dataSemanas: [],
            dataVentasPorMeses: [],
            FechaInicio: moment().add(-30, 'days'),
            FechaFin: new Date(),
            MesActual: new Date().getMonth()
        };

        this.getTotalVentasTurnoEmpresa = this.getTotalVentasTurnoEmpresa.bind(
            this
        );
        this.getTotalProductosValesEmpresa = this.getTotalProductosValesEmpresa.bind(
            this
        );
        this.getTotalProductosPorAgotarStockEmpresa = this.getTotalProductosPorAgotarStockEmpresa.bind(
            this
        );

        this.handleVerProductosAgotarStock = this.handleVerProductosAgotarStock.bind(
            this
        );

        this.handleIrProcesarVales = this.handleIrProcesarVales.bind(
            this
        );

        this.handleVerVentasTurnosActivosEmpresa = this.handleVerVentasTurnosActivosEmpresa.bind(
            this
        );

        this.handleVerProductosAgotarStock = this.handleVerProductosAgotarStock.bind(
            this
        );
        this.handleIrProductosMasVendidos = this.handleIrProductosMasVendidos.bind(
            this
        );
        this.handleReportesStock = this.handleReportesStock.bind(
            this
        );
        this.getReporteVentasRango = this.getReporteVentasRango.bind(this);
        this.getVentasDelDia = this.getVentasDelDia.bind(this);
        this.getVentasPorMeses = this.getVentasPorMeses.bind(this);
        this.getVentasPorSemanas = this.getVentasPorSemanas.bind(this);
    }

    componentDidMount() {
        let Inicio = moment(this.state.FechaInicio).format("YYYY-MM-DD");
        let Fin = moment(this.state.FechaFin).format("YYYY-MM-DD");
        this.mesLetras(Fin)
        this.getVentasDelDia(Fin);
        this.getVentasPorSemanas(Fin)
        this.getVentasPorMeses(Fin)

        this.getReporteVentasRango(Inicio, Fin);

        this.getTotalVentasTurnoEmpresa();
        this.getTotalProductosValesEmpresa();
        this.getTotalProductosPorAgotarStockEmpresa();
    }

    mesLetras = () => {
        var month = new Array();
        month[0] = "Enero";
        month[1] = "Febrero";
        month[2] = "Marzo";
        month[3] = "Abril";
        month[4] = "Mayo";
        month[5] = "Junio";
        month[6] = "Julio";
        month[7] = "Agosto";
        month[8] = "Setiembre";
        month[9] = "Octubre";
        month[10] = "Noviembre";
        month[11] = "Diciembre";
        this.setState({
            MesActual: month[this.state.MesActual]
        })
    }

    getVentasDelDia = async (Hoy) => {
        try {
            const response = await fetch(
                `/api/dashboard/get/ventasDia/${Hoy}`
            );
            const ReporteVentasDia = await response.json();
            this.setState({
                dataVentasDia: ReporteVentasDia.respuesta,
                isLoading: false
            });

            return ReporteVentasDia;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getVentasPorSemanas = async (Hoy) => {
        try {
            const response = await fetch(
                `/api/dashboard/get/ventasSemanas/${Hoy}`
            );
            const ReporteVentasSemana = await response.json();
            this.setState({
                dataSemanas: ReporteVentasSemana.respuesta,
                isLoading: false
            });

            return ReporteVentasSemana;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getVentasPorMeses = async (Hoy) => {
        try {
            // const year = moment(Hoy).format("YYYY")
            const response = await fetch(
                `/api/dashboard/get/ventasMeses/${Hoy}`
            );
            const ReporteVentasPorMeses = await response.json();
            this.setState({
                dataVentasPorMeses: ReporteVentasPorMeses.respuesta,
                isLoading: false
            });

            return ReporteVentasPorMeses;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getReporteVentasRango(Inicio, Fin) {
        try {
            const response = await fetch(
                `/api/ventas/reporte/ventas/fecha/${Inicio}/${Fin}`
            );
            const ReporteVentasRango = await response.json();
            console.log(ReporteVentasRango)
            this.setState({
                ReporteVentasRango: ReporteVentasRango.respuesta,
                isLoading: false
            });

            return ReporteVentasRango;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalVentasTurnoEmpresa() {
        try {
            const response = await fetch(`/api/dashboard/get/totalventas`);
            const TotalVentas = await response.json();
            this.setState({
                TotalVentas: TotalVentas.respuesta[0].Total,
                isLoading: false
            });
            return TotalVentas;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalProductosValesEmpresa() {
        try {
            const response = await fetch(`/api/dashboard/get/totalproductosvales`);
            const TotalProductosVales = await response.json();
            this.setState({
                TotalProductosVales: TotalProductosVales.respuesta[0].Total,
                isLoading: false
            });
            return TotalProductosVales;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getTotalProductosPorAgotarStockEmpresa() {
        try {
            const response = await fetch(
                `/api/dashboard/get/totalproductosagotarstock`
            );
            const TotalProductosAgotarStock = await response.json();
            this.setState({
                TotalProductosAgotarStock: TotalProductosAgotarStock.respuesta[0].Total,
                isLoading: false
            });
            return TotalProductosAgotarStock;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    handleVerProductosAgotarStock() {
        this.props.history.push("/dashboard/productosporagotarstock/")
    }

    handleIrProcesarVales() {
        this.props.history.push("/procesarvales/")
    }

    handleVerVentasTurnosActivosEmpresa() {
        this.props.history.push("/dashboard/ventas/turnosactivos/empresa")
    }

    handleIrProductosMasVendidos() {
        this.props.history.push("/dashborad/productosmasvendidos")
    }

    handleReportesStock() {
        this.props.history.push("/dashborad/reportesstock")
    }


    render() {

        const reporteChart = this.state.ReporteVentasRango.map(venta => {
            return {FechaEmision: venta.FechaEmision, Total: round(venta.Total, 2)};
        });

        const reporteDia = this.state.dataVentasDia.map(venta => {
            return {name: 'Ventas del Dia', Facturas: venta.Facturas, Boletas: venta.Boletas, Vales: venta.Vales,}
        });

        const reporteSemana = this.state.dataSemanas.map(venta => {
            return {name: venta.Semana, Ventas: venta.Ventas, Compras: venta.Compras}
        });

        const reporteMeses = this.state.dataVentasPorMeses.map(venta => {
            return {name: venta.Mes, Compras: venta.Compras, Ventas: venta.Total, Vales: venta.Vales,}
        });

        const renderBarChartVentasDia = (
            <BarChart
                width={400}
                height={250}
                data={reporteDia}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="Facturas" fill="#43C23F"/>
                <Bar dataKey="Boletas" fill="#3182C8"/>
                <Bar dataKey="Vales" fill="#ffc658"/>
            </BarChart>
        )

        const renderLineChartVentaMes = (
            <LineChart
                width={400}
                height={250}
                data={reporteSemana}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend/>
                <Line type="monotone" dataKey="Ventas" stroke="#3182C8" activeDot={{r: 5}}/>
                <Line type="monotone" dataKey="Compras" stroke="#F86816" activeDot={{r: 5}}/>
            </LineChart>
        )

        const renderBarChartMeses = (
            <BarChart
                width={700}
                height={250}
                data={reporteMeses}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
            >
                <CartesianGrid stroke='#f5f5f5'/>
                <YAxis/>
                <XAxis dataKey="name"/>
                <Tooltip/>
                <Legend/>
                <Bar dataKey="Ventas" name="Ventas F/B" fill="#43C23F"/>
                <Bar dataKey="Vales" fill="#3182C8"/>
                <Bar dataKey="Compras" fill="#ffc658"/>

            </BarChart>
        )

        return (
            <div className="dashboard">
                <div className="dashboardGraphicsBotons">
                    <div className="dashboard__graphics">
                        <div className="dashboard__graphicsDiaMes">
                            <div className="dashboard__graphicsDia m-3">
                                <div className="cardGraphics">
                                    <h4 className="mt-2">VENTAS DEL DIA</h4>
                                    {renderBarChartVentasDia}
                                </div>
                            </div>
                            <div className="dashboard__graphicsDia m-3">
                                <div className="cardGraphics">
                                    <h4 className="mt-2">{this.state.MesActual}</h4>
                                    {renderLineChartVentaMes}
                                </div>
                            </div>
                        </div>
                        <h4 className="align-self-center mt-3">VENTAS 3 ÚLTIMOS MESES </h4>
                        {renderBarChartMeses}
                    </div>
                </div>
                <div className="dashboardBotons">
                    <h4 className="dashboardBotonsEncabezado">REPORTES PERSONALIZADOS</h4>
                    {/* <div className="dashboard__content"> */}
                    <div type="button" className="dashboard__content--item-g" title="Haz clic para ver detalles"
                         onClick={this.handleVerVentasTurnosActivosEmpresa}>
                        <div className="d-flex">
                            <i className="fas fa-cash-register"></i>
                            <span className="align-self-center">Ventas de los turnos activos</span>
                        </div>
                        <span>
                {this.state.TotalVentas &&
                this.moneda.Simbolo + " " + this.state.TotalVentas.toLocaleString("en", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })}
              </span>
                    </div>
                    {/* <div className="dashboard__content--item-d">
              <div>
              <i className="fas fa-boxes"></i>
                Total de Compras registradas en los turnos activos
              </div>
            </div> */}
                    <div type="button" className="dashboard__content--item-d" title="Haz click para ver detalles"
                         onClick={this.handleVerProductosAgotarStock}>
                        <div>
                            <i className="fas fa-boxes"></i>
                            Productos Agotados o Próximos en Agotar Stock
                        </div>
                        <span>
                {this.state.TotalProductosAgotarStock &&
                this.state.TotalProductosAgotarStock}
              </span>
                    </div>
                    <div className="dashboard__content--item-g" type="button" title="Haz click para ir a Procesar Vales"
                         onClick={this.handleIrProcesarVales}>
                        <div>
                            <i className="fas fa-hourglass-end"></i>
                            Productos Pendientes en Procesar Vales
                        </div>
                        <span>
                {this.state.TotalProductosVales &&
                this.state.TotalProductosVales}
              </span>
                    </div>

                    <div type="button" className="dashboard__content--item-d" title="Haz click para ver detalles"
                         onClick={this.handleIrProductosMasVendidos}>
                        <div>
                            <i className="fas fa-barcode"></i>
                            Productos más Vendidos
                        </div>

                    </div>

                    <div type="button" className="dashboard__content--item-g" title="Haz click para ver detalles"
                         onClick={this.handleReportesStock}>
                        <div>
                            <i className="fas fa-boxes"></i>
                            Reportes de Stocks
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;
