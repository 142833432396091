import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageLoading from "../componentes/PageLoading";
import Layout from '../Layout';

const RequiereLogin = ({component: Component, ...props}) => {

    const sesion = useSelector(state => state.sesion);

    switch (sesion.isLoggedIn) {
        case true: return <Layout sesion={sesion}><Component sesion={sesion} {...props}/></Layout>
        case null: return <PageLoading/>
        case false: return <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location.pathname }
                                }}
                            />;
        default:
    }
    
}

const RouterConLogin = ({component, ...props}) => <Route {...props} render={ routeProps => <RequiereLogin component={component} {...routeProps} />} />

export default RouterConLogin;