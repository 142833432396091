import {useState, useEffect} from 'react';
import apiFetch from './apiFetch';

const fetchRoles = async (setRoles, signal) => {
    const {data_tables, error} = await apiFetch("/api/roles", ["_estado_roles_list"], {signal});

    if (error) return;
    const [rolesList] = data_tables;
    const roles = rolesList.map(  (Rol) => {
        Rol.Empresas = JSON.parse(Rol.Empresas);
        return Rol;
    });
    setRoles(roles);
}

/**
 * Hook que devuelve la lista de roles en un estado.
 *
 * Hace un request a `"/api/roles"`, lo guarda en un estado, y lo devuelve
 * @param {*} initialState - Estado inicial a devolver antes de que finalice el fetch
 * @param {Array<*>} deps - Array de dependencias que se le pasan como 2do argumento a `useEffect`
 * @returns {Array<Objects>} Listado de roles o `initialState`
 */
const useRoles = (initialState=null, deps=[]) => {
    const [roles, setRoles] = useState(initialState);

    useEffect( () => {
        const controller = new AbortController();
        fetchRoles(setRoles, controller.signal);
        return () => controller.abort()
    }, deps)

    return roles;
}

export default useRoles;