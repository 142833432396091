import React, {Component} from "react";
import printA4 from "../../helpers/A4";
import "./styles/Pagos.css";
import printTicket from "./Ticket";
import swal from "sweetalert";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import "react-moment";
import moment from "moment";
import {BotonCancelar2} from "../../componentes/Plantillas/Botones";
import {BotonLetras} from "../../componentes/Plantillas/Botones";

class DetalleRegistroVenta extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Venta: [],
            ValidarImpresion: null,
            isLoading: null,
            error: null,
            Pagos: [],
            DetallesVenta: [],
            respuestaDeleteRegistroVenta: null,
            extImg: 'png',
            cuentasBancarias: [],
        };
        this.getVenta = this.getVenta.bind(this);
        this.getVentaJSX = this.getVentaJSX.bind(this);
        this.getPagos = this.getPagos.bind(this);
        this.getDetalleVenta = this.getDetalleVenta.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handlePrintTicket = this.handlePrintTicket.bind(this);
        this.handleOnDeleteVenta = this.handleOnDeleteVenta.bind(this);

        this.consultarConfiguracioImprimeA4 = this.consultarConfiguracioImprimeA4.bind(this); //Analizar si es posible ponerlo global y en cada impresion revisar
        this.regresarAtras = this.regresarAtras.bind(this);
        this.fetchCuentasBancarias = this.fetchCuentasBancarias.bind(this);
    }

    componentDidMount() {
        this.fetchCuentasBancarias();
        this.consultarConfiguracioImprimeA4();
        this.getVenta();
        this.getPagos();
        this.getDetalleVenta();
    }

    async fetchCuentasBancarias() {
        this.setState({loading: true});
        let res = await fetch('/api/cuentas/');
        if (res.ok)
            this.setState({cuentasBancarias: await res.json()})
        this.setState({loading: false});
    }

    consultarConfiguracioImprimeA4 = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/ventas/revisar/ImprimirA4`);
            const ImprimirA4 = await response.json();
            this.setState({
                ValidarImpresion: ImprimirA4.ImprimirA4[0].Estado,
                extImg: ImprimirA4.extImg
            })
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    handleOnDeleteVenta(IdRegistroVenta) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea anular ésta Venta?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.deleteRegistroVenta(IdRegistroVenta);
                await this.getVenta(IdRegistroVenta);
            }
        };
    }

    async deleteRegistroVenta(IdRegistroVenta) {
        await fetch(`/api/ventas/delete/${IdRegistroVenta}`, {
            method: "POST",
            body: JSON.stringify({
                IdRegistroVenta: IdRegistroVenta
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});
                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeleteRegistroVenta: responseData.respuesta});
                if (this.state.respuestaDeleteRegistroVenta === true) {
                    swal(
                        "¡Anulada!",
                        "¡La Venta ha sido anulada satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
    }

    async getVenta() {


        var IdRegistroVenta = this.props.match.params.IdRegistroVenta;

        await fetch(`/api/ventas/get/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(Venta =>
                this.setState({
                    Venta: Venta.respuesta[0],
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getVentaJSX(venta) {
        if (venta) {
            return (
                <div className="container mt-5 ml-5 mr-5">
                    <div className="form-group ml-5 mr-5">
                        <label className="">Fecha</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={moment(venta.FechaEmision).format("DD-MM-YYYY")}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Moneda</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={venta.Abreviatura}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Cliente RUC</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={venta.NroTipoDocumento}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Razón Social</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={venta.RazonSocial}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Sucursal</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={venta.Sucursal}
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>

                    <div className="form-group ml-5 mr-5">
                        <label className="">Total</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Total &&
                                venta.Total.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Redondeo</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Redondeo &&
                                venta.Redondeo.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Total Redondeado</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.TotalRedondeo &&
                                venta.TotalRedondeo.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Descuento</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.DescuentoTotal &&
                                venta.DescuentoTotal.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Vuelto</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.VueltoRedondeo &&
                                venta.VueltoRedondeo.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>

                    <div className="form-group ml-5 mr-5">
                        <label className="">Gravadas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Gravadas &&
                                venta.Gravadas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Inafectas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Inafectas &&
                                venta.Inafectas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Exoneradas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Exoneradas &&
                                venta.Exoneradas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">Gratuitas</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.Gratuitas &&
                                venta.Gratuitas.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">IGV</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.IGV &&
                                venta.IGV.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">ICBPER</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.ICBPER &&
                                venta.ICBPER.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">ISC</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.ISC &&
                                venta.ISC.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group ml-5 mr-5">
                        <label className="">IVAP</label>
                        <input
                            type="text"
                            name="Nombre"
                            value={
                                venta.IVAP &&
                                venta.IVAP.toLocaleString("en", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })
                            }
                            className="input__linea"
                            required
                            readOnly
                        />
                    </div>
                </div>
            );
        }
    }

    getPagosJSX(pagos) {
        if (pagos) {
            return (
                <div className="mt-5 ml-3 mr-3">
                    <ReactTable
                        id="table-pagos"
                        data={this.state.Pagos}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Monto",
                                        id: "Monto",
                                        accessor: d =>
                                            d.Monto.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Monto"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Tipo Pago",
                                        id: "TipoPago",
                                        accessor: d => d.TipoPago,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["TipoPago"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "FechaPago",
                                        id: "FechaPago",
                                        accessor: d => d.FechaPago,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["FechaPago"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Estado",
                                        id: "Estado",
                                        accessor: d => d.Estado,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Estado"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Referencia",
                                        id: "Referencia",
                                        accessor: d => d.Referencia,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Referencia"]
                                            }),
                                        filterAll: true
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            );
        }
    }

    async getPagos() {
        const IdRegistroVenta = this.props.match.params.IdRegistroVenta;
        try {
            const response = await fetch(`/api/pagos/get/${IdRegistroVenta}`);
            const pagos = await response.json();
            this.setState({
                Pagos: pagos.respuesta,
                isLoading: false
            });
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    getDetalleVenta() {
        var IdRegistroVenta = this.props.match.params.IdRegistroVenta;

        fetch(`/api/ventas/detalles/${IdRegistroVenta}`)
            .then(response => response.json())
            .then(DetallesVenta =>
                this.setState({
                    DetallesVenta: DetallesVenta.respuesta,
                    isLoading: false
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    getDetalleVentaJSX(items) {
        if (items) {
            return (
                <div className="mt-5 mr-3 ml-3">
                    <ReactTable
                        id="table-productos-venta"
                        data={this.state.DetallesVenta}
                        filterable
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value
                        }
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Cantidad",
                                        id: "Cantidad",
                                        accessor: d => d.Cantidad,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {keys: ["Cantidad"]}),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Nombre Producto",
                                        id: "NombreProducto",
                                        accessor: d => d.NombreProducto,
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["NombreProducto"]
                                            }),
                                        filterAll: true
                                    },
                                    {
                                        Header: "Precio",
                                        id: "PrecioVenta",
                                        accessor: d =>
                                            d.PrecioVenta.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["PrecioVenta"]
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterAll: true
                                    },
                                    {
                                        Header: "Importe",
                                        id: "Importe",
                                        accessor: d =>
                                            d.Importe.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            }),
                                        filterMethod: (filter, rows) =>
                                            matchSorter(rows, filter.value, {
                                                keys: ["Importe"]
                                            }),
                                        style: {
                                            textAlign: "right"
                                        },
                                        filterAll: true
                                    }
                                ]
                            }
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                        previousText="Anterior"
                        nextText="Siguiente"
                        noDataText="No se encontraron registros"
                        loadingText="Cargando..."
                        ofText="de"
                        rowsText="filas"
                        pageText="Página"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                    />
                </div>
            );
        }
    }

    async handleSavePDF() {
        printA4(this.state.DetallesVenta, this.state.Venta, this.state.extImg, this.state.cuentasBancarias);
    }

    async handlePrintTicket() {
        printTicket(this.state.DetallesVenta, this.state.Venta, this.state.extImg);
    }

    regresarAtras() {
        this.props.history.goBack();
    }

    render() {

        return (
            <>
                <div className="detalles__venta">
                    {this.props.history.get}
                    <BotonCancelar2
                        onClick={this.regresarAtras}
                        title="Lista de Ventas"
                        tabindex="0"
                    />
                    <div className="opciones__venta">
                        <h4 className="mr-5 align-self-center">
                            {String(this.state.Venta.Serie).toUpperCase() +
                            " - " +
                            this.state.Venta.NumeroComprobante +
                            " - " +
                            "ESTADO: " +
                            this.state.Venta.Estado}
                        </h4>
                        {/*{this.state.Venta.Estado === "ACEPTADO" && (*/}
                        {/*    <BotonLetras*/}
                        {/*        className="boton__Rojo--letras ml-1 mr-1"*/}
                        {/*        onClick={() => this.props.history.push(`/notascredito/new/${this.props.match.params.IdRegistroVenta}`)}*/}
                        {/*    >*/}
                        {/*        Nota de Crédito*/}
                        {/*    </BotonLetras>*/}
                        {/*)}*/}

                        <button
                            // hidden={this.state.ValidarImpresion == null? true: this.state.ValidarImpresion == 1? false:true}
                            className="boton__verdeOscuro--letras ml-1 mr-1"
                            onClick={this.handleSavePDF}
                            title="Haz click para imprimir"
                        >
                            Imprimir A4 {<i className="fas fa-print"></i>}
                        </button>
                        <button
                            // hidden={this.state.ValidarImpresion == null? true: this.state.ValidarImpresion == 0? false:true}
                            className="boton__verdeClaro--letras ml-1 mr-1"
                            onClick={this.handlePrintTicket}
                            title="Haz click para imprimir"
                        >
                            Imprimir Ticket {<i className="fas fa-print"></i>}
                        </button>
                    </div>

                    <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a
                                class="nav-link font-weight-bold text-uppercase text-secondary active"
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                            >
                                {
                                    String(this.state.Venta.Serie).toUpperCase() +
                                    " - " +
                                    this.state.Venta.NumeroComprobante +
                                    " - " +
                                    "Estado: " +
                                    (this.state.Venta.Estado === null || 'Pendiente')}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link  text-secondary"
                                id="pills-profile-tab"
                                data-toggle="pill"
                                href="#pills-profile"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                            >
                                Pagos
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link  text-secondary"
                                id="pills-contact-tab"
                                data-toggle="pill"
                                href="#pills-contact"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                            >
                                Productos
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                        >
                            <div>{this.getVentaJSX(this.state.Venta)}</div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                        >
                            {this.getPagosJSX(this.state.Pagos)}
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-contact"
                            role="tabpanel"
                            aria-labelledby="pills-contact-tab"
                        >
                            {this.getDetalleVentaJSX(this.state.DetallesVenta)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DetalleRegistroVenta;
