import React from "react";
import PageLoading from "../../componentes/PageLoading";
import swal from "sweetalert";
import {
    ICBPER,
    CONTADO,
    RUC,
    BOLETA,
    FACTURA,
    TRIBUTO,
    IVAP
} from "../../Global";

import Modal from "../../componentes/Modal.js";
import {
    notificationPrecioCosto, notificationStock0,
    notificationFacturaRUC, notificationClienteNoExiste
    , notificationPreProcesarValeVacia, notificationCantidadNegativa,
    notificationConfigBoletaRUC, notificationBoletaVarios,
    notificationPrecioMenorAICBPER, notificationNoHayProducto
} from '../../componentes/Preventas/PreVentaNotify'
import PreProcesarValesModalDescuentos from "./PreProcesarValesModalDescuentos.js";

import printA4 from "../../helpers/A4";
import printTicket from "./Ticket";
import BuscarClientePorDoc from '../../componentes/Preventas/BuscarClientePorDoc'
import ListaProductos from '../../componentes/Preventas/ListaProductos'
import ModalCliente from '../../componentes/clientes/ClienteModal';
import ClienteNuevo from '../../componentes/clientes/ClientesNuevo';
import calcularVenta, {setPreciosYDescuento, setValorUnitario} from "../../helpers/calcularVenta.js";
import ProcesarValesListCarrito from "./ProcesarValesListCarrito";
import ModalBuscarCliente from "../../componentes/Preventas/ModalBuscarCliente";
import {BotonAdd} from "../../componentes/Plantillas/Botones";
import Notify from "../../componentes/Notify";
import ModalImprimirReporte from "./ModalImprimirReporte";
import {enviaremail} from "../../helpers/funcionesGlobales";
import {crearDocPDF} from "../../helpers/A4";

class PreProcesarValesEdit extends React.Component {

    constructor(props) {
        super(props);
        const extraConf = JSON.parse(window.localStorage.getItem('extraConf'))
        this.state = {
            statusModalSearchCliente: false,
            loading: true,
            error: null,
            buscar: "",
            Documento: "",
            dataCliente: [],
            IdCliente: "",
            IdEmpresa: "",
            Estado: "",
            IdPreProcesarVale: "",
            Total: 1,
            dataProductos: {
                productosVale: []
            },
            dataProductosPreMostrar: { //dataDetalle.respuesta
                respuesta: []
            },
            detallePreProcesarVale: {  //this.state.detallePreventa.detalleItem
                productosVale: []
            },

            IdRegistroVenta: null,
            Venta: null,
            DetallesVenta: [],
            aperturarCaja: false,
            BoletaRuc: "",
            IdMoneda: extraConf.moneda.IdMoneda,
            Gravado: 0,
            Inafecto: 0,
            ICBPERPreProcesarVale: 0,
            Exonerado: 0,
            IGVPreProcesarVale: 0,
            Gratuitas: 0,
            IVAP: 0,
            ISC: 0,
            totalMonto: 0,
            TasaIGV: "",
            TasaICBPER: "",
            Alias: "",
            RUSS: "",
            IdTipoDocumentoSunat: "",
            ItemDescuento: "",
            ClickPagar: true,
            statusModalDescuento: false,
            statusModalImprimirReporte: false,
            extImg: 'png'
        }

        this.getProductosPorProcesar = this.getProductosPorProcesar.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.AddProductoInList = this.AddProductoInList.bind(this)
        this.RemoveProductoInList = this.RemoveProductoInList.bind(this)
        this.AgregarCantidadDet = this.AgregarCantidadDet.bind(this)
        this.RestarCantidadDet = this.RestarCantidadDet.bind(this)
        this.onChangeCheckBoxGratuito = this.onChangeCheckBoxGratuito.bind(this)
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this)
        this.handleClickBuscar = this.handleClickBuscar.bind(this)
        this.handleCloseBuscar = this.handleCloseBuscar.bind(this)
        this.handleCloseImprimirReporte = this.handleCloseImprimirReporte.bind(this)
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handlePrintTicket = this.handlePrintTicket.bind(this);
        this.handleEnterKeyUp = this.handleEnterKeyUp.bind(this);
        this.asignarTurno = this.asignarTurno.bind(this);
    }

    componentDidMount = async () => {
        this.asignarTurno();
        const IdPreProcesarVale = this.props.match.params.IdPreProcesarVales;
        this.getConfigBoletasRuc();
        this.getRUSS();
        this.getPreProcesarValesById(IdPreProcesarVale)
            .then(() => {
                this.TraerClientePorDoc(this.state.Documento)
                    .then(() => {
                        this.getProductosPorProcesar()
                            .then(() => {
                                this.getDetallePreProcesarVales(IdPreProcesarVale)
                            })
                    })
            })
        this.intervalo = setInterval(this.getProductosPorProcesar, 1000)
    };


    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    asignarTurno = async () => {
        const response = await fetch(`/api/vales/asignarTurno`);
        const data = await response.json();
        // console.log(data.respuesta)
        this.setState({
            aperturarCaja: data.respuesta
        })
        if (data.respuesta == false) {
            Notify("Por favor, debe aperturar una caja", "error", 2000)
            this.props.history.push('/AperturarTurnosMovcaja')

        }
    }

    getConfigBoletasRuc = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/getConfEmpresa/${45}`);
            const data = await response.json();
            this.setState({BoletaRuc: data[0].Estado, extImg: data[1]})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    handleCloseImprimirReporte = () => {
        this.setState({
            statusModalImprimirReporte: false
        })
        this.props.history.push(`/registrosventas`)
    }

    async handleSavePDF() {
        await printA4(this.state.DetallesVenta, this.state.Venta, this.state.extImg).then(Notify("Abriendo... espere por favor.", "success", 1500)).then(this.handleCloseImprimirReporte())

    }

    async handlePrintTicket() {
        await printTicket(this.state.DetallesVenta, this.state.Venta, this.state.extImg).then(Notify("Abriendo... espere por favor.", "success", 1500)).then(this.handleCloseImprimirReporte())
    }

    getRUSS = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/getEmpresaByUser`);
            const data = await response.json();
            this.setState({RUSS: data[0].EsRuss})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getPreProcesarValesById(IdPreProcesarVale) {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(
                `/api/gestionPreProcesarVales/preProcesarVales/${IdPreProcesarVale}`
            );

            const preProcesarVale = await response.json();
            this.setState({
                IdPreProcesarVale: preProcesarVale.respuesta[0].IdPreProcesarVale,
                Total: preProcesarVale.respuesta[0].Total,
                IdCliente: preProcesarVale.respuesta[0].IdCliente,
                IdEmpresa: preProcesarVale.respuesta[0].IdEmpresa,
                Estado: preProcesarVale.respuesta[0].Estado,
                TasaICBPER: preProcesarVale.respuesta[0].TasaICBPER,
                TasaIGV: preProcesarVale.respuesta[0].TasaIGV,
                Alias: preProcesarVale.respuesta[0].Alias,
                Documento: preProcesarVale.respuesta[0].NroTipoDocumento,
                loading: false,
                IdTipoDocumentoSunat: preProcesarVale.respuesta[0].IdTipoDocumentoSunat
            })
            this.saveClienteInProcesarVale(this.state.IdCliente)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }

    }

    TraerClientePorDoc = async (DNI) => {
        try {
            await fetch(`/api/preVentas/traerCliente/${DNI}`)
                .then(res => res.json())
                .then(data => {

                    this.setState({
                        loading: false,
                        /**SI EXISTE EL CLIENTE ES = A DATA[0] Y SI NO, ES 0*/
                        dataCliente: data[0] ? data[0] : 0,
                        IdCliente: data[0] ? data[0].IdCliente : 0,
                        /**VACIA LA LISTA DE PRODUCTOS POR CLIENTE PARA LUEGO
                         * VOLVERLA A LLENAR CON EL NUEVO IDCLIENTE A TRAER
                         */
                        // StockPorCliente:[]
                    });
                    // this.getProductosPorCliente()
                });

            this.saveClienteInProcesarVale(this.state.IdCliente)
            // console.log(this.state.dataCliente)
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    saveClienteInProcesarVale = async (cliente) => {
        // console.log(this.state.IdPreProcesarVale)
        try {
            await fetch(`/api/vales/saveCliente/${this.state.IdPreProcesarVale}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdCliente: cliente
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(err => console.log(err));
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    getProductosPorProcesar = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/productosVale`);
            const productos = await response.json();
            // console.log("getProductosPorProcesar ", data)
            this.setState({
                dataProductos: productos
            });

            // this.AgregarCamposList(data.productosVale)
            await this.TributosInProductos(this.state.dataProductos.productosVale)
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getDetallePreProcesarVales(IdPreProcesarVale) {

        try {
            const response = await fetch(`/api/gestionPreProcesarVales/detallepreProcesarVale/${IdPreProcesarVale}`);
            const data = await response.json();
            //   console.log(data)
            this.setState({
                loading: false,
                dataProductosPreMostrar: data
            });
            //   console.log(this.state.dataProductosPreMostrar.respuesta)
            this.MostrarListaDetalle(this.state.dataProductosPreMostrar.respuesta)
        } catch (error) {
            this.setState({loading: false, error: error});
        }

    }

    TributosInProductos = async (productos) => {
        for (let i = 0; i < productos.length; i++) {
            productos[i].Tributos = JSON.parse(productos[i].Tributos)
            const itemCalculado = setPreciosYDescuento(productos[i])
            productos[i].Precio = itemCalculado.PrecioVenta
            productos[i].Gratuito = parseInt(productos[i].Gratuito)
        }
        this.setState(this.state.dataProductos)
        // console.log(this.state.dataProductos)
    }


    MostrarListaDetalle = async (listaItems) => {
        // console.log("listaItems ", listaItems, "this.state.dataProductos.productos", this.state.dataProductos.productosVale)
        for (let i = 0; i < listaItems.length; i++) {
            const item = this.state.dataProductos.productosVale.find(findProducto => {
                return (
                    findProducto.IdStock == listaItems[i].IdStock &&
                    findProducto.IdPresentacion == listaItems[i].IdPresentacion
                )
            })
            if (item) {
                let detProd = this.state.dataProductosPreMostrar.respuesta.map(dp => ({
                    ...dp,
                    mensaje: "",
                    IdPrecioPlantilla: item.IdPrecioPlantilla,
                    Simbolo: item.Simbolo,
                    Tributos: JSON.parse(listaItems[i].Tributos),
                    TasaISC: item.TasaISC,
                    // PrecioEspecial:FindPrecioEspecial(this.state.dataProductos.productos,listaItems[i]),
                    // PrecioFamiliar:FindPrecioFamiliar(this.state.dataProductos.productos,listaItems[i]),
                    // PrecioCosto:GetPrecioCosto(this.state.dataProductos.productos,listaItems[i]),
                    // PrecioMenor:FindPrecioMenor(this.state.dataProductos.productos,listaItems[i]),
                    checked: listaItems[i].Gratuito == 1 ? true : false
                }))
                this.state.detallePreProcesarVale.productosVale.push(detProd[i])
            }
        }

        this.setState(this.state.detallePreProcesarVale)
        //console.log(this.state.detallePreProcesarVale)
        this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
    }

    handleChange = (e) => {
        this.setState({
            buscar: e.target.value
        });
    }

    DocChange = (e) => {
        this.setState({
            Documento: e.target.value
        }, () => {
            if (this.state.Documento.length == 8 || this.state.Documento.length == 11 ||
                this.state.Documento.length == 9) {
                this.TraerClientePorDoc(this.state.Documento)
                if (this.state.Documento.length == 8 || this.state.Documento.length == 9) {
                    this.handleTipoComprobante(4)
                }
            }
        });
    }


    getBotonTipoComprobante1 = (IdTipoDocumentoSunat, NombreDocSunat) => {
        /********LOS BOTONES DE BOLETA Y FACTURA SE MUESTRAN NORMAL**** */
        if (IdTipoDocumentoSunat != FACTURA) {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        className={
                            this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                ? "preventa__tipo--comprobante--item--active"
                                : "preventa__tipo--comprobante--item"
                        }
                        value={this.state.IdTipoDocumentoSunat}
                        onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                    >
                        {NombreDocSunat}
                    </button>
                </React.Fragment>
            )
        } else {
            /******PARA EL BOTON FACTURA, SI NO ES RUSS LO MUESTRA, SINO NO MUESTRA NADA**** */
            if (this.state.RUSS == "Inactivo") {
                return (
                    <React.Fragment>
                        <button
                            type="button"
                            className={
                                this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                    ? "preventa__tipo--comprobante--item--active"
                                    : "preventa__tipo--comprobante--item"
                            }
                            value={this.state.IdTipoDocumentoSunat}
                            onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                        >
                            {NombreDocSunat}
                        </button>
                    </React.Fragment>
                )
            }
        }
    }

    setActiveInput = (e, item, inputName) => {
        //console.log(typeof item.Cantidad)
        /*item.PrecioVenta = item.PrecioVenta.toLocaleString("en", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            })*/
        // e.target.select()
        // this.setState({
        //     inputName: inputName,
        //     item:item
        // }, () => {
        //     this.state.inputTeclado.Cantidad=item.Cantidad
        //     this.state.inputTeclado.PrecioVenta=item.PrecioVenta

        //     this.setState(this.state.inputTeclado)
        // });
    }

    AddProductoInList = async (item) => {

        //VER SI HAY STOCK DISPONIBLE
        const Stock = this.StockDisponible(item)

        if (Stock >= 1) {

            this.state.dataProductosPreMostrar.respuesta.push(item) //dataDetalle.respuesta
            this.setState(this.state.dataProductosPreMostrar)

            const listaItems = this.state.detallePreProcesarVale.productosVale //this.state.detallePreventa.detalleItem

            //ENCONTRAR EL ELEMENTO EN EL ARRAY DEL DETALLE
            const findItemArray = listaItems.find(findItem => {
                /**SI EL IDSTOCK E IDPRESENTACION SON IGUALES ENTONCES YA EXISTE
                 * EN EL ARRAY Y RETORNA ESE ITEM********************************/
                return (findItem.IdStock == item.IdStock && findItem.IdPresentacion == item.IdPresentacion
                )
            })

            /*******SI EL ELEMENTO NO EXISTE EN EL ARRAY, LO AGREGA*********/
            if (!findItemArray) {
                /****NUEVO ARRAY CON CANTIDAD, MONTO  Y OTROS AGREGADO AL ELEMENTO****/
                let detProd = this.state.dataProductosPreMostrar.respuesta.map(dp => ({
                    ...dp,
                    // Stock:dp.Stock / dp.TipoCambio, //Aca se hace la conversion por tipo de cambio solo que se va a mostrar en pantalla porProcesar
                    Cantidad: 1,
                    PrecioVenta: Math.round(dp.Precio * 100) / 100,
                    Total: dp.Precio,
                    mensaje: "",
                    IdDetallePreProcesarVales: "",
                    Tributos: dp.Tributos,
                    checked: 0
                    // SubTotal:0,
                    // añadirProcesar:true, //Este va a evaluar si se procesa el producto o no. (disabled => true)
                }))
                /**SE AGREGA AL STATE SOLO EL ULTIMO ELEMENTO DE DETPROD QUE ES EL ULTIMO AGREGADO** */
                this.state.detallePreProcesarVale.productosVale.push(detProd[detProd.length - 1])
                this.setState(this.state.detallePreProcesarVale)
                this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
                this.AddDetallePreProcesarVales(detProd[detProd.length - 1])

            } else {//SI YA EXISTE, AUMENTA EN CANTIDAD 1
                // console.log(findItemArray)
                findItemArray.Cantidad++
                findItemArray.Total = findItemArray.Cantidad * findItemArray.PrecioVenta
                this.setState(this.state.detallePreProcesarVale)
                this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
                this.ActualizarDetallePreProcesarVales(findItemArray)
                this.ActualizarStockValePreProcesarVale(item, -1)
            }
            this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
            this.getProductosPorProcesar()
            // console.log("aaaaaaaaaa", this.state.dataProductosMostrar)
        } else {
            notificationStock0(item)
        }
    }

    ActualizarDetallePreProcesarVales = async (item) => {

        try {
            // console.log("item.IdDetallePreProcesarVales ", item)
            await fetch(`/api/vales/editDet/${item.IdDetallePreProcesarVales}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdPreProcesarVale: this.state.IdPreProcesarVale,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    PrecioVenta: item.PrecioVenta,
                    Precio: item.PrecioReferencial,
                    ValorUnitario: item.ValorUnitario,
                    Descuento: item.Descuento,
                    Gratuito: item.Gratuito,
                    IdAfectacionIgv: item.IdAfectacionIgv
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }).then(res => res.json())
                .then(data => console.log(data))
                .catch(err => {
                    console.log(err);
                    this.ActualizarDetallePreProcesarVales(item)
                })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    AddDetallePreProcesarVales = (item) => {
        this.setState({loading: true, error: null});
        try {
            fetch("/api/vales/addDetalle", {
                method: "POST",
                body: JSON.stringify({
                    IdPreProcesarVale: this.state.IdPreProcesarVale,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    IdAfectacionIgv: item.IdAfectacionIgv,
                    Descuento: 0,
                    PrecioReferencial: item.Precio,
                    PrecioVenta: item.PrecioVenta,
                    TipoCambio: item.TipoCambio,
                    IdAlmacen: item.IdAlmacen,
                    ValorUnitario: item.ValorUnitario
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }).then(res => res.json())
                .then(data => {
                    /*TRAE EL NUEVO IDDETALLEPREVENTA, RESULTADO DEL STOREDPROCEDURE*/
                    // console.log(data)
                    item.IdDetallePreProcesarVale = data
                    this.setState(this.state.detallePreProcesarVale)
                })
                .catch(err => console.log(err));
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
        this.ActualizarStockValePreProcesarVale(item, -1)

    }


    ActualizarStockValePreProcesarVale = async (item, variacionStock) => {
        const listaProducto = this.state.dataProductos.productosVale
        let findStockVale = ""
        findStockVale = listaProducto.find(findItem => {
            return (findItem.IdProducto == item.IdProducto && findItem.IdPresentacion == item.IdPresentacion
                && findItem.IdAlmacen == item.IdAlmacen
                && findItem.IdPrecioPlantilla == item.IdPrecioPlantilla)
        })
        // console.log(findStockVale)
        try {
            await fetch(`/api/vales/editStockValePreProcesarVales/${findStockVale.IdStock}`, {
                method: "PUT",
                body: JSON.stringify({
                    variacionStock: variacionStock * item.TipoCambio
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    montoTotalDetalle = (items) => {

        const lista = items.map(item => {
            const {
                ValorUnitario,
                PrecioVenta,
                Tributos,
                TasaISC,
                Cantidad,
                Gratuito,
                Descuento,
                IdAfectacionIgv
            } = item
            return ({
                ValorUnitario,
                PrecioVenta,
                Tributos,
                TasaISC,
                Cantidad,
                Gratuito,
                Descuento,
                IdAfectacionIgv
            })
        })

        const PreProcesarVales = {
            Items: lista
        }
        // //
        const ventaCalculada = calcularVenta(PreProcesarVales)
        // //

        this.setState({
            Gravado: ventaCalculada.Gravadas.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            Inafecto: ventaCalculada.Inafectas.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            Exonerado: ventaCalculada.Exoneradas.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            IGVPreProcesarVale: ventaCalculada.IGV.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            Gratuitas: ventaCalculada.Gratuitas.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            totalMonto: ventaCalculada.Total,
            ICBPERPreProcesarVale: ventaCalculada.ICBPER.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            IVAP: ventaCalculada.IVAP.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }),
            ISC: ventaCalculada.ISC.toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        }, () => this.ActualizarPreProcesarValesMontoInDB())

    }

    async handleGenerarPreProcesarVales(IdPreProcesarVale) {
        this.setState({
            ClickPagar: false
        })
        if (this.state.BoletaRuc == 1) {
            if (this.state.dataCliente.IdTipoDocumento == RUC && this.state.IdTipoDocumentoSunat == BOLETA) {
                const willGenerar = await swal({
                    title: "¿Está seguro?",
                    text: "¿Generar una Boleta con RUC?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    closeOnClickOutside: false
                });
                if (willGenerar) {
                    this.GenerarPreProcesarVales(IdPreProcesarVale)
                } else {
                    this.setState({
                        ClickPagar: true
                    })
                }
            } else {
                this.GenerarPreProcesarVales(IdPreProcesarVale)
            }
        } else {
            if (this.state.dataCliente.IdTipoDocumento == RUC && this.state.IdTipoDocumentoSunat == BOLETA) {
                notificationConfigBoletaRUC()
                this.setState({
                    ClickPagar: true
                })
            } else {
                this.GenerarPreProcesarVales(IdPreProcesarVale)
            }
        }

    }

    GenerarPreProcesarVales = async (IdPreProcesarVale) => {
        console.log(IdPreProcesarVale)
        this.setState({loading: true, error: null});
        if (this.state.detallePreProcesarVale.productosVale.length == 0) {
            notificationPreProcesarValeVacia()
            this.setState({
                ClickPagar: true
            })
        } else {
            if (this.state.IdCliente != 0) {
                if (this.state.dataCliente.NroTipoDocumento == "99999999"
                    && this.state.IdTipoDocumentoSunat == BOLETA && this.state.totalMonto >= 700) {
                    notificationBoletaVarios()
                    this.setState({
                        ClickPagar: true
                    })
                } else {
                    try {
                        await fetch(`/api/vales/GenerarPreProcesarVale/${IdPreProcesarVale}`, {
                            method: "PUT",
                            body: JSON.stringify({
                                Estado: "GENERADO",
                                IdCliente: this.state.IdCliente,
                                Total: this.state.totalMonto,
                                Alias: this.state.Alias,
                                IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                            }),
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json"
                            }
                        })
                            .then(res => res.json())
                            .then(async (respuesta) => {
                                this.setState({
                                    Estado: "GENERADO"
                                })
                                console.log(respuesta)
                                Notify("Procesando... por favor esperar", "warning", 2000);
                                // console.log(respuesta)
                                const ventacalculada = await this.calcularVenta(respuesta) //Descuento = Input Descuento
                                // console.log(ventacalculada)
                                await this.registrarVenta(ventacalculada)

                            }) // /pagos/${IdPreProcesarVale}
                            .catch(err => console.log(err));

                    } catch (error) {
                        this.setState({
                            loading: false,
                            error: error
                        });
                    }
                }
            } else {
                notificationClienteNoExiste()
                this.setState({
                    ClickPagar: true
                })
            }
        }
    }

    async registrarVenta(ventaCalculada) {
        await fetch("/api/ventas/new", {
            method: "POST",
            body: JSON.stringify({
                IdCliente: this.state.IdCliente,
                IdTipoOperacion: this.state.IdPreProcesarVale,
                TipoTabla: "PreProcesarVales",
                Estado: this.state.Estado,
                IdModalidadPago: CONTADO,
                IdTipoComprobante: this.state.IdTipoDocumentoSunat,
                IdMoneda: this.state.IdMoneda,
                TipoCambio: this.state.TipoCambio,
                TasaIGV: this.state.TasaIGV,
                TasaICBPER: this.state.TasaICBPER,
                DescuentoTotal: ventaCalculada.Descuento,
                Gravadas: ventaCalculada.Gravadas,
                Exoneradas: ventaCalculada.Exoneradas,
                Inafectas: ventaCalculada.Inafectas,
                ICBPER: ventaCalculada.ICBPER,
                Exportacion: 0.0,
                Total: ventaCalculada.Total,
                Vuelto: 0.0,
                Observacion: "",
                IGV: ventaCalculada.IGV,
                ISC: ventaCalculada.ISC,
                Gratuitas: ventaCalculada.Gratuitas,
                PorcentajeDescuento: ventaCalculada.PorcentajeDescuento,
                IVAP: ventaCalculada.IVAP,
                TasaIVAP: ventaCalculada.TasaIVAP,
                TasaISC: ventaCalculada.ISC,
                Redondeo: 0
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(this.setState({isLoading: false}))
            .then(response => response.json())
            .then(async responseData => {
                const [[table_data], [header]] = responseData;
                let error = false;
                if (header._estado) error = header._estado;
                else if (header._estadoRegistroVentas !== true)
                    error = header._estadoRegistroVentas;
                if (error) {
                    swal("ERROR", error, "error");
                } else {
                    const [{IdRegistroVenta}] = table_data;

                    this.setState({IdRegistroVenta: IdRegistroVenta});


                    await this.saveDetalleVenta(ventaCalculada, IdRegistroVenta);

                    let [Venta, DetalleVenta] = await Promise.all([
                        this.getVenta(IdRegistroVenta),
                        this.getDetalleVenta(IdRegistroVenta),

                    ]);
                    await this.handleEnviarVenta(IdRegistroVenta)

                    await this.handleEnviarEmail(IdRegistroVenta, DetalleVenta, Venta)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    async handleEnviarVenta(IdRegistroVentas) {
        await this.sendxml(IdRegistroVentas);
    }

    async sendxml(IdRegistroVentas) {
        try {
            const response = await fetch(`/api/configFactElectronica/ce/${IdRegistroVentas}`);
            const xmlresponse = await response.json();
            this.setState({xmlresponse: xmlresponse.respuesta, isLoading: false});
            return xmlresponse;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async handleEnviarEmail(IdRegistroVenta, DetalleVenta, Venta) {
        //ENVIAR CORREO *******
        //traer registro de venta para cuerpo de email
        const response = await fetch(
            `/api/configFactElectronica/RegistroVenta/${IdRegistroVenta}`
        );
        const dataCorreo = await response.json();
        var docpdf = await crearDocPDF(DetalleVenta, Venta, this.state.extImg);
        await enviaremail(dataCorreo.data[0], docpdf);
    }


    async getVenta(IdRegistroVenta) {
        try {
            const response = await fetch(`/api/ventas/get/${IdRegistroVenta}`);
            const Venta = await response.json();
            this.setState({
                Venta: Venta.respuesta[0],
                isLoading: false,
            });
            swal({
                title: '¡CORRECTO!',
                text: `Venta Registrada. ¿Desea imprimir el boucher?`,
                icon: 'success',
                buttons: [
                    'No',
                    'Si'
                ],
            }).then((isConfirm) => {
                if (isConfirm) {
                    this.setState({
                        statusModalImprimirReporte: true,
                    })
                } else {
                    this.props.history.push(`/registrosventas`)
                }
            })
            return Venta.respuesta[0];
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }


    async getDetalleVenta(IdRegistroVenta) {

        try {
            const response = await fetch(`/api/ventas/detalles/${IdRegistroVenta}`);
            const DetallesVenta = await response.json();
            this.setState({
                DetallesVenta: DetallesVenta.respuesta,
                isLoading: false,
            });
            return DetallesVenta.respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async saveDetalleVenta(ventaCalculada, IdRegistroVenta) {
        const Items = ventaCalculada.Items.map(detalle => {
            return [
                IdRegistroVenta,
                detalle.IdStock,
                detalle.Cantidad,
                detalle.PrecioReferencial,
                detalle.PrecioVenta,
                detalle.IdAfectacionIgv,
                detalle.IdPresentacion,
                detalle.Total,
                detalle.Descuento,
                detalle.TipoCambio,
                detalle.ISC,
                detalle.IGV,
                detalle.ICBPER,
                detalle.IVAP,
                detalle.TasaISC,
                detalle.ValorUnitario,
                detalle.DescuentoSI,
                detalle.Gratuito
            ];
        });

        // console.log("saveDetalleVenta - ",Items);

        try {
            const response = await fetch(`/api/ventas/detalles/new`, {
                method: "POST",
                body: JSON.stringify({
                    Items
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const respuesta = await response.json();
            this.setState({
                respuestaDetallesVenta: respuesta.respuesta,
                isLoading: false
            });
            return respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    calcularVenta = async (respuesta) => {

        const TasaIGV = respuesta.respuesta[0].TasaIGV;
        const TasaICBPER = respuesta.respuesta[0].TasaICBPER;
        const TasaIVAP = IVAP;
        const Descuento = this.state.DescuentoTotal;

        const datosParaCalculo = {
            Descuento,
            TasaIGV,
            TasaICBPER,
            TasaIVAP,
            Items: this.state.detallePreProcesarVale.productosVale.map(detalle => {
                return {
                    ValorUnitario: detalle.ValorUnitario,
                    Cantidad: detalle.Cantidad,
                    Descuento: detalle.Descuento,
                    Tributos: detalle.Tributos,
                    TasaISC: detalle.TasaISC,
                    IdStock: detalle.IdStock,
                    // PrecioReferencial: detalle.PrecioReferencial,
                    IdAfectacionIgv: detalle.IdAfectacionIgv,
                    IdPresentacion: detalle.IdPresentacion,
                    TipoCambio: detalle.TipoCambio,
                    Gratuito: detalle.Gratuito
                }
            })
        }
        // //
        const venta = calcularVenta(datosParaCalculo)

        // console.log("revisar - ",venta);
        return venta;
    }


    ActualizarPreProcesarValesMontoInDB() {
        //console.log(this.state.newPreProcesarValeId)
        try {
            fetch(`/api/vales/PreProcesarValesUpdateMonto/${this.state.IdPreProcesarVale}`, {
                method: "PUT",
                body: JSON.stringify({
                    Total: this.state.totalMonto
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(res => res.json())
                .catch(err => console.log(err));
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    StockDisponible = producto => {
        // console.log(producto.Stock)
        const listaProducto = this.state.dataProductos.productosVale
        const findStockVale = listaProducto.find(findItem => {
            return (findItem.IdProducto == producto.IdProducto &&
                findItem.IdPresentacion == producto.IdPresentacion
                && findItem.IdAlmacen == producto.IdAlmacen)
        })
        return ((findStockVale.Stock / producto.TipoCambio))
    }

    RemoveProductoInList(item) {
        this.state.dataProductosPreMostrar.respuesta.splice(this.state.dataProductosPreMostrar.respuesta.indexOf(item), 1)
        this.setState(this.state.dataProductosPreMostrar)

        this.state.detallePreProcesarVale.productosVale.splice(this.state.detallePreProcesarVale.productosVale.indexOf(item), 1)
        this.setState(this.state.dataProductosPreMostrar)
        this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)

        this.RemoveDetallePreProcesarVale(item)

        this.ActualizarStockValePreProcesarVale(item, item.Cantidad)
    }


    AgregarCantidadDet(item) {

        const Stock = this.StockDisponible(item)

        if (Stock >= 1) {
            const listaItems = this.state.detallePreProcesarVale.productosVale
            const key = listaItems.indexOf(item)

            listaItems[key].Cantidad++

            // FindPrecioPorMayor(this.state.dataProductos.productos,item)

            listaItems[key].Total = listaItems[key].Cantidad * listaItems[key].PrecioVenta
            this.setState(this.state.detallePreProcesarVale)
            this.montoTotalDetalle(listaItems)
            this.ActualizarDetallePreProcesarVales(item)
            this.ActualizarStockValePreProcesarVale(item, -1)

        } else {
            notificationStock0(item)
            this.setState({
                ClickPagar: true
            })
        }

    }

    RestarCantidadDet(item) {
        const listaItems = this.state.detallePreProcesarVale.productosVale
        const key = listaItems.indexOf(item)

        if (listaItems[key].Cantidad > 1) {
            listaItems[key].Cantidad--

            // FindPrecioPorMayor(this.state.dataProductos.productos,item)

            listaItems[key].Total = listaItems[key].Cantidad * listaItems[key].PrecioVenta
            this.setState(this.state.detallePreProcesarVale)
            this.montoTotalDetalle(listaItems)

            this.ActualizarDetallePreProcesarVales(item)
            this.ActualizarStockValePreProcesarVale(item, 1)
        }

    }

    RemoveDetallePreProcesarVale = item => {
        this.setState({loading: true, error: null});

        try {
            fetch(`/api/vales/deleteDetPreProcesarVale/${item.IdDetallePreProcesarVales}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    handleOnDeletePreProcesarVales(listaDetalle, IdPreProcesarVale) {
        return async () => {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Borrar de Vales no Generados?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });

            if (willDelete) {
                await this.CancelarPreProcesarVales(listaDetalle)
                    .then(() => {
                        this.DeletePreProcesarVales(IdPreProcesarVale)
                    })
            }
        };
    }

    CancelarPreProcesarVales = async (listaDetalle) => {
        for (let i = 0; i < listaDetalle.length; i++) {
            this.ActualizarStockValePreProcesarVale(listaDetalle[i], listaDetalle[i].Cantidad)
        }
    }

    DeletePreProcesarVales = async (IdPreProcesarVale) => {
        try {
            fetch(`/api/vales/deletePreProcesarVales/${IdPreProcesarVale}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
                .then(() => this.props.history.push(`/procesarvales/PreProcesarValesList`))
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    InputCantidadDetalleChange = (event, item) => {
        // console.log(event.target)
        const Stock = this.StockDisponible(item)

        const listaItems = this.state.detallePreProcesarVale.productosVale
        const key = listaItems.indexOf(item)

        const {value} = event.target;

        const variacionStock = value - listaItems[key].Cantidad

        if (value > -0.1) {
            if (Stock > variacionStock) {
                listaItems[key].Cantidad = value

                listaItems[key].Total = value * listaItems[key].PrecioVenta

                this.setState(this.state.detallePreProcesarVale)
                this.montoTotalDetalle(listaItems)
                this.ActualizarDetallePreProcesarVales(listaItems[key])
                this.ActualizarStockValePreProcesarVale(listaItems[key], 0 - variacionStock)

            } else {
                notificationStock0(item)
                this.setState({
                    ClickPagar: true
                })
            }
        } else {
            notificationCantidadNegativa()
            this.setState({
                ClickPagar: true
            })
        }

    };

    InputPrecioVentaChange = (event, item) => {
        const listaItems = this.state.detallePreProcesarVale.productosVale
        const key = listaItems.indexOf(item)

        const {value} = event.target;
        if (listaItems[key].Tributos.includes(TRIBUTO.ICBPER)) {
            if (value >= ICBPER) {
                listaItems[key].PrecioVenta = parseFloat(value)
                const {
                    PrecioVenta,
                    Tributos,
                    TasaISC,
                    Cantidad,
                    Gratuito,
                    Descuento
                } = item

                const itemcalculo = {
                    PrecioVenta,
                    Tributos,
                    TasaISC,
                    Cantidad,
                    Gratuito,
                    Descuento
                }

                const newValorUnitario = setValorUnitario(itemcalculo)

                listaItems[key].ValorUnitario = newValorUnitario

                if (item.mensaje == "") {
                    listaItems[key].Total = value * listaItems[key].Cantidad
                    this.setState(this.state.detallePreProcesarVale)
                    this.montoTotalDetalle(listaItems)
                }
                this.ActualizarDetallePreProcesarVales(listaItems[key])
            } else {
                listaItems[key].PrecioVenta = parseFloat(value * -1)
            }
        } else {
            if (value >= 0) {
                listaItems[key].PrecioVenta = parseFloat(value)
                const {
                    PrecioVenta,
                    Tributos,
                    TasaISC,
                    Cantidad,
                    Gratuito,
                    Descuento
                } = item

                const itemcalculo = {
                    PrecioVenta,
                    Tributos,
                    TasaISC,
                    Cantidad,
                    Gratuito,
                    Descuento
                }
                // //
                const newValorUnitario = setValorUnitario(itemcalculo)
                // //
                listaItems[key].ValorUnitario = newValorUnitario

                if (item.mensaje == "") {
                    listaItems[key].Total = value * listaItems[key].Cantidad
                    ////
                    this.setState(this.state.detallePreProcesarVale)
                    this.montoTotalDetalle(listaItems)
                }
                ////
                this.ActualizarDetallePreProcesarVales(listaItems[key])
            } else {
                listaItems[key].PrecioVenta = parseFloat(value * -1)
            }
        }
    }

    handleTipoComprobante(IdTipoDocumentoSunat) {
        /******FACTURA SE PUEDE CON RUC, BOLETA/VALE CON AMBOS********* */
        switch (IdTipoDocumentoSunat) {
            case 2:
                if (this.state.Documento.length === 11 && this.state.IdCliente != 0) {
                    this.setState({
                        IdTipoDocumentoSunat: IdTipoDocumentoSunat,
                        // filtroTipoStock:1,
                        // checkfiltroTipoStock:true
                    });
                } else {
                    notificationFacturaRUC()
                    this.setState({
                        ClickPagar: true
                    })
                }
                break;
            case 4:
                this.setState({
                    IdTipoDocumentoSunat: IdTipoDocumentoSunat,
                    // filtroTipoStock:1,
                    // checkfiltroTipoStock:true
                });
                break;
            default:
                this.setState({
                    IdTipoDocumentoSunat: IdTipoDocumentoSunat
                });
                break;
        }
    }

    onChangeCheckBoxGratuito = (e, item) => {
        const {checked} = e.target;
        item.checked = checked
        item.Gratuito = checked ? 1 : 0
        if (checked) {
            this.getAfectacionIdGratuita(item)
        } else {
            const itemInicial = this.state.dataProductos.productosVale.find(findItem => {
                return (
                    findItem.IdProducto == item.IdProducto
                    && findItem.IdStock == item.IdStock
                    && findItem.IdPresentacion == item.IdPresentacion
                    // && findItem.IdAlmacen == item.IdAlmacen
                    && findItem.IdTipoStock == item.IdTipoStock
                    && findItem.IdPrecioPlantilla == item.IdPrecioPlantilla
                )
            })
            item.IdAfectacionIgv = itemInicial.IdAfectacionIgv

            this.setState(this.state.detallePreProcesarVale)
            this.ActualizarDetallePreProcesarVales(item)
        }
        this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
    }

    getAfectacionIdGratuita = async (item) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/getIdAfectacionIgvByGrupoIgv/${item.IdGrupoClasificacionIgv}`);
            const IdAfectacionIgv = await response.json();
            //console.log(IdAfectacionIgv.IdAfectacionIgv.IdAfectacionIgv)
            item.Descuento = 0
            item.IdAfectacionIgv = IdAfectacionIgv.IdAfectacionIgv.IdAfectacionIgv
            this.setState(this.state.detallePreProcesarVale)
            this.ActualizarDetallePreProcesarVales(item)
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }
    handleCloseModal = () => {
        this.setState({modalClienteIsOpen: false})
    }
    setActiveInputDoc = (inputName) => {

    }
    handleOpenModalDescuento = (item) => {
        this.setState({
            statusModalDescuento: true,
            ItemDescuento: item
        })
    }
    handleOnCloseModalDescuento = () => {
        this.setState({
            statusModalDescuento: false
        })
    }

    FindPrecioCosto = item => {


        if (item.PrecioVenta < item.PrecioCosto) {
            item.mensaje = `PRECIO INGRESADO ${item.PrecioVenta} ES MENOR AL PRECIO DE COSTO DEL PRODUCTO ${item.PrecioCosto}`
            item.PrecioVenta = Math.round(item.PrecioCosto * 100) / 100
            item.Total = item.PrecioVenta * item.Cantidad - item.Descuento

            //console.log(item.Total)
            notificationPrecioCosto(item)
            item.mensaje = ""

            if (item.Tributos.includes(TRIBUTO.ICBPER)) {
                if (item.PrecioVenta >= ICBPER) {
                    //item.PrecioVenta = parseFloat(item.PrecioVenta)
                    const {
                        PrecioVenta,
                        Tributos,
                        TasaISC,
                        Cantidad,
                        Gratuito,
                        Descuento
                    } = item

                    const itemcalculo = {
                        PrecioVenta,
                        Tributos,
                        TasaISC,
                        Cantidad,
                        Gratuito,
                        Descuento
                    }

                    const newValorUnitario = setValorUnitario(itemcalculo)

                    item.ValorUnitario = newValorUnitario

                    if (item.mensaje == "") {
                        item.Total = item.PrecioVenta * item.Cantidad - item.Descuento
                        ////
                        this.setState(this.state.detallePreProcesarVale)
                        this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
                    }
                    this.ActualizarDetallePreProcesarVales(item)
                } else {
                    notificationPrecioMenorAICBPER()
                    this.setState({
                        ClickPagar: true
                    })
                }

            } else {
                if (item.PrecioVenta >= 0) {
                    //item.PrecioVenta = parseFloat(item.PrecioVenta)
                    const {
                        PrecioVenta,
                        Tributos,
                        TasaISC,
                        Cantidad,
                        Gratuito,
                        Descuento
                    } = item

                    const itemcalculo = {
                        PrecioVenta,
                        Tributos,
                        TasaISC,
                        Cantidad,
                        Gratuito,
                        Descuento
                    }

                    const newValorUnitario = setValorUnitario(itemcalculo)

                    item.ValorUnitario = newValorUnitario

                    if (item.mensaje == "") {
                        item.Total = item.PrecioVenta * item.Cantidad - item.Descuento
                        ////
                        this.setState(this.state.detallePreProcesarVale)
                        this.montoTotalDetalle(this.state.detallePreProcesarVale.productosVale)
                    }
                    this.ActualizarDetallePreProcesarVales(item)
                } else {
                    item.PrecioVenta = parseFloat(item.PrecioVenta * -1)
                }
            }
        } else {
            item.mensaje = ""
        }

    }

    AsignarValorDoc(dataCliente) {
        // console.log(dataCliente)
        this.setState({
            Documento: dataCliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(dataCliente.NroTipoDocumento)
    }

    handleClickNuevo = () => {
        this.setState({
            modalClienteIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })
    }

    AliasChange = e => {
        this.setState({
            Alias: e.target.value
        });
    }

    handleClickBuscar = () => {
        this.setState({
            statusModalSearchCliente: true
        })
    }

    GetCliente = async (cliente) => {
        //console.log(cliente)
        await this.handleCloseBuscar()
        this.setState({
            Documento: cliente.NroTipoDocumento
        })
        await this.TraerClientePorDoc(cliente.NroTipoDocumento)
    }

    handleCloseBuscar = () => {
        this.setState({
            statusModalSearchCliente: false
        })
    }

    handleEnterKeyUp(event, filtrarProductos) {
        console.log(filtrarProductos)
        if (event.key === "Enter") {
            if (filtrarProductos) {
                if (filtrarProductos.length == 1) {
                    this.AddProductoInList(filtrarProductos[0])
                    this.setState({
                        buscar: ""
                    })
                } else {
                    this.setState({
                        buscar: ""
                    })
                    notificationNoHayProducto()
                }
            }
        }
    }

    render() {
        if (this.state.loading === true && this.state.dataProductos === undefined) {
            return (
                <React.Fragment>
                    <div className="d-flex justify-content-center">
                        <label>Cargando...</label>
                        <div className="spinner-border text-primary ml-3" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        if (this.state.dataProductos.productosVale.length === 0 || this.state.aperturarCaja == false) {
            return (
                <PageLoading/>
            )
        } else {
            let filtrarProductos = this.state.dataProductos.productosVale.filter((Listproductos) => {
                return (
                    Listproductos.NombreProducto.concat(Listproductos.NombrePresentacion)
                        .concat(Listproductos.CodigoBarras).concat(Listproductos.Codigo)
                        .toLowerCase().includes(this.state.buscar.toLowerCase()) && Listproductos.Stock > 0
                )
            })

            if (filtrarProductos.length === 0) {
                return (
                    <React.Fragment>
                        <div className="preventa__container">
                            <div className="preventa">
                                <div className="encabezado">
                                    <div className="preventa__tipo--comprobante">
                                        {/* *******TIPOS DE COMPROBANTES DE PAGO********* */}
                                        {this.getBotonTipoComprobante1(FACTURA, "Factura")}
                                        {this.getBotonTipoComprobante1(BOLETA, "Boleta")}
                                    </div>
                                    {/* ***************************** */}
                                    {/* *************************
                                      ***BUSCAR CLIENTE POR DOC**
                                      **************************
                                      <BotonCancelar
                                            onClick={()=>this.props.history.goBack()}
                                            className="mb-2 mt-2"
                                        />*/}
                                    <BuscarClientePorDoc
                                        DocChange={this.DocChange}
                                        Documento={this.state.Documento}
                                        setActiveInputDoc={this.setActiveInputDoc}
                                        TraerClientePorDoc={this.TraerClientePorDoc}
                                        dataCliente={this.state.dataCliente}
                                        handleClickNuevo={this.handleClickNuevo}
                                        handleClickBuscar={this.handleClickBuscar}
                                        handleCloseBuscar={this.handleCloseBuscar}
                                        Alias={this.state.Alias}
                                        AliasChange={this.AliasChange}
                                    />
                                    <div className="PreVBuscarProducto">
                                        <input type="text"
                                               placeholder="Buscar Producto"
                                               className="input__linea mr-2"
                                               value={this.state.buscar}
                                               onChange={this.handleChange} autoFocus
                                               onKeyUp={(e) => this.handleEnterKeyUp(e, filtrarProductos)}/>
                                        {/* <label className="mr-2 mt-1">Con Comprobante</label>
                                            <input
                                                type="checkbox"
                                                name="filtroTipoStock"
                                                className="filtroTipoStock mt-2 mr-2"
                                                onChange={this.onChangeCheckBox}
                                                value={this.state.filtroTipoStock}
                                                checked={this.state.checkfiltroTipoStock}
                                            />
                                            <button
                                                onClick={this.handleOpenModal}
                                                type="button"
                                                className="btn btn-outline-info btn-sm"
                                                title="Seleccionar Almacén"
                                            >
                                                <i className="fa fa-warehouse" title="Seleccionar Almacén"></i>
                                            </button> */}
                                    </div>

                                    <div className="form-group encabezadoPV">
                                        {/* PESTAÑAS LISTAS DE PRODUCTOS CON BOTONES*/}
                                        {/* **************************** */}
                                        <div>
                                            {/* { this.BotonTipoLista(1,"General") } */}
                                            {/* { this.BotonTipoLista(2,"Cliente") }
                                            { this.BotonTipoLista(3,"+Vendido") } */}
                                        </div>
                                        <div id="tableProdPreventa" className="lista_productos">
                                            <div className="lista_productos__item">
                                                <table className="table table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th>Producto</th>
                                                        <th>Precio</th>
                                                        <th>Stock</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    No hay coincidencias:
                                                    <BotonAdd
                                                        className="ml-2"
                                                        to="/productos/new"
                                                        title="Agregar Nuevo Producto"
                                                    >
                                                        Agregar Nuevo Producto
                                                    </BotonAdd>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="preventa__container_data" style={{marginTop: '15px'}}>


                                    {/* *********************
                                     ***CARRITTO DE DETALLE**
                                    *************************/}
                                    <ProcesarValesListCarrito
                                        dataProductosMostrar={this.state.detallePreProcesarVale}
                                        InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                        handleOpenModalDescuento={this.handleOpenModalDescuento}
                                        InputPrecioVentaChange={this.InputPrecioVentaChange}
                                        setActiveInput={this.setActiveInput}
                                        AgregarCantidadDet={this.AgregarCantidadDet}
                                        RestarCantidadDet={this.RestarCantidadDet}
                                        RemoveProductoInList={this.RemoveProductoInList}
                                        FindPrecioCosto={this.FindPrecioCosto}
                                        PrecioChange={this.PrecioChange}
                                    />
                                </div>
                                {/*<div className="encabezado">
                                    <div className="preventa__tipo--comprobante">
                                        <Keyboard
                                            keyboardRef={r => (this.keyboard = r)}
                                            layout={{
                                                'default': [
                                                    "1 2 3", "4 5 6", "7 8 9", "{bksp} 0 ."
                                                ]
                                            }}
                                            display={{
                                                '{bksp}': `x`,
                                            }}
                                            inputName={this.state.inputName}
                                            onChangeAll={inputObj => this.onChangeAll(inputObj)}
                                            //onKeyPress={button => this.onKeyPress(button)}
                                        />
                                    </div>*/}

                                {<ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                               title="NUEVO CLIENTE">
                                    <ClienteNuevo onClose={this.handleCloseModal}
                                                  NumeroDocumento={this.state.Documento}
                                                  AsignarValorDoc={this.AsignarValorDoc}
                                    />
                                </ModalCliente>}
                                {<Modal
                                    isOpen={this.state.statusModalDescuento}
                                    onClose={this.handleOnCloseModalDescuento}
                                    handleKeyUp={this.handleKeyUp}
                                >
                                    <  PreProcesarValesModalDescuentos
                                        ItemDescuento={this.state.ItemDescuento}
                                        DescuentoChange={this.DescuentoChange}
                                        onChangeCheckBoxGratuito={this.onChangeCheckBoxGratuito}
                                    />
                                </Modal>}
                                {<Modal
                                    isOpen={this.state.statusModalSearchCliente}
                                    onClose={this.handleCloseBuscar}
                                    handleKeyUp={this.handleKeyUp}
                                >
                                    <ModalBuscarCliente
                                        GetCliente={this.GetCliente}
                                    />
                                </Modal>}
                                {<Modal
                                    isOpen={this.state.statusModalImprimirReporte}
                                    onClose={this.handleCloseImprimirReporte}
                                    handleKeyUp={this.handleKeyUp}
                                    title="Opciones de impresión"
                                >
                                    <ModalImprimirReporte
                                        state={this.state}
                                        handleSavePDF={this.handleSavePDF}
                                        handlePrintTicket={this.handlePrintTicket}
                                        // GetCliente={this.GetCliente}
                                    />
                                </Modal>}
                            </div>

                            <div className="totals__container">
                                <div className="totals__container--item">
                                    {parseFloat(this.state.Gravado) > 0 ?
                                        <p>
                                            Gravado{" "}
                                            <strong>
                                                {this.state.Gravado.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.Inafecto) > 0 ?
                                        <p>
                                            Inafecto{" "}
                                            <strong>
                                                {this.state.Inafecto.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.Exonerado) > 0 ?
                                        <p>
                                            Exonerado{" "}
                                            <strong>
                                                {this.state.Exonerado.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.Gratuitas) > 0 ?
                                        <p>
                                            Gratuitas:{" "}
                                            <strong>
                                                {this.state.Gratuitas.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.ICBPERPreProcesarVale) > 0 ?
                                        <p>
                                            ICBPER{" "}
                                            <strong>
                                                {this.state.ICBPERPreProcesarVale.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.IGVPreProcesarVale) > 0 ?
                                        <p>
                                            IGV{" "}
                                            <strong>
                                                {this.state.IGVPreProcesarVale.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                    {parseFloat(this.state.IVAP) > 0 ?
                                        <p>
                                            IVAP <strong>
                                            {this.state.IVAP.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                        </p>
                                        : null
                                    }

                                    {parseFloat(this.state.ISC) > 0 ?
                                        <p>
                                            ISC:{" "}
                                            <strong>
                                                {this.state.ISC.toLocaleString("en", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </strong>
                                        </p>
                                        : null
                                    }
                                </div>
                                <div className="totals__container--item-total">
                                    <span> {this.state.totalMonto
                                        .toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </span>
                                </div>
                                <div className="totals__container--item">
                                    <button
                                        className="generar__boton btn btn-success"
                                        type="submit"
                                        onClick={() => this.handleGenerarPreProcesarVales(this.state.IdPreProcesarVale)}
                                        hidden={this.state.totalMonto > 0 ? false : true}
                                        disabled={this.state.ClickPagar == true ? false : true}
                                    >
                                        Procesar
                                    </button>
                                    {   /****SI EXISTE LOCATION.FROM, REGRESA NULL** */
                                        this.props.location.from ? null :
                                            /**Y SI NO EXISTE, REGRESA EL BOTON** */
                                            <button
                                                className="cancelar__boton btn btn-danger ml-1"
                                                onClick={this.handleOnDeletePreProcesarVales(
                                                    this.state.detallePreProcesarVale.productosVale,
                                                    this.state.IdPreProcesarVale
                                                )}
                                            >
                                                Eliminar
                                            </button>
                                    }

                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                )
            }
            return (
                <React.Fragment>
                    <div className="preventa__container">
                        <div className="preventa">

                            <div className="encabezado">
                                <div className="preventa__tipo--comprobante">
                                    {/* *******TIPOS DE COMPROBANTES DE PAGO********* */}
                                    {this.getBotonTipoComprobante1(FACTURA, "Factura")}
                                    {this.getBotonTipoComprobante1(BOLETA, "Boleta")}
                                </div>
                                {/* ***************************** */}
                                {/* *************************
                                      ***BUSCAR CLIENTE POR DOC**
                                      **************************
                                      <BotonCancelar
                                            onClick={()=>this.props.history.goBack()}
                                            className="mb-2 mt-2"
                                        />*/}
                                <BuscarClientePorDoc
                                    DocChange={this.DocChange}
                                    Documento={this.state.Documento}
                                    setActiveInputDoc={this.setActiveInputDoc}
                                    TraerClientePorDoc={this.TraerClientePorDoc}
                                    dataCliente={this.state.dataCliente}
                                    handleClickNuevo={this.handleClickNuevo}
                                    handleClickBuscar={this.handleClickBuscar}
                                    handleCloseBuscar={this.handleCloseBuscar}
                                    Alias={this.state.Alias}
                                    AliasChange={this.AliasChange}
                                />
                                <div className="PreVBuscarProducto">
                                    <input type="text"
                                           placeholder="Buscar Producto"
                                           className="input__linea mr-2"
                                           value={this.state.buscar}
                                           onChange={this.handleChange} autoFocus
                                           onKeyUp={(e) => this.handleEnterKeyUp(e, filtrarProductos)}/>
                                    {/* <label className="mr-2 mt-1">Con Comprobante</label>
                                            <input
                                                type="checkbox"
                                                name="filtroTipoStock"
                                                className="filtroTipoStock mt-2 mr-2"
                                                onChange={this.onChangeCheckBox}
                                                value={this.state.filtroTipoStock}
                                                checked={this.state.checkfiltroTipoStock}
                                            />
                                            <button
                                                onClick={this.handleOpenModal}
                                                type="button"
                                                className="btn btn-outline-info btn-sm"
                                                title="Seleccionar Almacén"
                                            >
                                                <i className="fa fa-warehouse" title="Seleccionar Almacén"></i>
                                            </button> */}
                                </div>

                                <div className="form-group encabezadoPV">
                                    {/* PESTAÑAS LISTAS DE PRODUCTOS CON BOTONES*/}
                                    {/* **************************** */}
                                    <div>
                                        {/* { this.BotonTipoLista(1,"General") } */}
                                        {/* { this.BotonTipoLista(2,"Cliente") }
                                            { this.BotonTipoLista(3,"+Vendido") } */}
                                    </div>
                                    <ListaProductos
                                        listaProductos={filtrarProductos}
                                        AddProductoInList={this.AddProductoInList}
                                        StockDisponible={this.StockDisponible}
                                    />

                                </div>
                            </div>

                            <div className="preventa__container_data" style={{marginTop: '15px'}}>


                                {/* *********************
                                     ***CARRITTO DE DETALLE**
                                    *************************/}
                                <ProcesarValesListCarrito
                                    dataProductosMostrar={this.state.detallePreProcesarVale}
                                    InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                    handleOpenModalDescuento={this.handleOpenModalDescuento}
                                    InputPrecioVentaChange={this.InputPrecioVentaChange}
                                    setActiveInput={this.setActiveInput}
                                    AgregarCantidadDet={this.AgregarCantidadDet}
                                    RestarCantidadDet={this.RestarCantidadDet}
                                    RemoveProductoInList={this.RemoveProductoInList}
                                    FindPrecioCosto={this.FindPrecioCosto}
                                    PrecioChange={this.PrecioChange}
                                />
                            </div>
                            {/*<div className="encabezado">
                                    <div className="preventa__tipo--comprobante">
                                        <Keyboard
                                            keyboardRef={r => (this.keyboard = r)}
                                            layout={{
                                                'default': [
                                                    "1 2 3", "4 5 6", "7 8 9", "{bksp} 0 ."
                                                ]
                                            }}
                                            display={{
                                                '{bksp}': `x`,
                                            }}
                                            inputName={this.state.inputName}
                                            onChangeAll={inputObj => this.onChangeAll(inputObj)}
                                            //onKeyPress={button => this.onKeyPress(button)}
                                        />
                                    </div>*/}

                            {<ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                           title="NUEVO CLIENTE">
                                <ClienteNuevo onClose={this.handleCloseModal}
                                              NumeroDocumento={this.state.Documento}
                                              AsignarValorDoc={this.AsignarValorDoc}
                                />
                            </ModalCliente>}
                            {<Modal
                                isOpen={this.state.statusModalDescuento}
                                onClose={this.handleOnCloseModalDescuento}
                                handleKeyUp={this.handleKeyUp}
                            >
                                <  PreProcesarValesModalDescuentos
                                    ItemDescuento={this.state.ItemDescuento}
                                    DescuentoChange={this.DescuentoChange}
                                    onChangeCheckBoxGratuito={this.onChangeCheckBoxGratuito}
                                />
                            </Modal>}
                            {<Modal
                                isOpen={this.state.statusModalSearchCliente}
                                onClose={this.handleCloseBuscar}
                                handleKeyUp={this.handleKeyUp}
                            >
                                <ModalBuscarCliente
                                    GetCliente={this.GetCliente}
                                />
                            </Modal>}
                            {<Modal
                                isOpen={this.state.statusModalImprimirReporte}
                                onClose={this.handleCloseImprimirReporte}
                                handleKeyUp={this.handleKeyUp}
                                title="Opciones de impresión"
                            >
                                <ModalImprimirReporte
                                    state={this.state}
                                    handleSavePDF={this.handleSavePDF}
                                    handlePrintTicket={this.handlePrintTicket}
                                    // GetCliente={this.GetCliente}
                                />
                            </Modal>}
                        </div>

                        <div className="totals__container">
                            <div className="totals__container--item">
                                {parseFloat(this.state.Gravado) > 0 ?
                                    <p>
                                        Gravado{" "}
                                        <strong>
                                            {this.state.Gravado.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.Inafecto) > 0 ?
                                    <p>
                                        Inafecto{" "}
                                        <strong>
                                            {this.state.Inafecto.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.Exonerado) > 0 ?
                                    <p>
                                        Exonerado{" "}
                                        <strong>
                                            {this.state.Exonerado.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.Gratuitas) > 0 ?
                                    <p>
                                        Gratuitas:{" "}
                                        <strong>
                                            {this.state.Gratuitas.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.ICBPERPreProcesarVale) > 0 ?
                                    <p>
                                        ICBPER{" "}
                                        <strong>
                                            {this.state.ICBPERPreProcesarVale.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.IGVPreProcesarVale) > 0 ?
                                    <p>
                                        IGV{" "}
                                        <strong>
                                            {this.state.IGVPreProcesarVale.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                                {parseFloat(this.state.IVAP) > 0 ?
                                    <p>
                                        IVAP <strong>
                                        {this.state.IVAP.toLocaleString("en", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </strong>
                                    </p>
                                    : null
                                }

                                {parseFloat(this.state.ISC) > 0 ?
                                    <p>
                                        ISC:{" "}
                                        <strong>
                                            {this.state.ISC.toLocaleString("en", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })}
                                        </strong>
                                    </p>
                                    : null
                                }
                            </div>
                            <div className="totals__container--item-total">
                                <span> {this.state.totalMonto
                                    .toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                            </span>
                            </div>
                            <div className="totals__container--item">
                                <button
                                    className="generar__boton btn btn-success"
                                    type="submit"
                                    onClick={() => this.handleGenerarPreProcesarVales(this.state.IdPreProcesarVale)}
                                    hidden={this.state.totalMonto > 0 ? false : true}
                                    disabled={this.state.ClickPagar == true ? false : true}
                                >
                                    Procesar
                                </button>
                                {   /****SI EXISTE LOCATION.FROM, REGRESA NULL** */
                                    this.props.location.from ? null :
                                        /**Y SI NO EXISTE, REGRESA EL BOTON** */
                                        <button
                                            className="cancelar__boton btn btn-danger ml-1"
                                            onClick={this.handleOnDeletePreProcesarVales(
                                                this.state.detallePreProcesarVale.productosVale,
                                                this.state.IdPreProcesarVale
                                            )}
                                        >
                                            Eliminar
                                        </button>
                                }

                            </div>
                        </div>
                    </div>

                </React.Fragment>
            )
        }
    }


}

export default PreProcesarValesEdit
