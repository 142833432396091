import React, {Component} from "react";
import GestionPreventasUI from "./GestionPreventasUI";
import Header from "../componentes/Header";
import parent_icon_header from "../componentes/images/icons8-barcode-52.png";
import child_icon_header from "../componentes/images/icons8-clipboard-52.png";
import swal from "sweetalert";
import jsPDF from "jspdf";
import "react-moment";
import moment from "moment";
import {ESTADO_PREVENTA} from "../Global";

class GestionPreventas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            preventas: [],
            FechaPreventas: new Date(),
            IdPreventa: null,
            tienePermisoCaja: false
        };

        this.handleProcesaPreventa = this.handleProcesaPreventa.bind(this);
        this.handleOnDeletePreventa = this.handleOnDeletePreventa.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handleCalendar = this.handleCalendar.bind(this);
        this.getPreventas = this.getPreventas.bind(this);
    }

    componentDidMount() {
        this.getPreventas(this.state.FechaPreventas);
        // this.intervalo = setInterval(this.getPreventas, 5000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalo)
    }

    async getPreventas(FechaPreventas) {
        var FechaPreventas = moment(FechaPreventas).format("YYYY-MM-DD");

        await fetch(`/api/gestionpreventas/preventas/list/${FechaPreventas}`)
            .then(response => response.json())
            .then(preventas =>
                this.setState({
                    preventas: preventas.respuesta,
                    isLoading: false,
                    tienePermisoCaja: preventas.tienePermisoCaja
                })
            )
            .catch(error => this.setState({error, isLoading: false}));
    }

    handleProcesaPreventa(IdPreventa) {
        return () => {
            this.props.history.push(`/pagos/${IdPreventa}`);
        };
    }

    async handleOnDeletePreventa(IdPreventa) {

        const response = await fetch(
            `/api/gestionPreventas/preventa/${IdPreventa}`
        );
        const preventa = await response.json();

        if (preventa.respuesta[0].Estado === ESTADO_PREVENTA.PROCESADA || preventa.respuesta[0].Estado === ESTADO_PREVENTA.CANCELADA)
            window.location.reload();
        else {
            const willDelete = await swal({
                title: "¿Está seguro?",
                text: "¿Desea borrar ésta preventa?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                closeOnClickOutside: false
            });
            if (willDelete)
                await this.deletePreventa(IdPreventa);
        }


    }

    async deletePreventa(IdPreventa) {
        await fetch(`/api/gestionpreventas/delete/${IdPreventa}`, {
            method: "POST",
            body: JSON.stringify({
                IdPreventa: IdPreventa
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(data => {
                this.setState({isLoading: false});
                return data;
            })
            .then(response => response.json())
            .then(responseData => {
                this.setState({respuestaDeletePreventa: responseData.respuesta});
                if (this.state.respuestaDeletePreventa === true) {
                    swal(
                        "¡Borrada!",
                        "¡La Preventa ha sido borrada satisfactoriamente!",
                        "success"
                    );
                }
            })
            .catch(error => this.setState({error, isLoading: false}));
        await this.getPreventas(this.state.FechaPreventas);
    }

    handleCalendar(e) {
        this.setState({FechaPreventas: e.target.value})
        this.getPreventas(e.target.value)
    }

    handleSavePDF() {
        let doc = new jsPDF({
            orientation: "landscape"
        });
        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text("REPORTE DE PREVENTAS PENDIENTES", 105, 10);
        doc.autoTable({
            body: this.state.preventas,
            columns: [
                {header: "FECHA", dataKey: "FechaEmision"},
                {header: "CÓDIGO", dataKey: "Codigo"},
                {header: "ALIAS", dataKey: "Alias"},
                {header: "NÚMERO DOCUMENTO", dataKey: "NroTipoDocumento"},
                {header: "RAZÓN SOCIAL", dataKey: "RazonSocial"},
                {header: "MONEDA", dataKey: "Abreviatura"},
                {header: "TOTAL", dataKey: "Total"},
                {header: "TIPO COMPROBANTE", dataKey: "TipoComprobante"}
            ]
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("PreventasPendientes.pdf");
    }

    render() {
        return (
            <div id="gestionPreventas">
                <GestionPreventasUI
                    {...this.state}
                    handleProcesaPreventa={this.handleProcesaPreventa}
                    handleOnDeletePreventa={this.handleOnDeletePreventa}
                    pdfViewer={this.handleSavePDF}
                    handleCalendar={this.handleCalendar}
                    tienePermisoCaja={this.state.tienePermisoCaja}
                />
            </div>
        );
    }
}

export default GestionPreventas;
