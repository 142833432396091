import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import {BotonLetras, BotonUp, BotonCancelar} from "../Plantillas/Botones.js";
import {notificationStockInicial} from "./KardexNotify.js";
import "react-moment";
import moment from "moment";

class TablaStockInicial extends React.Component {

    render() {
        return (
            <ReactTable
                data={this.props.data}
                filterable
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]) === filter.value
                }
                columns={[
                    {
                        Header: "Código",
                        id: "Codigo",
                        // accessor: "Codigo",
                        headerClassName: "",
                        width: 100,
                        accessor: d => d.Codigo,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["Codigo"]}),
                        filterAll: true
                    },
                    {
                        Header: "Nombre",
                        id: "Nombre",
                        // accessor: "Nombre",
                        headerClassName: "",
                        width: 300,
                        accessor: d => d.Nombre,
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {keys: ["Nombre"]}),
                        filterAll: true
                    },
                    {
                        Header: "Precio Costo con IGV",
                        id: "Precio",
                        // accessor: "Precio",
                        headerClassName: "",
                        width: 200,
                        accessor: d => d.Precio,
                        Cell: this.props.renderEditable
                    },
                    {
                        filterable: false,
                        Header: "Cantidad",
                        id: "Stock",
                        // accessor: "Stock",
                        headerClassName: "",
                        width: 100,
                        accessor: d => d.Stock,
                        Cell: this.props.renderEditable
                    },
                    {
                        Header: "",
                        filterable: false,
                        headerClassName: "",
                        width: 80,
                        Cell: original => {
                            return (
                                this.props.disableButton ? (
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status"
                                                  aria-hidden="true"/>
                                            <span className="sr-only">Cargando...</span>
                                        </button>
                                    ) :
                                    (<BotonUp
                                        title="Agregar Stock Inicial"
                                        onClick={() => this.props.AddMA(original.original)
                                            .then(() => notificationStockInicial())}
                                    />)

                            );
                        }
                    }
                ]}
                defaultPageSize={50}
                //pageSize={10}
                className="-striped -highlight"
                // Text de paginación
                previousText="Anterior"
                nextText="Siguiente"
                loadingText="Cargando..."
                noDataText="No se encontraron registros"
                pageText="Página"
                ofText="de"
                rowsText="filas"
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
            />
        )
    }
}

export default TablaStockInicial
