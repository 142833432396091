import React from 'react';
import "react-moment";
//Componentes
import EmpresasAddFormDatosSunat from "./empresasComponentes/EmpresasAddFormDatosSunat.js"
import EmpresasAddFormDatosGenerales from "./empresasComponentes/EmpresasAddFormDatosGenerales.js"
import EmpresasAddFormDatosLogoFirmas from "./empresasComponentes/EmpresasAddFormDatosLogoFirmas.js"
import {validarInputsEmpresa} from "./empresasComponentes/EmpresasValidator.js";
import {notificationError} from './empresasComponentes/EmpresasNotify.js'
import PageLoading from './PageLoading.js';
import './styles/tabla.css';
import apiFetch from '../helpers/apiFetch.js';
import {BotonGuardarLetras, BotonCancelar} from "./Plantillas/Botones";
import "react-moment";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import Notify from './Notify.js';


class AddEmpresa extends React.Component {

    constructor() {

        super()
        this.state = {
            // user: null, //User de firebase para el logo
            // uploadValue: 0,
            // uploadValueFirma: 0,
            // FechaActual: new Date(),
            mostrarCorreosOpcionales: true,
            mostrarTelefonosOpcionales: true,
            AddOrEdit: "Add",
            mostrarLogos: false,
            RucPre: "",
            Ruc: null,
            RazonSocial: null,
            Direccion: null,
            Telefono: null,
            TelefonoDos: null,
            TelefonoTres: null,
            IdUbigeo: null,
            NombreComercial: null,
            // Logo: null,
            PaginaWeb: null,
            CorreoElectronico: null,
            CorreoElectronicoDos: null,
            CorreoElectronicoTres: null,
            Sector: 'Comercial',
            FechaDeConstitucion: null,
            RepresentanteLegal: null,
            // Firma: null,
            Retenedor: "Inactivo",
            Percepcion: "Inactivo",
            EsRuss: "Inactivo",
            IdPlanSunat: 1,
            IdMoneda: 1,
            ContribuyenteCondicion: null,
            ContribuyenteEstado: null,
            FechaDeInscripcion: null,
            SistemaContabilidad: "MANUAL",
            SistemaEmision: "MANUAL",
            UsuarioSolPrincipal: null,
            ClaveSolPrincipal: null,
            ubigeoNombrecodigo: null,
            IdEmpresaNew: '',

            Errores: {
                Ruc: "",
                RazonSocial: "",
                Direccion: "",
                Telefono: "",
                TelefonoDos: "",
                TelefonoTres: "",
                NombreComercial: "",
                PaginaWeb: "",
                CorreoElectronico: "",
                CorreoElectronicoDos: "",
                CorreoElectronicoTres: "",
                Sector: "",
                RepresentanteLegal: "",
                FechaDeInscripcion: "",
                FechaDeConstitucion: ""
            },

            data: {
                ubigeos: []
            },
            dataP: {
                planesSunat: []
            },
            dataM: {
                results: []
            },

            checkRetenedor: false,
            checkPercepcion: false,
            checkEsRuss: false,

            ubigeoReg: [],
            ubigeoProv: [],
            ubigeoDis: [],
            valueRegion: '01',
            valueProvincia: '01',
            valueDistrito: '01',
            Hoy: '',
            pruebaLogo: new FormData(),
            pruebaFirma: new FormData(),

            formDataL: new FormData(),
            formDataF: new FormData()
        }
        //this.TraerEmpresaPorRUC = this.TraerEmpresaPorRUC.bind(this)
        this.TraerEmpresaPorRUC_API = this.TraerEmpresaPorRUC_API.bind(this)

        this.newEmpresa = this.newEmpresa.bind(this)
        this.handleChange = this.handleChange.bind(this)
        // this.RegionChange=this.RegionChange.bind(this)
        this.EsRussChange = this.EsRussChange.bind(this);
        this.getUbigeo = this.getUbigeo.bind(this)
        this.getPlanesSunat = this.getPlanesSunat.bind(this)
        // this.ProvinciaChange=this.ProvinciaChange.bind(this)
        // this.DistritoChange=this.DistritoChange.bind(this)
        // this.traerUbigeoPorRegion=this.traerUbigeoPorRegion.bind(this)
        // this.traerUbigeoPorProvincia=this.traerUbigeoPorProvincia.bind(this)
        // this.traerUbigeoPorDistrito=this.traerUbigeoPorDistrito.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getMoneda = this.getMoneda.bind(this);
        // this.handleAuth = this.handleAuth.bind(this);
        // this.handleLogout = this.handleLogout.bind(this);
        // this.handleUpload = this.handleUpload.bind(this);
        // this.handleUploadFirma = this.handleUploadFirma.bind(this);
        this.SubirLogo = this.SubirLogo.bind(this);
        this.SubirFirma = this.SubirFirma.bind(this);
    }

    SubirLogo = async (e) => {
        var logoInput = document.getElementById('logoInput');
        let file = logoInput.files[0];


        var archivoRuta = logoInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            Notify('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.', "warning", 1500);
            logoInput.value = '';
            //document.getElementById('visorLogo').innerHTML = ''; //Visualizar el logo
            return false;
        } else if (logoInput.files && logoInput.files[0]) {
            var visor = new FileReader();
            visor.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    //Acá se necesita hacer la validacion del tamaño de la imagen y evitar que se guarde. //si cumple el tamaño insertarlo en visorLogo

                    if (this.width >= 10 && this.width <= 5000) {

                        document.getElementById('visorLogo').innerHTML = '<img name="Logo" src="' + e.target.result + '" >';//El que permite visaulizar el logo subido

                    } else {
                        alert('La imagen debe ser tener un ancho entre 10 y 5000');
                        // logoInput.value='';
                        return false;
                    }
                };
            }//bandera para ver si se hace el readAsDataURL o no se ejecuta primero el readAsDataURl y el append y luego el onload. ver el orden
            visor.readAsDataURL(logoInput.files[0]);//console.log(visor.result);
        } //Bandera para saber si se hace el append o no
        // this.state.pruebaLogo.append("Logo",e.target.files[0],`${this.state.Ruc}.jpeg`); //El que guarda y esta listo para enviarse al back


        this.state.formDataL.append("file", file);
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/logo`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();
        // console.log(rpt.success)// para controlar si se suve o no
    }
    SubirFirma = async (e) => {
        var FirmaInput = document.getElementById('FirmaInput');
        let file = FirmaInput.files[0];


        var archivoRuta = FirmaInput.value;
        var extPermitidas = /(.png|.jpg|.jpeg|.PNG|.JPG|.JPEG)$/i;
        if (!extPermitidas.exec(archivoRuta)) {
            alert('Ingrese una imagen con alguno de los siguientes formatos: .jpeg/.jpg/.png.');
            FirmaInput.value = '';
            //document.getElementById('visorFirma').innerHTML = ''; //Visualizar el Firma
            return false;
        } else if (FirmaInput.files && FirmaInput.files[0]) {
            var visor = new FileReader();
            visor.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () { //Mantener el function ya que con = () => se cae, hacer la prueba de bandera con function ()
                    // console.log("img ------ ",this.width);
                    if (this.width >= 10 && this.width <= 5000) {
                        document.getElementById('visorFirma').innerHTML = '<img name="Firma" src="' + e.target.result + '" >';
                    } else {
                        alert('La imagen debe ser tener un ancho entre 50 y 500');
                        FirmaInput.value = '';
                        return false;
                    }
                };
            }

            visor.readAsDataURL(FirmaInput.files[0]);
        }


        this.state.formDataF.append("file", file);
        // console.log("FirmaInput: ",FirmaInput.value)
        // const respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/firma`, {method: "POST", body: formData});
        // const rpt = await respuesta.json();
    }


    componentDidMount = () => {
        this.getUbigeo();
        this.getPlanesSunat();
        this.getMoneda();
        const Fecha = new Date();
        this.setState({
            Hoy: moment(Fecha).format('YYYY-MM-DD')
        });
        // this.retenedorPercepcion(this.state.Retenedor,this.state.Percepcion);
    }
// componentWillMount = () => {
//     firebase.auth().onAuthStateChanged(user => {
//         this.setState({  user  })
//     });
// }
    getMoneda = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/empresas/monedas/');
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataM: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getPlanesSunat = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/planesSunat/planes');
            const data = await response.json();
            //console.log(data.planesSunat)
            this.setState({
                loading: false,
                dataP: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getUbigeo = async () => {
        this.setState({loading: true, error: null})
        try {
            const response = await fetch('/api/ubigeos/ubigRegion');
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
                valueRegion: data.ubigeos[0].CodRegion,
                valueProvincia: data.ubigeos[0].CodProvincia,
                IdUbigeo: data.ubigeos[0].IdUbigeo,
                valueDistrito: data.ubigeos[0].IdUbigeo
            })
            //     await Promise.all([
            //         this.traerUbigeoPorRegion(),
            //         this.traerUbigeoPorProvincia(data[0].CodRegion),
            //         this.traerUbigeoPorDistrito(data[0].CodRegion,data[0].CodProvincia)
            //    ])
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }
    RetenedorChange = (e) => {
        if (this.state.Retenedor === "Activo") {
            this.setState({Retenedor: "Inactivo", checkRetenedor: false})
        } else {
            this.setState({Retenedor: "Activo", checkRetenedor: true})
        }
    }

    PercepcionChange = () => {
        if (this.state.Percepcion === "Activo") {
            this.setState({Percepcion: "Inactivo", checkPercepcion: false})
        } else {
            this.setState({Percepcion: "Activo", checkPercepcion: true})
        }
    }
    EsRussChange = () => {
        if (this.state.EsRuss === "Activo") {
            this.setState({EsRuss: "Inactivo", checkEsRuss: false})
        } else {
            this.setState({EsRuss: "Activo", checkEsRuss: true})
        }
        // console.log("EsRussChange: ",this.state.EsRuss)
    }
    newEmpresa = async (e) => {

        let valido = true
        Object.values(this.state.Errores).forEach(val => {
            val.length > 1 && (valido = false)
        })
        //console.log(this.state)

        if (valido) {
            //notificationAdd()
            apiFetch('/api/empresas/', ["_estadoInsertarEmpresa"], {
                method: 'POST',
                body: JSON.stringify({
                    Ruc: this.state.Ruc,
                    RazonSocial: this.state.RazonSocial,
                    Direccion: this.state.Direccion,
                    Telefono: this.state.Telefono,
                    TelefonoDos: this.state.TelefonoDos,
                    TelefonoTres: this.state.TelefonoTres,
                    IdUbigeo: this.state.IdUbigeo,
                    NombreComercial: this.state.NombreComercial,
                    // Logo:this.state.Logo,
                    PaginaWeb: this.state.PaginaWeb,
                    IdMoneda: this.state.IdMoneda,
                    CorreoElectronico: this.state.CorreoElectronico,
                    CorreoElectronicoDos: this.state.CorreoElectronicoDos,
                    CorreoElectronicoTres: this.state.CorreoElectronicoTres,
                    Sector: this.state.Sector,
                    FechaDeConstitucion: this.state.FechaDeConstitucion,
                    RepresentanteLegal: this.state.RepresentanteLegal,
                    // Firma:this.state.Firma,
                    Retenedor: this.state.Retenedor,
                    Percepcion: this.state.Percepcion,
                    EsRuss: this.state.EsRuss,
                    IdPlanSunat: this.state.IdPlanSunat,
                    ContribuyenteCondicion: this.state.ContribuyenteCondicion,
                    ContribuyenteEstado: this.state.ContribuyenteEstado,
                    FechaDeInscripcion: this.state.FechaDeInscripcion,
                    SistemaContabilidad: this.state.SistemaContabilidad,
                    SistemaEmision: this.state.SistemaEmision,
                    UsuarioSolPrincipal: this.state.UsuarioSolPrincipal,
                    ClaveSolPrincipal: this.state.ClaveSolPrincipal
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
                .then(async ({data_tables, error}) => { //data tables arreglo de tablas
                    const [table_data] = data_tables;
                    if (!error) {
                        const [{IdEmpresa}] = table_data;
                        this.setState({IdEmpresaNew: IdEmpresa});
                        fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/empresa/add`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                IdEmpresa: "1",
                                RUC: this.state.Ruc,
                                RazonSocial: this.state.RazonSocial,
                                NombreComercial: this.state.NombreComercial
                            })

                        }).then(async () => {
                            console.log("empresa creada en API-Facturador")
                            let respuesta = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/logo`, {
                                method: "POST",
                                body: this.state.formDataL
                            });
                            let rpt = await respuesta.json();
                            // console.log(rpt.success)
                            if (rpt.success == false) {
                                Notify("No se ha subido correctamente el logo, edite la empresa y súbalo nuevamente", "warning", 2000)
                            }
                            let respuesta2 = await fetch(`${process.env.REACT_APP_API_FACTURADOR_URL}/upload/img/${this.state.Ruc}/firma`, {
                                method: "POST",
                                body: this.state.formDataF
                            });
                            let rpt2 = await respuesta2.json();
                            if (rpt2.success == false) {
                                Notify("No se ha subido correctamente la firma, edite la empresa y súbalo nuevamente", "warning", 2000)
                            }
                        }).catch(e => {
                            console.error(e)
                        })
                        // FIN ::API-F::

                        //Se guarda el logo y la firma

                        this.props.history.push("/empresas/");

                    }
                })
                .catch(err => console.log(err))
            //Guardar Logo Back

            // fetch('/api/empresas/Logos/Subir',{
            //     method:'POST',
            //     body:this.state.pruebaLogo
            // })
            // .then(res=>res.json())
            // .catch(err=>console.log(err))

            //Guardar Firma Back
            // fetch('/api/empresas/Firmas/Subir',{
            //     method:'POST',
            //     body:this.state.pruebaFirma
            // })
            // .then(res=>res.json())
            // .catch(err=>console.log(err))

        } else {
            notificationError()
        }
        e.preventDefault()


    }

// traerUbigeoPorRegion = () =>{

//     this.state.ubigeoReg = this.state.data.ubigeos.filter((ubigeoR) => {
//         return ubigeoR.CodProvincia === '00' && ubigeoR.CodDistrito === '00'
//     })

//   }

// traerUbigeoPorProvincia = (idRegion) =>{

//     this.state.ubigeoProv = this.state.data.ubigeos.filter((ubigeoP) => {
//         return ubigeoP.CodRegion === idRegion && ubigeoP.CodProvincia != '00' && ubigeoP.CodDistrito === '00'
//     })
//     this.setState({valueProvincia: this.state.ubigeoProv[0].CodProvincia})

// }

// traerUbigeoPorDistrito = (idRegion,idProvincia) => {

//     this.state.ubigeoDis = this.state.data.ubigeos.filter((ubigeoD) => {
//         return ubigeoD.CodRegion ===  idRegion && ubigeoD.CodProvincia === idProvincia && (ubigeoD.Ubigeo != null)
//     })
//     this.setState({valueDistrito: this.state.ubigeoDis[0].IdUbigeo, IdUbigeo: this.state.ubigeoDis[0].IdUbigeo})

//   }

    PlanChange = (event) => {
        this.setState({IdPlanSunat: event.target.value})
        // console.log(event.target.value);
    }

    MonedaChange = (event) => {
        // console.log(event.target.value);
        this.setState({IdMoneda: event.target.value})

    }

// RegionChange = (event) => {
//     this.setState({valueRegion: event.target.value })
//     this.traerUbigeoPorProvincia(event.target.value);
//     this.traerUbigeoPorDistrito(event.target.value,this.state.ubigeoProv[0].CodProvincia);
// }

// ProvinciaChange = (event) => {
//     this.setState({valueProvincia: event.target.value,IdUbigeo: event.target.value,valueDistrito: event.target.value  })
//     this.traerUbigeoPorDistrito(this.state.valueRegion, event.target.value );
// }

// DistritoChange = (event) =>{
//     this.setState({IdUbigeo: event.target.value,valueDistrito: event.target.value })
// }

// TraerEmpresaPorRUC = async (ruc) =>{
//     console.log("traersunat")
//     try {
//         const prefijo=`https://cors-anywhere.herokuapp.com/`
//         const response = await fetch(`${prefijo}https://api.sunat.cloud/ruc/${ruc}`,{mode: 'cors'});
//         const empresa = await response.json();
//         this.setState({
//             RazonSocial: empresa.razon_social,
//             Direccion: empresa.domicilio_fiscal,
//             NombreComercial: empresa.nombre_comercial,
//             ContribuyenteCondicion: empresa.contribuyente_condicion,
//             ContribuyenteEstado: empresa.contribuyente_estado,
//             FechaDeInscripcion: empresa.fecha_inscripcion,
//             SistemaContabilidad: empresa.sistema_contabilidad,
//             SistemaEmision: empresa.sistema_emision,
//         });
//         console.log(this.state.RepresentanteLegal)
//     }catch(error){
//       console.log(`Error --- >${error}`);
//     }
// };

    hallarUbigeo = async (codigoUbigeo) => {
        try {
            const response = await fetch(`/api/ubigeos/ubigeoPorCodigo/${codigoUbigeo}`);
            const data = await response.json();
            // console.log(data[0][0])
            this.setState({
                loading: false,
                ubigeo: data[0][0].NombreUbigeo,
                ubigeoNombrecodigo: data[0][0].NombreUbigeo.concat(" - ", data[0][0].Ubigeo).toUpperCase(),
                IdUbigeo: data[0][0].IdUbigeo
            })
        } catch (err) {
            console.log(`Error --- >${err}`);
        }
    }

    TraerEmpresaPorRUC_API = async (ruc) => {

        try {
            // const prefijo=`https://cors-anywhere.herokuapp.com/`
            // const response = await fetch(`${prefijo}http://190.117.160.67:3000/contribuyente/ruc/${ruc}`,{mode: 'cors'});
            //http://172.16.16.208:4000/contribuyente/ruc/
            const response = await fetch(`${process.env.REACT_APP_API_CONSULTA_RUC}/${ruc}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API_RUC}`
                }
            });
            const empresa = await response.json();

            if (empresa) {
                this.setState({
                    Ruc: empresa.ruc,
                    RazonSocial: empresa.razon_social,
                    Direccion: empresa.direccion,
                    NombreComercial: empresa.razon_social,
                    ContribuyenteCondicion: empresa.condicion_domicilio,
                    ContribuyenteEstado: empresa.estado,
                    codigoUbigeo: empresa.ubigeo,
                    mostrarLogos: true
                });
                // console.log(this.state.Ruc)
                this.hallarUbigeo(empresa.ubigeo)
            } else {
                this.setState({
                    Ruc: "",
                    RazonSocial: "",
                    Direccion: "",
                    NombreComercial: "",
                    ContribuyenteCondicion: "",
                    ContribuyenteEstado: "",
                    codigoUbigeo: "",
                    IdUbigeo: "",
                    ubigeoNombrecodigo: "",

                    msjerror: "Ruc No Existe en BD SUNAT",
                    mostrarLogos: false
                })
            }
        } catch (error) {
            this.setState({
                mostrarLogos: false
            })
            console.log(`Error --- >${error}`);
        }
    };


    handleSubmit = (e) => {
        e.preventDefault();
    }

    handleSubmitRUC = async (e) => {
        e.preventDefault();
        this.TraerEmpresaPorRUC_API(this.state.RucPre);
    };


    handleChange = (event) => {
        const {name, value} = event.target
        // console.log(this.state.Ruc)
        // console.log(event.target.name, " - ", event.target.value)
        let Errores = this.state.Errores;
        validarInputsEmpresa(Errores, name, value);
        this.setState({
            Errores,
            [event.target.name]: event.target.value
        });
    }

// handleLogout = () => {
//     firebase.auth().signOut()
//     .then(result => console.log(`${result.user.email} ha salido`))
//     .catch(error => console.log(`Error ${error.code}: ${error.message}`));
// }

// handleAuth = () => { //Funcion para usar el auth de firebase
//     const provider = new firebase.auth.GoogleAuthProvider();
//     firebase.auth().signInWithPopup(provider)
//         .then(result => console.log(`${result.user.email} ha iniciado sesión`))
//         .catch(error => console.log(`Error ${error.code}: ${error.message}`));
// }

// handleUpload = (event) => {
//     let file = event.target.files[0]
//     console.log("Logo 419: ",event.target.files[0])
//     const storageRef = firebase.storage().ref(`/Logos/${this.state.Ruc.concat("Logo")}`);
//     const task = storageRef.put(file);

//     task.on('state_changed', snapshot => {
//         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         this.setState({
//             uploadValue: percentage
//         })
//     }, error => {
//         console.log(error.message)
//     }, () => {
//         // this.setState({
//         //     uploadValue: 100,
//         //     picture: task.snapshot.downloadURL
//         // });
//         storageRef.getDownloadURL().then(url => {
//             this.setState({
//                 Logo: url //La url de la imagen subida
//             });
//             console.log("Logo "+this.state.Logo)
//         })

//     });

// }

// handleUploadFirma = (event) => {
//     let file = event.target.files[0]
//     const storageRef = firebase.storage().ref(`/Firmas/${this.state.Ruc.concat(".Firma")}`);
//     const task = storageRef.put(file);

//     task.on('state_changed', snapshot => {
//         let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         this.setState({
//             uploadValueFirma: percentage
//         })
//     }, error => {
//         console.log(error.message)
//     }, () => {
//         // this.setState({
//         //     uploadValue: 100,
//         //     picture: task.snapshot.downloadURL
//         // });
//         storageRef.getDownloadURL().then(url => {
//             this.setState({
//                 Firma: url //La url de la imagen subida
//             });
//             console.log("Firma "+this.state.Firma)
//         })

//     });

// }mostrarCorreosOpcionales
    mostrarTelefonosOpcionales = (e) => {
        if (this.state.mostrarTelefonosOpcionales == true) {
            this.setState({
                mostrarTelefonosOpcionales: false
            });
        } else if (this.state.mostrarTelefonosOpcionales == false) {
            this.setState({
                mostrarTelefonosOpcionales: true
            });
        }
    }

    mostrarCorreosOpcionales = (e) => {
        if (this.state.mostrarCorreosOpcionales == true) {
            this.setState({
                mostrarCorreosOpcionales: false
            });
        } else if (this.state.mostrarCorreosOpcionales == false) {
            this.setState({
                mostrarCorreosOpcionales: true
            });
        }

    }

    handleChangeSistemaContabilidad = (e) => {
        this.setState({
            SistemaContabilidad: e.target.value
        })
        // console.log(e.target.value)
    }

    handleChangeSistemaEmision = (e) => {
        this.setState({
            SistemaEmision: e.target.value
        })
        // console.log(e.target.value)
    }

    MonedaChecked = (IdMoneda) => {
        if (this.state.IdMoneda == IdMoneda)
            return true
        else {
            return false
        }
    }

    ContabilidadChecked = (contabilidad) => {
        if (this.state.SistemaContabilidad == contabilidad)
            return true
        else {
            return false
        }
    }

    EmisionChecked = (emision) => {
        if (this.state.SistemaEmision == emision)
            return true
        else {
            return false
        }
    }

    render = () => {
        if (this.state.loading === true) {
            return <PageLoading/>;
        }
        return (
            <React.Fragment>

                <div className="ml-4 mt-4">
                    <BotonCancelar
                        onClick={() => this.props.history.goBack()}
                        className="ml-4 mb-2 mt-2"
                    />
                </div>
                <form onSubmit={this.newEmpresa} className="d-flex justify-content-center">
                    <div className="almacenForm">


                        <EmpresasAddFormDatosSunat
                            onSubmit={this.newEmpresa}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleSubmitRUC={this.handleSubmitRUC}
                        />
                        <EmpresasAddFormDatosGenerales
                            onSubmit={this.newEmpresa}
                            state={this.state}
                            handleChange={this.handleChange}
                            handleSubmitRUC={this.handleSubmitRUC}
                            PlanChange={this.PlanChange}
                            RegionChange={this.RegionChange}
                            ProvinciaChange={this.ProvinciaChange}
                            DistritoChange={this.DistritoChange}
                            getPlanesSunat={this.getPlanesSunat}
                            getUbigeo={this.getUbigeo}
                            RetenedorChange={this.RetenedorChange}
                            PercepcionChange={this.PercepcionChange}
                            EsRussChange={this.EsRussChange}
                            MonedaChange={this.MonedaChange}
                            getMoneda={this.getMoneda}
                            mostrarTelefonosOpcionales={this.mostrarTelefonosOpcionales}
                            mostrarCorreosOpcionales={this.mostrarCorreosOpcionales}
                            handleChangeSistemaContabilidad={this.handleChangeSistemaContabilidad}
                            handleChangeSistemaEmision={this.handleChangeSistemaEmision}
                            MonedaChecked={this.MonedaChecked}
                            ContabilidadChecked={this.ContabilidadChecked}
                            EmisionChecked={this.EmisionChecked}
                        />
                        <div className="almacenDiv" hidden={!this.state.mostrarLogos}>
                            <EmpresasAddFormDatosLogoFirmas
                                onSubmit={this.newEmpresa}
                                state={this.state}
                                // handleLogout = {this.handleLogout}
                                // handleAuth = {this.handleAuth}
                                // validarExt = {this.validarExt}
                                // handleUpload = {this.handleUpload}
                                // handleUploadFirma = {this.handleUploadFirma}
                                // validarExtFirma = {this.validarExtFirma}
                                SubirLogo={this.SubirLogo}
                                SubirFirma={this.SubirFirma}
                            />
                        </div>
                        {/* <div align="center">
                            <button className="btn btn-primary" type="submit">Guardar <i className="ml-1 fa fa-save"></i></button>
                        </div> */}
                        <div className="AlmacenBoton mt-2 mb-2">
                            <BotonGuardarLetras type="submit"/>
                        </div>
                    </div>
                </form>
            </React.Fragment>
        )
    }

}

export default AddEmpresa
