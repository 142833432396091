import React from "react";
import "./styles/VerDetallesPresentaciones.css";
import ReactExport from "react-data-export";

import {BotonLetras} from "../componentes/Plantillas/Botones";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function listLotes(IdLote, lotes, handleLote) {
    return (
        <select
            name="IdLote"
            onChange={handleLote}
            className="custom-select"
            value={IdLote}
        >
            {lotes.map(lote => {
                return (
                    <option key={lote.IdLote} value={lote.IdLote}>
                        {lote.Codigo.concat("-", lote.Descripcion)}
                    </option>
                );
            })}
        </select>
    );
}

function listUnidades(IdUnidad, unidades, handleUnidad) {
    return (
        <select
            name="IdUnidad"
            onChange={handleUnidad}
            className="custom-select"
            required
            value={IdUnidad}
        >
            {unidades.map(unidad => {
                return (
                    <option key={unidad.IdUnidad} value={unidad.IdUnidad}>
                        {unidad.Descripcion}
                    </option>
                );
            })}
        </select>
    );
}

function listPreciosInactivos(
    NuevoNombrePrecio,
    preciosinactivos,
    handleNuevoNombrePrecio
) {
    return (
        <select
            name="NuevoNombrePrecio"
            onChange={handleNuevoNombrePrecio}
            className="custom-select"
            required
            value={NuevoNombrePrecio}
        >
            {preciosinactivos.map(inactivos => {
                return (
                    <option
                        key={inactivos.IdPrecioPlantilla}
                        value={inactivos.IdPrecioPlantilla}
                    >
                        {inactivos.Nombre}
                    </option>
                );
            })}
        </select>
    );
}

function listMonedas(IdMoneda, monedas, handleMoneda, precio) {
    return (
        <select
            style={{width: "auto"}}
            name="IdMoneda"
            onChange={e => handleMoneda(e, precio)}
            onLoad={handleMoneda}
            className="custom-select"
            required
            value={IdMoneda}
        >
            {monedas.map(moneda => {
                return (
                    <option key={moneda.IdMoneda} value={moneda.IdMoneda}>
                        {moneda.Abreviatura}
                    </option>
                );
            })}
        </select>
    );
}

function getPresentacionData(
    presentacion,
    lotes,
    unidades,
    handleNombre,
    handleDescripcion,
    handleLote,
    handleUnidad,
    handleMedida,
    mensajedeactualizacion,
    handleCodigoBarras,
    handleEsFacturable,
) {
    return (
        <>
            <div>
                <div className="card-body mr-5 ml-5">
                    <div className="form-group">
                        <label>Nombre</label>
                        <input
                            name="Nombre"
                            autoFocus
                            id="nombreVerPresentacion"
                            onChange={handleNombre}
                            value={presentacion.Nombre}
                            className="input__linea"
                            required
                            type="text"
                        />
                    </div>

                    <div className="form-group">
                        <label>¿Es facturable?</label>
                        <input className="ml-2"
                               onChange={handleEsFacturable}
                               checked={presentacion.EsFacturable}
                               value={presentacion.EsFacturable}
                               type="checkbox"
                        />
                    </div>

                    <div className="form-group">
                        <label>Descripción</label>
                        <textarea
                            name="Descripcion"
                            onChange={handleDescripcion}
                            value={presentacion.Descripcion}
                            className="input__linea"
                            rows="3"
                            type="text"
                        />
                    </div>

                    <div className="form-group">
                        <label>Código Barras</label>
                        <input
                            name="Descripcion"
                            onChange={handleCodigoBarras}
                            value={presentacion.CodigoBarras}
                            className="input__linea"
                            type="text"
                        />
                    </div>

                    <div className="form-group">
                        <label>Lote de Ingreso</label>
                        {listLotes(presentacion.IdLote, lotes, handleLote)}
                    </div>

                    <div className="form-group">
                        <label>Unidad de Medida</label>
                        {listUnidades(presentacion.IdUnidad, unidades, handleUnidad)}
                    </div>

                    <div className="form-group">
                        <label>Tipo de Cambio</label>
                        <input
                            name="TipoCambio"
                            onChange={handleMedida}
                            value={presentacion.Medida}
                            className="input__linea"
                            aria-describedby="basic-addon3"
                            required
                            type="number"
                            placeholder="Ingresa el valor de conversión según la unidad de medida."
                        />
                    </div>
                </div>
                <div className="card mb-3">
                    {mensajedeactualizacion ? (
                        <div className="alert alert-success" role="alert">
                            {mensajedeactualizacion}
                        </div>
                    ) : null}
                    <div className="card-body" align="right">
                        <BotonLetras type="submit" title="Actualizar Presentación">
                            Actualizar
                        </BotonLetras>
                    </div>
                </div>
            </div>
        </>
    );
}

function listPrecios(
    data,
    // onClick,
    onDelete,
    // IdPresentacion,
    AfectacionProducto,
    handleNombrePrecio,
    handlePrecio,
    handlePrecioIGV,
    handleCantidad,
    handlePorcentaje,
    handleObservacionesPrecio,
    actualizarPrecio2,
    monedas,
    handleMonedaChange
) {
    return data.map((precio, i) => {
        return (

            <tr key={i}>
                <td>
                    <input
                        name="NombrePrecio"
                        onChange={e => handleNombrePrecio(e, precio)}
                        defaultValue={precio.Nombre}
                        className="input__lineaTablePrecios"
                        disabled
                        type="text"
                    />
                </td>
                <td>
                    <input
                        name="Precio"
                        onChange={e => handlePrecio(e, precio)}
                        value={
                            precio.Precio
                        }
                        className="input__lineaTablePrecios"
                        required
                        type="text"
                    />
                </td>
                {AfectacionProducto && (
                    <td>
                        <input
                            name="PrecioIGV"
                            onChange={e => handlePrecioIGV(e, precio)}
                            value={
                                precio.PrecioIGV
                            }
                            className="input__lineaTablePrecios"
                            required
                            type="text"
                        />
                    </td>
                )}
                {precio.IdPrecioPlantilla !== 1 && (
                    <td>
                        <input
                            name="PorcentajePrecio"
                            onChange={e => handlePorcentaje(e, precio)}
                            value={precio.Porcentaje}
                            className="input__lineaTablePrecios"
                            style={{width: 30}}
                            type="text"
                            required
                        />
                    </td>)}
                {precio.IdPrecioPlantilla === 1 && (
                    <td>
                        -
                    </td>)}
                <td>
                    {listMonedas(precio.IdMoneda,
                        monedas,
                        handleMonedaChange,
                        precio
                    )}
                </td>
                <td>
                    <input
                        name="CantidadPrecio"
                        onChange={e => handleCantidad(e, precio)}
                        defaultValue={precio.Cantidad}
                        className="input__lineaTablePrecios"
                        style={{width: 30}}
                        type="text"
                    />
                </td>

                <td>
                    <div className="d-flex justify-content-start">
                        <button
                            style={{width: "50px"}}
                            className="boton__outline-pequenoPreciosList pr-2"
                            onClick={e => actualizarPrecio2(e, precio)}>
                            <i className="far fa-save"/>
                        </button>
                        <button
                            type="button"
                            className="boton__outline-pequeno--r"
                            onClick={onDelete(precio.IdPrecio)}
                        >
                            <i class="fas fa-trash-alt"/>
                        </button>
                    </div>
                </td>
            </tr>
        );
    });
}

function VerDetallesPresentacionUI(props) {
    return (
        <>
            <ul className="nav nav-tabs mb-3 mt-3" id="pills-tab" role="tablist">
                <li className="nav-item">
                    <a
                        className="nav-link active text-secondary"
                        id="pills-present-tab"
                        data-toggle="pill"
                        href="#pills-present"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                    >
                        PRESENTACIÓN {props.presentacion.Nombre}
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link text-secondary"
                        id="pills-precios-tab"
                        data-toggle="pill"
                        href="#pills-precios"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                    >
                        LISTA DE PRECIOS
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className="nav-link text-secondary"
                        id="pills-nuevo-precio-tab"
                        data-toggle="pill"
                        href="#pills-nuevo-precio"
                        role="tab"
                        aria-controls="pills-contact"
                        aria-selected="false"
                    >
                        NUEVO PRECIO
                    </a>
                </li>
            </ul>
            <div className="modal-contenedor">
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-present"
                        role="tabpanel"
                        aria-labelledby="pills-present-tab"
                    >
                        <form onSubmit={props.actualizarPresentacion}>
                            {getPresentacionData(
                                props.presentacion,
                                props.lotes,
                                props.unidades,
                                props.handleNombre,
                                props.handleDescripcion,
                                props.handleLote,
                                props.handleUnidad,
                                props.handleMedida,
                                props.mensajedeactualizacion,
                                props.handleCodigoBarras,
                                props.handleEsFacturable,
                            )}
                        </form>
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-precios"
                        role="tabpanel"
                        aria-labelledby="pills-precios-tab"
                    >
                        <h6 className="ml-4" style={{fontSize: '17px', color: 'red'}}>*No se olviden darle al botón
                            'Actualizar' una vez haya modificado un precio. (hacer esto por precio)</h6>
                        <h6 className="ml-4" style={{fontSize: '17px', color: 'red'}}>*Si la tabla no muestra la columna
                            IGV es porque el producto es exonerado o inafecto.</h6>

                        <div className="alert alert-info mt-3 mr-3 ml-3">
                            <strong>Importante</strong><br/>
                            Si el <strong>precio con IGV</strong> ingresado <strong>no concuerda </strong> al momento de
                            actualizar, <strong>asegurarse de lo siguiente</strong>.
                            <br/>
                            <strong>Eje:</strong>
                            Si mi <strong>precio con IGV</strong> ingresado es <strong>25.5 </strong>
                            pero al momento de <strong>actualizar</strong> se muestra <strong>24.999999 </strong>
                            colocar en el <strong>precio con IGV</strong> como <strong>25.500001</strong>
                        </div>

                        <div className="card-body" style={{padding: 0}}>
                            <div align="right" className="mb-1 mr-3">
                                <button
                                    className="boton__Rojo mr-2"
                                    title="Descargar PDF"
                                    onClick={props.pdfViewer(
                                        props.nombreProducto,
                                        props.presentacion.Nombre
                                    )}
                                >
                                    {<i className="fas fa-file-pdf"/>}
                                </button>
                                <ExcelFile
                                    element={
                                        <button
                                            type="button"
                                            className="boton__verdeOscuro"
                                            title="Descargar datos en EXCEL"
                                        >
                                            <i className="fas fa-file-excel"/>
                                        </button>
                                    }
                                    filename={
                                        "Reporte_Precios_" +
                                        props.nombreProducto +
                                        "_" +
                                        props.presentacion.Nombre
                                    }
                                >
                                    <ExcelSheet
                                        data={props.precios}
                                        name={"Precios de " + props.presentacion.Nombre}
                                    >
                                        <ExcelColumn label="Nombre del Precio" value="Nombre"/>
                                        <ExcelColumn label="Precio sin IGV" value="Precio"/>
                                        <ExcelColumn label="Precio con IGV" value="IGV"/>
                                        <ExcelColumn label="Porcentaje" value="Porcentaje"/>
                                        <ExcelColumn label="Cantidad" value="Cantidad"/>
                                        <ExcelColumn label="Moneda" value="Abreviatura"/>
                                        <ExcelColumn label="Observación" value="Observaciones"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            </div>
                            {/* Cambiar aqui la vista de los precios */}
                            <table id="precios-to-xls" className="table table-hover">
                                <thead>
                                <tr>
                                    <th scope="col">NombreDelPrecio</th>
                                    <th scope="col">P.SinIGV</th>
                                    {props.AfectacionProducto && (
                                        <th scope="col">P.ConIGV</th>
                                    )}
                                    {props.precio.IdPrecioPlantilla !== 1 && (
                                        <th scope="col">PCT (%)</th>
                                    )}
                                    <th scope="col">Mon.</th>
                                    <th scope="col">Cant.</th>
                                    <th scope="col" text-align="center">Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                {listPrecios(
                                    props.precios,
                                    // props.onClick,
                                    props.handleOnDeletePrecio,
                                    // props.presentacion.IdPresentacion,
                                    props.AfectacionProducto,

                                    props.handleNombrePrecio,
                                    props.handlePrecio,
                                    props.handlePrecioIGV,
                                    props.handleCantidad,
                                    props.handlePorcentaje,
                                    props.handleObservacionesPrecio,
                                    props.actualizarPrecio2,
                                    props.listMonedas,
                                    props.handleMoneda
                                )}
                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div
                        className="tab-pane fade card-body mr-5 ml-5"
                        id="pills-nuevo-precio"
                        role="tabpanel"
                        aria-labelledby="pills-nuevo-precio-tab"
                    >
                        <div className="form-group">
                            <label>Nombre</label>

                            {listPreciosInactivos(
                                props.NuevoNombrePrecio,
                                props.preciosinactivos,
                                props.handleNuevoNombrePrecio
                            )}

                        </div>
                        <form onSubmit={props.handleSubmitNewPrecio}>
                            <div className="form-group">
                                <label>Precio sin IGV</label>

                                <input
                                    name="NuevoPrecio"
                                    onChange={props.handleNuevoPrecio}
                                    value={props.NuevoPrecio}
                                    className="input__linea"
                                    required
                                    type="text"
                                />
                            </div>
                            {props.AfectacionProducto && (
                                <div className="form-group">
                                    <label>Precio con IGV</label>

                                    <input
                                        name="NuevoPrecioIGV"
                                        onChange={props.handleNuevoPrecioIGV}
                                        value={props.NuevoPrecioIGV}
                                        className="input__linea"
                                        required
                                        type="text"
                                    />
                                </div>
                            )}

                            <div className="form-group">
                                <label>Porcentaje</label>

                                <input
                                    name="NuevoPorcentajePrecio"
                                    onChange={props.handleNuevoPorcentaje}
                                    value={props.NuevoPorcentaje}
                                    className="input__linea"
                                    type="text"
                                />
                            </div>

                            <div className="form-group">
                                <label>Cantidad</label>

                                <input
                                    name="CantidadPrecio"
                                    onChange={props.handleNuevaCantidad}
                                    value={props.NuevaCantidad}
                                    className="input__linea"
                                    type="text"
                                />
                            </div>
                            <div className="form-group">
                                <label>Moneda</label>

                                {listMonedas(
                                    props.NuevaIdMoneda,
                                    props.monedas,
                                    props.handleNuevaMoneda
                                )}
                            </div>
                            <div className="form-group">
                                <label>Observaciones</label>

                                <textarea
                                    name="Observaciones"
                                    value={props.NuevasObservaciones}
                                    onChange={props.handleNuevasObservaciones}
                                    className="input__linea"
                                    rows="3"
                                    type="text"
                                />
                            </div>
                            {props.mensajenewprecio ? (
                                <div className="alert alert-success" role="alert">
                                    {props.mensajenewprecio}
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div align="right">
                                <BotonLetras type="submit">Guardar</BotonLetras>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VerDetallesPresentacionUI;
