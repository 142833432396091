import React from "react";
import {BOLETA, FACTURA, VALE} from "../../Global";

const _docs = [
    {key: FACTURA, name: "Factura"},
    {key: BOLETA, name: "Boleta"},
    {key: VALE, name: "Vale"}
]


export default class DocTypeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            docs: _docs
        }
        this.onSelect = this.onSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.value)
            this.props.onChange(this.state.docs.find(d => String(d.key) === String(this.props.value)))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (String(prevProps.value) !== String(this.props.value))
            this.props.onChange(this.state.docs.find(d => String(d.key) === String(this.props.value)))
    }

    onSelect(e) {
        const value = e.target.getAttribute('value')
        const tmpDocs = this.state.docs
        this.props.onChange(tmpDocs.find(d => String(d.key) === String(value)))
    }

    render() {
        const docs = this.state.docs
        const value = this.props.value;

        return (
            <div className="preventa__tipo--comprobante">
                {docs.map(d =>
                    <button
                        key={d.key}
                        type="button"
                        className={
                            (String(value) === String(d.key))
                                ? "preventa__tipo--comprobante--item--active2"
                                : "preventa__tipo--comprobante--item2"
                        }
                        value={d.key}
                        onClick={this.onSelect}
                    >
                        {d.name}
                    </button>
                )}
            </div>
        );
    }
}
