import {
    decimalAdjust,
    ICBPER as TasaICBPERGlobal,
    IGV as TasaIGVGlobal,
    IVAP as TasaIVAPGlobal,
    TRIBUTO
} from "../Global";

export const setValorUnitario = (_item, TasaIGV = TasaIGVGlobal, TasaICBPER = TasaICBPERGlobal, TasaIVAP = TasaIVAPGlobal) => {
    const item = {..._item};
    if (!('ValorUnitario' in item)) {
        if (!('PrecioConISC' in item)) {
            if (!('PrecioConIGV' in item)) {
                if (!('PrecioConIVAP' in item)) {
                    if (!('PrecioVenta' in item)) {
                        console.warn(item);

                    }
                    item.PrecioConIVAP = item.Tributos.includes(TRIBUTO.ICBPER)
                        ? item.PrecioVenta - TasaICBPER
                        : item.PrecioVenta
                }
                item.PrecioConIGV = item.Tributos.includes(TRIBUTO.IVAP)
                    ? item.PrecioConIVAP / (1 + TasaIVAP)
                    : item.PrecioConIVAP
            }
            item.PrecioConISC = item.Tributos.includes(TRIBUTO.IGV)
                ? item.PrecioConIGV / (1 + TasaIGV)
                : item.PrecioConIGV
        }
        item.ValorUnitario = item.Tributos.includes(TRIBUTO.ISC)
            ? item.PrecioConISC / (1 + item.TasaISC)
            : item.PrecioConISC
    }
    return item.ValorUnitario;
}

export function setPreciosYDescuento(item, TasaIGV = TasaIGVGlobal, TasaICBPER = TasaICBPERGlobal, TasaIVAP = TasaIVAPGlobal) {
    let Tributos = String(item.Tributos);

    const {
        ValorUnitario,
        DescuentoSI,
        TasaISC,
    } = item;

    const PrecioConISC = Tributos.includes(TRIBUTO.ISC)
        ? ValorUnitario * (1 + TasaISC)
        : ValorUnitario

    const PrecioConIGV = Tributos.includes(TRIBUTO.IGV)
        ? PrecioConISC * (1 + TasaIGV)
        : PrecioConISC

    const PrecioConIVAP = Tributos.includes(TRIBUTO.IVAP)
        ? PrecioConIGV * (1 + TasaIVAP)
        : PrecioConIGV

    const PrecioVenta = Tributos.includes(TRIBUTO.ICBPER)
        ? PrecioConIVAP + TasaICBPER
        : PrecioConIVAP

    const Descuento = DescuentoSI
        * (Tributos.includes(TRIBUTO.ISC) ? (1 + TasaISC) : 1)
        * (Tributos.includes(TRIBUTO.IGV) ? (1 + TasaIGV) : 1)
        * (Tributos.includes(TRIBUTO.IVAP) ? (1 + TasaIVAP) : 1)

    return {
        ...item,
        PrecioConISC,
        PrecioConIGV,
        PrecioConIVAP,
        PrecioVenta,
        Descuento,
    }
}

export function extractTwoDecimalsForNumber(number, cant = 2) {
    if (number) {
        let numberStr = number.toString()
        if (numberStr.includes('.')) {
            let indexOfPoint = numberStr.indexOf('.');
            let decimalPart = numberStr.substr(indexOfPoint + 1, numberStr.length);
            let normalPart = numberStr.substr(0, indexOfPoint);

            if (decimalPart.length > cant)
                decimalPart += numbersFactory(number - cant, "0")

            decimalPart = decimalPart.substr(0, cant);

            return Number(normalPart + "." + decimalPart);
        }
        return numberStr
    }
    return number;
}


export const getDescuentoSI = (item, TasaIGV = TasaIGVGlobal, TasaIVAP = TasaIVAPGlobal) => {
    const {
        Descuento,
        TasaISC,
        Tributos
    } = item;

    const DescuentoSinIVAP = Descuento / (Tributos.includes(TRIBUTO.IVAP) ? (1 + TasaIVAP) : 1)
    const DescuentoSinIGV = DescuentoSinIVAP / (Tributos.includes(TRIBUTO.IGV) ? (1 + TasaIGV) : 1)
    return DescuentoSinIGV / (Tributos.includes(TRIBUTO.ISC) ? (1 + TasaISC) : 1);
}

export const calcularItem = (_item, TasaIGV = TasaIGVGlobal, TasaICBPER = TasaICBPERGlobal, TasaIVAP = TasaIVAPGlobal) => {
    let item = {..._item};
    const {
        ValorUnitario,
        Cantidad,
        Tributos,
        DescuentoSI,
        TasaISC,
    } = item
    const ValorBruto = ValorUnitario * Cantidad;
    const BaseImponible = ValorBruto - DescuentoSI;
    const ISC = (Tributos.includes(TRIBUTO.ISC))
        ? BaseImponible * TasaISC
        : 0;
    const IGV = (Tributos.includes(TRIBUTO.IGV))
        ? (BaseImponible + ISC) * TasaIGV
        : 0;
    const IVAP = (Tributos.includes(TRIBUTO.IVAP))
        ? (BaseImponible + ISC + IGV) * TasaIVAP
        : 0;
    const ICBPER = (Tributos.includes(TRIBUTO.ICBPER))
        ? Cantidad * TasaICBPER
        : 0;
    const Total = BaseImponible + ISC + IGV + IVAP + ICBPER;


    if (BaseImponible < 0) {
        console.warn(item);

    }
    if (ISC < 0) {
        console.warn(item);

    }
    if (IGV < 0) {
        console.warn(item);

    }
    if (IVAP < 0) {
        console.warn(item);

    }
    if (ICBPER < 0) {
        console.warn(item);

    }

    item = setPreciosYDescuento(item, TasaIGV, TasaICBPER, TasaIVAP)

    return {
        ...item,
        ValorBruto,
        BaseImponible,
        IGV,
        ISC,
        ICBPER,
        IVAP,
        Total,
    }
}

export const calcularVentaNormalizada = Venta => {

    const Items = Venta.Items.map(item => calcularItem(item, Venta.TasaIGV, Venta.TasaICBPER, Venta.TasaIVAP));


    let SumatoriaGravadas = 0;
    let SumatoriaExoneradas = 0;
    let SumatoriaInafectas = 0;
    let SumatoriaDescuentos = 0;
    let SumatoriaISC = 0;
    let SumatoriaIGV = 0;
    let SumatoriaIVAP = 0;
    let Gratuitas = 0;
    let ICBPER = 0;
    Items.forEach(item => {

        ICBPER += item.ICBPER;

        if (item.Gratuito) {
            Gratuitas += item.BaseImponible;
            return;
        }

        SumatoriaDescuentos += item.DescuentoSI;

        if (item.Tributos.includes(TRIBUTO.IGV)
            || item.Tributos.includes(TRIBUTO.IVAP)) SumatoriaGravadas += item.BaseImponible;
        else if (item.Tributos.includes(TRIBUTO.INAFECTO)) SumatoriaInafectas += item.BaseImponible;
        else if (item.Tributos.includes(TRIBUTO.EXONERADO)) SumatoriaExoneradas += item.BaseImponible;
        else if (item.Tributos.includes(TRIBUTO.EXPORTACION)) {
            console.warn(item);
        } else if (item.Tributos.includes(TRIBUTO.GRATUITO)) {
            console.warn(item);
        } else if (item.Tributos.includes(TRIBUTO.OTROS)) {
            console.warn(item);
        } else if (item.Tributos.length === 0) {
            console.warn(item);
        } else {
            console.warn(item);
        }

        SumatoriaIGV += item.IGV
        SumatoriaISC += item.ISC
        SumatoriaIVAP += item.IVAP
    });

    const BasesImponiblesNoGratuitas = (SumatoriaGravadas + SumatoriaExoneradas + SumatoriaInafectas);

    const DescuentoGlobal = BasesImponiblesNoGratuitas * Venta.PorcentajeDescuento;
    const Descuento = DescuentoGlobal + SumatoriaDescuentos

    const Gravadas = SumatoriaGravadas * (1 - Venta.PorcentajeDescuento)
    const Exoneradas = SumatoriaExoneradas * (1 - Venta.PorcentajeDescuento)
    const Inafectas = SumatoriaInafectas * (1 - Venta.PorcentajeDescuento)
    const ISC = SumatoriaISC * (1 - Venta.PorcentajeDescuento)
    const IGV = SumatoriaIGV * (1 - Venta.PorcentajeDescuento)
    const IVAP = SumatoriaIVAP * (1 - Venta.PorcentajeDescuento)

    const Total = (
        Gravadas
        + Exoneradas
        + Inafectas
        + ISC
        + IGV
        + IVAP
        + ICBPER
    );

    return {
        ...Venta,
        Items,
        DescuentoGlobal,
        Descuento,
        Gravadas,
        Exoneradas,
        Inafectas,
        Gratuitas,
        ISC,
        IGV,
        IVAP,
        ICBPER,
        Total,
    };
}

export function calcTotalDetVenta(cantidad, precio, descuento = 0, decimales = -3) {
    let prVenta = -decimalAdjust('floor', precio, -2);
    let total = Math.abs(decimalAdjust('floor', cantidad * prVenta, decimales));
    return decimalAdjust('floor', total - descuento, decimales);
}

export const calcularVenta = _Venta => {
    const Venta = {..._Venta};

    Venta.TasaIGV = Venta.TasaIGV || TasaIGVGlobal;
    Venta.TasaICBPER = Venta.TasaICBPER || TasaICBPERGlobal;
    Venta.TasaIVAP = Venta.TasaIVAP || TasaIVAPGlobal;

    Venta.Items = Venta.Items.map(_item => {
        const item = {..._item};

        if (item.Tributos.includes(TRIBUTO.ISC) && !item.TasaISC) {

        }

        if (!('DescuentoSI' in item)) {
            if (!('Descuento' in item)) item.DescuentoSI = 0;
            else item.DescuentoSI = getDescuentoSI(item, Venta.TasaIGV, Venta.TasaIVAP);
        } else {
            item.DescuentoSI = item.DescuentoSI || 0;
        }

        item.ValorUnitario = setValorUnitario(item, Venta.TasaIGV, Venta.TasaICBPER, Venta.TasaIVAP);

        return item
    })

    if (!('PorcentajeDescuento' in Venta)) {
        Venta.PorcentajeDescuento = 0
        if (Venta.Descuento) {
            const {Total, ICBPER} = calcularVentaNormalizada(Venta);
            Venta.PorcentajeDescuento = Venta.Descuento / (Total - ICBPER)
        }
    }

    return calcularVentaNormalizada(Venta);
}

export default calcularVenta;


export function extractDecimals(number, cant, roundNumber = null) {
    if (number) {
        let numberStr = String(number);
        let point = numberStr.indexOf('.');
        if (point !== -1) {
            let enteroPart = numberStr.substr(0, point);
            let decimalPart = numberStr.substr(point + 1, numberStr.length);
            decimalPart = decimalPart.length <= cant ? decimalPart : `${decimalPart}${numbersFactory(cant - decimalPart.length, "0")}`
            decimalPart = decimalPart.substr(0, cant);
            let lastNumber = Number(decimalPart.slice(-1));
            decimalPart = roundNumber ? (lastNumber === roundNumber ? `${lastNumber}${lastNumber + 1 > 9 ? "0" : lastNumber}` : decimalPart) : decimalPart;
            return Number(`${enteroPart}.${decimalPart}`);
        }
        return number;
    }
    return number;
}


export function lmao(number, cant = 6) {
    if (number) {
        let numberStr = String(number);
        let point = numberStr.indexOf('.');
        if (point !== -1) {
            let decimalPart = numberStr.substr(point + 1, numberStr.length);
            let enteroPart = numberStr.substr(0, point);

            decimalPart = decimalPart.length <= cant ? decimalPart : `${decimalPart}${numbersFactory(cant - decimalPart.length, "0")}`

            let percent = Number(decimalAdjust('floor', numberCount(decimalPart) / decimalPart.length, -1));

            let applyNine = percent >= 0.6;

            decimalPart = applyNine ? setCharAt(decimalPart, decimalPart.length - 1, "9") : decimalPart;

            return Number(`${enteroPart}.${decimalPart}`).toFixed(cant);
        }
        return number;
    }
    return number;
}

function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
}

function numberCount(str, number = "9") {
    let cont = 0;
    for (let i = 0; i < str.length; i++)
        if (str[i] === number)
            cont++;
    return cont;
}

function numbersFactory(cant, number) {
    let str = "";
    for (let i = 0; i < cant; i++)
        str += number
    return str;
}
