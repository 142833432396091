import React from "react";
import {TIPO_STOCK} from "../../Global";
import {defaultGetHeaders, pfetch} from "../../helpers/wrappers";
import {setPreciosYDescuento} from "../../helpers/calcularVenta";
import {notificarError} from "../Almacenes/AlmacenNotify";
import ListaProductosPreventa from "../Preventas/ListaProductosPreventa";

export default class ProductPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            search: "",
            prods: [],
            isStockGood: true
        }
        this.timer = null
        this.onChangeOtherStock = this.onChangeOtherStock.bind(this)
        this.onChangeSearch = this.onChangeSearch.bind(this)
        this.onClickItem = this.onClickItem.bind(this)
        this.reloadProds = this.reloadProds.bind(this)
        this.onSubmitSearch = this.onSubmitSearch.bind(this)
    }

    componentDidMount() {
        this.fetchProds()
    }

    onClickItem(e) {
        const cleanSearch = this.props.cleanSearch
        if (cleanSearch && this.state.search && this.state.search.length) {
            this.onChangeSearch({target: {value: ""}})
            this.fetchProds(this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE, '')
        }
        this.props.onSelectItem({...e})
    }

    onChangeSearch(e) {
        const val = e.target.value
        this.setState({search: val})
    }

    onSubmitSearch(e) {
        e.preventDefault();
        const tipoStock = this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        this.fetchProds(tipoStock, this.state.search, () => {
            if (this.state.prods.length === 1)
                this.onClickItem({...this.state.prods[0]})
        })
    }

    fetchProds(idTipoStock = TIPO_STOCK.CON_COMPROBANTE, query = '', callback = null) {
        pfetch({
                fetch: {
                    url: `/api/preVentas/?search=${query}&idTipoStock=${idTipoStock}`,
                    method: "GET",
                    headers: defaultGetHeaders()
                },
                before: () => this.setState({isLoading: true}),
                then: p => this._setProds(p, callback),
                error: () => notificarError("No se pudo cargar los productos"),
                finally: () => this.setState({isLoading: false})
            }
        )
    }

    _setProds(prods, callback = null) {
        const newProds = prods.productos.map(d => {
            const itemCalculado = setPreciosYDescuento(d)
            return {
                ...d,
                Precio: itemCalculado.PrecioVenta,
                PrecioReferencial: itemCalculado.PrecioVenta,
                Gratuito: parseInt(d.Gratuito)
            }
        })
        this.setState({prods: newProds}, callback)
    }

    onChangeOtherStock(e) {
        const checked = e.target.checked;
        this.setState({isStockGood: checked})
        this.fetchProds(checked ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE)
    }

    reloadProds() {
        const tipoStock = this.state.isStockGood ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        const search = this.state.search
        this.fetchProds(tipoStock, search)
    }

    render() {
        return (
            <>
                <div className="PreVBuscarProducto">

                    <form style={{display: "contents"}} onSubmit={this.onSubmitSearch}>
                        <input
                            type="text"
                            placeholder="Buscar Producto"
                            className="input__linea mr-2"
                            value={this.state.search}
                            onChange={this.onChangeSearch}
                        />
                    </form>

                    <label className="mr-2 mt-1">Stock con Comprobante</label>
                    <label className="checkbox-togglePreventa">
                        <div className="togglePreventa">
                            <input
                                type="checkbox"
                                name="filtroTipoStock"
                                className="toggle-state"
                                onChange={this.onChangeOtherStock}
                                checked={this.state.isStockGood}
                                disabled={this.state.isLoading}
                            />
                            <div className="togglePreventa-inner">
                                <div className="indicator"/>
                            </div>
                            <div className="active-bg"/>
                        </div>
                    </label>
                </div>

                <div className="form-group encabezadoPV">

                    <div className="preventa__tipo--comprobante">

                    </div>

                    <ListaProductosPreventa
                        load={this.state.isLoading}
                        productos={this.state.prods}
                        AddProductoInList={this.onClickItem}
                        checkedTipoStock={this.state.isStockGood}
                    />

                    <button id={"BtnReloadProds"} hidden className="btn btn-primary" onClick={this.reloadProds}>
                        reload
                    </button>
                </div>

            </>
        );
    }
}
