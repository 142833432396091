import React from 'react';
import jsPDF from "jspdf";
import moment from 'moment';
import Notify from '../Notify.js';
import Moment from 'react-moment';
import {BotonCancelar2, BotonLetras} from "../Plantillas/Botones";
import {loadLogoByRUC} from '../../helpers/funcionesGlobales.js';
import "../styles/TurnosDetalleStyle.css"
import {
    decimalAdjust,
    EFECTIVO,
    ESTADO_CE, ESTADO_VALE, ESTADO_VENTA,
    TIPO_MOV_CAJA,
} from "../../Global";


class turnosDetalle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mostrarTurno: true,
            IdCaja: 0,
            dataCaj: {
                cajasSucursal: []
            },
            IdTurno: 0,
            dataTur: {
                turnos: []
            },
            //Info de movimientos por turnos
            IdMovimientos: 1,
            data: {
                movCajas: []
            },
            //Info de usuarios por sucursal
            IdUsuario: 0,
            dataUser: {
                usuariosSucursal: []
            },
            logoUrl: null,
            modalIsOpen: false,
            PDF: true,
            btnVerDetallesMov: true, //Se encarga de ver si se muestra o no el boton ver detalles movimiento en la lista. Acá SI es necesario ver detalles el movimiento
            fechaBusquedaInicio: '', //Buscar los turnos desde una fecha
            fechaBusquedaFin: '',
            //confirmar si hay data o hay un error
            loading: false,
            error: null,
            tiposPago: [],
            datosReporte: {},
            isLoadingReporte: false,
            montoInicial: 0,
            extImg: 'png'
        }
        this.limpiarData = this.limpiarData.bind(this)
        this.obtenerCajas = this.obtenerCajas.bind(this);
        this.obtenerTurnos = this.obtenerTurnos.bind(this);
        this.obtenerUsuarios = this.obtenerUsuarios.bind(this);
        this.handleChangeFechaBusqueda = this.handleChangeFechaBusqueda.bind(this);
        this.handleChangeUsuario = this.handleChangeUsuario.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.ToDay = this.ToDay.bind(this)
        this.onClickGenerarReport = this.onClickGenerarReport.bind(this);
        this.onCambioTurnoChange = this.onCambioTurnoChange.bind(this);
    }

    componentDidMount() {
        this.obtenerCajas();
        this.obtenerUsuarios();
        this.ToDay();
        this.fetchTiposPago();
    }

    fetchTiposPago() {
        fetch('/api/pagos/tipopagos')
            .then(async res => {
                let jsonRes = await res.json();
                jsonRes.respuesta.forEach(t => {
                    t['isTarjeta'] = t.Nombre.includes("Tarjeta")
                });
                this.setState({tiposPago: [...jsonRes.respuesta]});
            });
    }

    limpiarData = async () => {
        this.state.IdTurno = 0;
        this.setState({datosReporte: {}});
        await this.obtenerTurnos(0, false);
    }

    obtenerCajas = async () => {
        try {
            const response = await fetch(`/api/turnos/cajas/`);
            const data = await response.json();
            this.setState({
                loading: false,
                dataCaj: data,
                extImg: data.extImg
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerUsuarios = async () => {
        try {
            const response = await fetch(`/api/turnos/usuarios`); //Trae turnos por Sucursal
            const data = await response.json();
            // console.log(data)
            this.setState({
                loading: false,
                dataUser: data
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerTurnos = async (IdCaja, mostrarNotify) => {

        if (this.state.fechaBusquedaInicio == '' && mostrarNotify == true) {
            return Notify("Debes ingresar una fecha de inicio", "warning", 1500)
        }
        if (this.state.fechaBusquedaFin == '' && mostrarNotify == true) {
            return Notify("Debes ingresar una fecha de fin", "warning", 1500)
        }
        if (this.state.IdCaja == '' && mostrarNotify == true) {
            return Notify("Debes seleccionar una caja", "warning", 1500)
        }
        if (this.state.IdUsuario == '' && mostrarNotify == true) {
            return Notify("Debes seleccionar un usuario", "warning", 1500)
        }
        if (mostrarNotify) {
            try {
                const response = await fetch(`/api/turnos/turnosCaj/${IdCaja}/${this.state.fechaBusquedaInicio}/${this.state.fechaBusquedaFin}/${this.state.IdUsuario}`);
                const data = await response.json();
                this.setState({
                    dataTur: data
                })
                if (data.turnos != 0) {
                    const logoP = loadLogoByRUC(data.turnos[0].Ruc, "logo", this.state.extImg);
                    const logo = await logoP;
                    this.setState({
                        mostrarTurno: false,
                        logoUrl: logo.src
                    })
                } else {
                    this.setState({
                        mostrarTurno: true,
                        logoUrl: ''
                    })
                    return Notify("No se han encontrado turnos, por favor revise los parámetros de búsqueda", "warning", 3500)
                }
            } catch (error) {
                this.setState({loading: false, error: error})
            }
        }
    }

    handleCloseModal = () => {
        this.setState({
            modalIsOpen: false
        })

    }

    handleChangeCaja = (e) => {
        this.setState({
            IdCaja: e.target.value
        })
        if (e.target.value == 0) {
            this.setState({
                IdTurno: 0
            })
        }
        this.setState({
            mostrarTurno: true
        })
        this.limpiarData()

    }

    handleChangeUsuario = async (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            PDF: true,
            IdTurno: 0,
            mostrarTurno: true
        })
        this.limpiarData()
    }

    handleChangeFechaBusqueda = async (e) => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            PDF: true,
            IdTurno: 0,
        })
        this.limpiarData()
    }

    ToDay = () => {
        let today = new Date();
        let hoy = ''.concat(today.getFullYear())
        if ((today.getMonth() + 1) < 10) {
            hoy = hoy.concat('-0', (today.getMonth() + 1))
        } else {
            hoy = hoy.concat('-', (today.getMonth() + 1))
        }
        if ((today.getDate()) < 10) {
            hoy = hoy.concat('-0', (today.getDate()))
        } else {
            hoy = hoy.concat('-', (today.getDate()))
        }
        return hoy;
    }

    handleSavePDF = async () => {

        let doc = new jsPDF({});

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(13);


        const logoP = loadLogoByRUC(this.state.dataTur.turnos[0].Ruc, "logo", this.state.extImg);
        const logo = await logoP;
        doc.addImage(logo, "JPEG", 5, 5, 60, 15);

        const turnDetalle = this.state.dataTur.turnos.filter((obj) => {
            return obj.IdTurnoCaja == this.state.IdTurno
        })

        doc.text(`INFORMACIÓN DEL TURNO`, 75, 25);
        doc.line(74, 26, 137, 26);

        doc.setFontSize(11);
        doc.text(`Usuario: ${this.state.data.movCajas[0].Usuario} `, 9, 34);
        doc.text(`Fecha: ${moment(turnDetalle[0].FechaInicio).format("DD-MM-YYYY")}`, pageWidth - 57, 34);
        doc.text(`Observación: ${turnDetalle[0].Observacion}`, 9, 40);

        doc.setFontSize(13);
        doc.text(`MOVIMIENTOS DEL TURNO`, 75, 48);
        doc.line(74, 49, 137, 49);


        doc.autoTable({
            body: this.state.data.movCajas,
            bodyStyles: {fontSize: 10},
            headStyles: {fontSize: 11},
            startY: 55,
            margin: {
                right: 10,
                left: 10,
                bottom: 10
            },
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                cellWidth: "auto",
                halign: "center"
            },
            PageBreak: "avoid",
            columns: [
                {header: 'FECHA', dataKey: 'FechaHora'},
                {header: 'MONTO', dataKey: `Monto`},
                {header: 'TIPO', dataKey: 'Tipo'},
                {header: 'OBSERVACION', dataKey: 'Observacion'},
                {header: 'SALDO', dataKey: 'Saldo'},
                {header: 'SUPERVISOR', dataKey: 'Supervisor'},
            ],
            theme: "plain"
        });

        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        let nombreArchivo = `${this.ToDay()}` + "-Movimientos";
        doc.autoPrint(); //Imprimir
        window.open(doc.output("bloburl"), "_blank");
        doc.save(nombreArchivo + ".pdf");
    }

    fechaFin = (fecha) => {
        if (fecha == null) {
            return "En curso"
        } else {
            return <Moment format="MMMM Do YYYY, h:mm:ss a">{fecha}</Moment>
        }
    }

    async onClickGenerarReport(e) {
        if (this.state.IdTurno.toString() === "0" || this.state.IdTurno.toString().length === 0)
            Notify("Debe seleccionar un turno", 'error')
        else {

            this.setState({isLoadingReporte: true});

            let objReporte = {
                tiposPago: [...this.state.tiposPago],
                totalBadTarjeta: 0
            }

            for (let i = 0; i < objReporte.tiposPago.length; i++) {
                objReporte.tiposPago[i].montoAceptado = await this.getTotalByComprobante(objReporte.tiposPago[i].IdTipoPago, `${ESTADO_CE.ACEPTADO},${ESTADO_CE.PENDIENTE},${ESTADO_VENTA.PROCESADA},${ESTADO_CE.RECHAZADO}`, `${ESTADO_VALE.PENDIENTE},${ESTADO_VALE.ANULADO},${ESTADO_VALE.PROCESADO}`);
                objReporte.tiposPago[i].montoAnulado = await this.getTotalByComprobante(objReporte.tiposPago[i].IdTipoPago, `${ESTADO_CE.ANULADO},${ESTADO_VENTA.PROCESADA}`, `${ESTADO_VALE.ANULADO}`)

                if (objReporte.tiposPago[i].isTarjeta)
                    objReporte.totalBadTarjeta += Number(objReporte.tiposPago[i].montoAnulado);
            }

            objReporte.sumTotal = 0;
            objReporte.tiposPago.forEach(t => objReporte.sumTotal += (t.montoAceptado - t.montoAnulado))


            const mtoTotalEfectivo = this.calcularMontoByTipoPago(objReporte.tiposPago, EFECTIVO);
            objReporte.totalIngresosCaja = await this.getTotalMovsCaja(this.state.IdTurno, TIPO_MOV_CAJA.ENTRADA)
            objReporte.totalSalidasCaja = await this.getTotalMovsCaja(this.state.IdTurno, TIPO_MOV_CAJA.SALIDA)

            objReporte.totalCaja = Number(this.state.montoInicial) + mtoTotalEfectivo + objReporte.totalIngresosCaja - (objReporte.totalSalidasCaja + objReporte.totalBadTarjeta)

            this.setState({isLoadingReporte: false, datosReporte: objReporte});
        }
    }

    async getTotalMovsCaja(idTurno, tipoMov) {
        let res = await fetch(`/api/turnos/total-movs-caja-sin-comprobante/${idTurno}/${tipoMov}`)
        let data = await res.json()
        return data.total.total;
    }

    calcularMontoByTipoPago(tiposPago, tipoPago) {
        let monto = 0;
        tiposPago.filter(t => t.IdTipoPago === tipoPago)
            .forEach(t => monto += (t.montoAceptado - t.montoAnulado));
        return monto;
    }

    async getTotalByComprobante(idsTipoPago, estadoVenta, estadoVale) {
        let queryParams =
            `idsTipoPago=${idsTipoPago}&
            idTurnoCaja=${this.state.IdTurno}&
            estadoVenta=${estadoVenta}&
            estadoVale=${estadoVale}            
            `.replace(/\s/g, "");
        let response = await fetch(`/api/turnos/total-ventas?${queryParams}`);
        let resJson = await response.json();
        return Number(resJson.total);
    }

    onCambioTurnoChange(e) {
        const {name, value} = e.target
        let turnos = this.state.dataTur.turnos.filter(t => t.IdTurnoCaja.toString() === value.toString())
        this.setState({
            [name]: value,
            PDF: true,
            montoInicial: turnos.length ? turnos[0].MontoInicial : 0
        })
    }

    render() {

        const {tiposPago} = this.state;

        return (
            <React.Fragment>
                <div className="contenedorAbsoluto">
                    <div className="turnosBotones">
                        <BotonCancelar2
                            onClick={() => this.props.history.goBack()}
                            className="ml-4 mb-2 mt-2"
                        />
                        <div className="turnosBotonesCargarImprimir">
                            <BotonLetras className="ml-4 mr-4 mt-2 mb-2"
                                         onClick={() => {
                                             this.obtenerTurnos(this.state.IdCaja, true)
                                         }}
                            >
                                Cargar Turnos
                            </BotonLetras>
                        </div>
                    </div>

                    <div className="turnosBusqueda">
                        <div className="turnoBusquedaItem">
                            <b style={{color: "red", marginRight: '7px'}}>* </b> Inicio:
                            <input required
                                   name="fechaBusquedaInicio"
                                   className="input__lineaTurnos"
                                   onChange={this.handleChangeFechaBusqueda}
                                   defaultValue={this.state.fechaBusquedaInicio}
                                   type="date"
                                   max={this.ToDay()}/>
                        </div>
                        <div className="turnoBusquedaItem">
                            <b style={{color: "red", marginRight: '7px'}}>* </b> Cierre:
                            <input required
                                   name="fechaBusquedaFin"
                                   className="input__lineaTurnos ml-2"
                                   onChange={this.handleChangeFechaBusqueda}
                                   defaultValue={this.state.fechaBusquedaFin}
                                   type="date"
                                   min={this.state.fechaBusquedaInicio == '' ? this.ToDay(new Date()) : this.state.fechaBusquedaInicio}
                                   max={this.ToDay()}
                            />
                        </div>
                    </div>
                    <div className="turnosBusqueda">
                        <div className="turnoBusquedaItem">
                            <b style={{color: "red", marginRight: '7px'}}>* </b> Cajas:
                            <select required className="input__lineaTurnos" name="IdCaja"
                                    onChange={this.handleChangeCaja} defaultValue={this.state.IdCaja}>
                                <option defaultValue value={0}>Seleccionar Caja</option>
                                {
                                    this.state.dataCaj.cajasSucursal.map((obj) => {
                                        return <option key={obj.IdCaja} value={obj.IdCaja}>{obj.Nombre}</option>
                                    })}
                            </select>
                        </div>
                        <div className="turnoBusquedaItem">
                            <b style={{color: "red", marginRight: '7px'}}>* </b> Usuario:F
                            <select required className="input__lineaTurnos" name="IdUsuario"
                                    onChange={this.handleChangeUsuario} defaultValue={this.state.IdUsuario}
                                    aria-haspopup="true" aria-expanded="false">
                                <option defaultValue value={0}>Seleccionar Usuario</option>
                                {this.state.dataUser.usuariosSucursal.map((obj) => {
                                    return <option key={obj.IdUsuario} value={obj.IdUsuario}>{obj.Usuario}</option>
                                })}
                            </select>

                        </div>
                    </div>

                    <div className='contendorInfo' hidden={this.state.mostrarTurno}>
                        <div className="turnosBusqueda">
                            <div className="Infotexto">
                                <b style={{color: "red", marginRight: '7px'}}>* </b> Turnos:
                            </div>
                            <div className="Infoselect">
                                <select required className="input__lineaTurnos" name="IdTurno"
                                        value={this.state.IdTurno} onChange={this.onCambioTurnoChange}
                                        aria-haspopup="true" aria-expanded="false">
                                    <option defaultValue value={0}>Seleccionar Turno</option>
                                    {this.state.dataTur.turnos.map((obj) => {
                                        if (obj.FechaFin !== null) {
                                            return <option key={obj.IdTurnoCaja}
                                                           value={obj.IdTurnoCaja}>Apertura: {obj.FechaInicio} -
                                                Cierre: {obj.FechaFin}</option>
                                        } else {
                                            return <option key={obj.IdTurnoCaja}
                                                           value={obj.IdTurnoCaja}>Apertura: {obj.FechaInicio} - Cierre:
                                                Turno en curso</option>
                                        }
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-auto">
                                <BotonLetras onClick={this.onClickGenerarReport} disabled={this.state.isLoadingReporte}>
                                    {this.state.isLoadingReporte ? "Cargando" : "Generar reporte"}
                                </BotonLetras>
                            </div>
                        </div>

                        <div className="alert alert-info mt-3">
                            Las anulaciones de ventas que contuvo los siguientes modos de pago
                            <ul>
                                <li>Master</li>
                                <li>Visa</li>
                                <li>American</li>
                            </ul>
                            En caja se descuentan del <strong>efectivo</strong>.
                            Si hay una diferencia debe sumar las anulaciones y restárselas al efectivo en esta vista.
                        </div>

                        {
                            Object.keys(this.state.datosReporte).length ? (
                                <div>
                                    <div className="row mt-3">
                                        <div className="col-sm-auto" style={{color: "#01a59c"}}>
                                            Total a
                                            entregar efectivo: {decimalAdjust('floor', this.state.datosReporte.totalCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Total ingresos
                                            caja: {decimalAdjust('floor', this.state.datosReporte.totalIngresosCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Total salidas
                                            caja: {decimalAdjust('floor', this.state.datosReporte.totalSalidasCaja, -2)}
                                        </div>
                                        <div className="col-sm-auto">
                                            Monto Apertura: {decimalAdjust('floor', this.state.montoInicial, -2)}
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <table className="table table-hover">
                                            <thead>
                                            <tr>
                                                <th scope="col">Tipo de pago</th>
                                                <th scope="col">Monto aceptados $</th>
                                                <th scope="col">Monto anulados $</th>
                                                <th scope="col">Total $</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                tiposPago.map((t, i) => (
                                                    <tr key={`tr_${i}`}>
                                                        <td>{t.Nombre}</td>
                                                        <td>{t.montoAceptado}</td>
                                                        <td>{t.montoAnulado}</td>
                                                        <td>{t.montoAceptado - t.montoAnulado}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr>
                                                <td>
                                                    Totales
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', tiposPago.map(t => t.montoAceptado).reduce((a, b) => a + b, 0), -6)}
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', tiposPago.map(t => t.montoAnulado).reduce((a, b) => a + b, 0), -6)}
                                                </td>
                                                <td>
                                                    {decimalAdjust('floor', this.state.datosReporte.sumTotal, -6)}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            ) : null
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default turnosDetalle;
