import React from "react";
import {useHistory} from "react-router-dom";
import Notify from "../componentes/Notify";
import useLogin from "../helpers/useLogin";
import "./styles/Login.css";
import logo from "../componentes/images/mifacturaperu.png";

const Login = ({location}) => {
    const login = useLogin();
    const history = useHistory();

    const usernameInput = React.createRef();
    const passwordInput = React.createRef();

    const handleLoginClick = async e => {
        e.preventDefault();

        const username = usernameInput.current.value;
        const password = passwordInput.current.value;

        const status = await login(username, password);
        if (status !== true) return Notify(status.message, status.alertType);

        const {from} = location.state || {from: "/LogoAplicativo"};

        let reloadPromise = new Promise((resolve, reject) => {
            resolve('');
        })

        reloadPromise.then(() => history.push(from))
            .then(() => window.location.reload(true))
    };

    return (
        <div className="login__padre">
            <div id="login__body">
                <div className="login__principal">
                    <div className="login__hijo__central">
                        <div className="d-flex align-items-center flex-column">
                            <img src={logo}/>
                            <h6 className="mt-2">Sistema de Facturación Electrónica</h6>
                        </div>
                        <form
                            className="login__container"
                            action="/api/auth/login"
                            method="POST"
                        >
                            <div className="form-group">
                                <div className="d-flex">
                                    <i className="fas fa-user align-self-center mr-3"></i>
                                    <input
                                        className="input__linea"
                                        ref={usernameInput}
                                        placeholder="Usuario"
                                        name="username"
                                        type="text"
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="d-flex">
                                    <i className="fas fa-lock align-self-center mr-3"></i>
                                    <input
                                        className="input__linea"
                                        ref={passwordInput}
                                        placeholder="Contraseña"
                                        name="password"
                                        type="password"
                                    />
                                </div>
                                <so className="ml-5"></so>
                            </div>
                            <input
                                onClick={handleLoginClick}
                                className="mt-3 boton__verdeOscuro--letrasLogin"
                                type="submit"
                                value="Iniciar Sesión"
                            />
                        </form>
                    </div>
                </div>
            </div>

            <div id="login__footer">
                <div>
          <span className="align-self-center mb-2">
            Contáctanos (076) 283681 / 933 386 658</span>
                </div>

                <div className="d-flex align-items-center mt-2">
                    <span className="align-self-center mr-1">Powered by</span>
                    <a
                        href="https://www.contatrib.com/"
                        className="align-self-center"
                        title="www.contatrib.com"
                    >
                        <img className="align-self-center" src="/logoEM.png" height="30" width="117"></img>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Login;
