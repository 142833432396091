import React from "react";
import moment from "moment";
import {CREDITO, decimalAdjust} from "../../Global";
import {isNumber, showErrorMsg} from "../../helpers/validators";
import TablaPagosComponent from "./TablaPagosComponent";

export default class FormPagoComprobante extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            payload: {},
            tiposPago: ['efectivo', 'tarjeta'],
            pagos: [...props.deuda.pagos],
            restante: props.deuda.saldo,
        }
        this.onChangeFields = this.onChangeFields.bind(this);
        this.onProcesarHanler = this.onProcesarHanler.bind(this);
        this.onAddPagosHandler = this.onAddPagosHandler.bind(this);
        this.onEliminarPagoHandler = this.onEliminarPagoHandler.bind(this);
        this.procesarPago = this.procesarPago.bind(this);
    }

    onChangeFields(e, pagoItem) {
        let pagos = [...this.state.pagos];
        let pago = pagos.find(p => p.IdPago === pagoItem.IdPago);
        pago[e.target.getAttribute('name')] = e.target.value;
        pago.capital = pago.Monto - pago.TotalPagado
        this.setState({pagos: pagos})
    }

    async onProcesarHanler(e, p) {
        if (isNumber(p.TotalPagado) && p.TotalPagado > 0) {
            await this.procesarPago(p);
        } else
            showErrorMsg("El total pagado tiene que ser un número mayor que 0.")
    }

    async procesarPago(p) {
        let pagos = [...this.state.pagos];
        const i = pagos.indexOf(p);
        pagos[i].Estado = await this.updatePago(p);
        this.setState({pagos: pagos, restante: this.state.restante - pagos[i].TotalPagado});
    }

    async updatePago(p) {
        this.setState({isLoading: true})
        let res = await fetch(`/api/creditos/procesar-pago?idPago=${p.IdPago}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                totalPagado: p.TotalPagado,
                metodoPago: p.TipoPago,
                numMovimiento: p.NumeroMovimiento
            })
        })
        let json = await res.json();
        this.setState({isLoading: false})
        return json.estado;
    }


    async onAddPagosHandler() {
        let pago = await this.addPago();
        let pagos = [...this.state.pagos];
        pagos.push(pago);
        this.setState({pagos: pagos});
    }

    async addPago() {
        this.setState({isLoading: true})
        let res = await fetch(`/api/creditos/add-pago`, {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                idVenta: this.props.deuda.IdRegistroVenta,
                idTipoPago: CREDITO,
                monto: this.state.restante
            })
        })
        let json = await res.json()
        this.setState({isLoading: false})
        return {...json.pago, capital: json.pago.Monto - json.pago.TotalPagado};
    }

    async onEliminarPagoHandler(e, p) {
        let pagos = [...this.state.pagos]
        await this.deletePago(p.IdPago)
        pagos.splice(pagos.indexOf(p), 1)
        this.setState({pagos: pagos})
    }

    async deletePago(idPago) {
        this.setState({isLoading: true})
        await fetch(`/api/creditos/delete-pago?idPago=${idPago}`, {
            method: "DELETE",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        this.setState({isLoading: false})
    }

    render() {
        const {tiposPago, pagos, restante} = this.state;

        if (this.state.isLoading)
            return (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                    </div>
                </div>
            )
        return (
            <div>
                <div className="row mt-1">
                    <div className="col-sm-auto">
                        Total restante: {decimalAdjust('floor', restante, -2)}
                    </div>
                    <div className="col-sm ml-2">
                        {Number(decimalAdjust('floor', restante, -2)) > 0 ? (
                            <button
                                onClick={this.onAddPagosHandler}
                                className="btn btn-outline-primary">Agregar
                            </button>
                        ) : null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <TablaPagosComponent pagos={pagos} onProcesar={this.onProcesarHanler}
                                             readOnly={false}
                                             onChangeFields={this.onChangeFields}
                                             isLoading={this.state.isLoading}
                                             tiposPago={tiposPago}
                                             onEliminar={this.onEliminarPagoHandler}/>
                    </div>
                </div>

            </div>
        );
    }
}
