import React, {Component} from "react";
import AsyncSelect from 'react-select/async';
import moment from "moment";
import Noty from "noty";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import Modal from "../componentes/Modal";
import FormPagoComprobante from "../componentes/Creditos/FormPagoComprobante";
import {decimalAdjust} from "../Global";
import DetalleDeudaComponent from "../componentes/Creditos/DetalleDeudaComponent";

export default class Creditos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            fechaFin: moment(new Date()).format('YYYY-MM-DD'),
            cliente: {},
            isLoading: false,
            deudas: [],
            isOpenPagoModal: false,
            selectedDeuda: null,
            isOpenDetalleModal: false,
        }
        this.searchClients = this.searchClients.bind(this);
        this.onChangeCliente = this.onChangeCliente.bind(this);
        this.onChangeFecha = this.onChangeFecha.bind(this);
        this.buscarDeudas = this.buscarDeudas.bind(this);
        this.onRealizarPagoHandler = this.onRealizarPagoHandler.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    searchClients(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/clientes/buscar/?query=${query}`)
                .then(r => r.json())
                .then(clientes => {
                    const mappedClientes = clientes.map(c => {
                        return {
                            label: `${c.PrimerNombre} ${c.SegundoNombre || ""} - ${c.RazonSocial || ""} ${c.NroTipoDocumento || ""}`,
                            value: c.IdCliente
                        }
                    });
                    resolve(mappedClientes)
                }).catch(reject)
        })
    }

    onChangeCliente(test) {
        this.setState({
            cliente: test
        })
    }

    onChangeFecha(e) {
        const elem = e.target;
        this.setState((state, props) => {
            let newState = {...state};
            newState[elem.getAttribute('name')] = elem.value
            return newState;
        })
    }

    async buscarDeudas() {
        const isValid = this.state.fechaInicio.length && this.state.fechaInicio.length;
        if (isValid)
            await this.fetchDeudas();
        else
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `Los datos ingresados son inválidos`,
                timeout: 1500
            }).show();
    }

    async fetchDeudas() {
        this.setState({isLoading: true})
        let queryClient = this.state.cliente && this.state.cliente.value ? `&idCliente${this.state.cliente.value}` : '';
        const query = `fechaInicio=${this.state.fechaInicio}&fechaFin=${this.state.fechaFin}${queryClient}`;
        let resDeudas = await fetch(`/api/creditos/boletas-facturas?${query}`);
        let deudas = await resDeudas.json();
        this.setState({deudas: deudas, isLoading: false})
    }

    onRealizarPagoHandler(deuda) {
        this.setState({isOpenPagoModal: true, selectedDeuda: deuda})
    }

    onCloseModal() {
        this.setState({isOpenPagoModal: false, isOpenDetalleModal: false})
        this.fetchDeudas();
    }

    onDetallePagosHandler(deuda) {
        this.setState({isOpenDetalleModal: true, selectedDeuda: deuda})
    }

    render() {
        return (
            <div style={{"marginLeft": "50px", "marginRight": "50px"}}>
                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Fecha
                    </div>
                    <div className="col-sm">
                        <input type="date" onChange={this.onChangeFecha} name='fechaInicio' className="form-control"
                               value={this.state.fechaInicio}/>
                    </div>
                    <div className="col-sm-auto">
                        entre
                    </div>
                    <div className="col-sm">
                        <input type="date" onChange={this.onChangeFecha} name='fechaFin' className="form-control"
                               value={this.state.fechaFin}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-auto">
                        Cliente
                    </div>
                    <div className="col-sm">
                        <AsyncSelect
                            isClearable={true}
                            defaultOptions={true}
                            onChange={this.onChangeCliente}
                            value={this.state.cliente}
                            loadOptions={this.searchClients}
                        />
                    </div>
                    <div className="col-sm">
                        <button disabled={this.state.isLoading} onClick={this.buscarDeudas}
                                className="btn btn-outline-primary">
                            Buscar
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-sm-12">
                        <ReactTable
                            data={this.state.deudas}
                            filterable
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Código",
                                            id: "IdRegistroVenta",
                                            width: "80",
                                            accessor: d => d.IdRegistroVenta,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["IdRegistroVenta"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Razón social",
                                            id: "datos",
                                            width: "180",
                                            accessor: d => d.datos,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["datos"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Serie-Correlativo",
                                            id: "numSerie",
                                            width: "150",
                                            accessor: d => d.numSerie,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["numSerie"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Importe total",
                                            id: "Total",
                                            width: "150",
                                            filterable: true,
                                            Cell: (d) => decimalAdjust('floor', d.original.Total, -2)

                                        },
                                        {
                                            Header: "Cant. Cuotas",
                                            id: "cantPagos",
                                            width: "100",
                                            accessor: d => d.cantPagos,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["cantPagos"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Saldo",
                                            id: "saldo",
                                            width: "100",
                                            filterable: true,
                                            Cell: d => decimalAdjust('floor', d.original.saldo, -2)
                                        },
                                        {
                                            Header: "Estado",
                                            id: "estado",
                                            width: "100",
                                            accessor: d => d.estado,
                                            filterMethod: (filter, rows) => matchSorter(rows, filter.value, {keys: ["estado"]}),
                                            filterable: true
                                        },
                                        {
                                            Header: "Acciones",
                                            width: "300",
                                            filterable: false,
                                            Cell: (d) => {
                                                if (d.original.estado === "Anulado" || d.original.estado === "Pagado")
                                                    return (
                                                        <div>
                                                            <button className="btn btn-outline-info"
                                                                    onClick={() => this.onDetallePagosHandler(d.original)}
                                                                    disabled={this.state.isLoading}>
                                                                Pagos y NC
                                                            </button>
                                                        </div>
                                                    )
                                                return (
                                                    <div>
                                                        <button onClick={() => this.onRealizarPagoHandler(d.original)}
                                                                className="btn btn-outline-secondary"
                                                                disabled={this.state.isLoading}>
                                                            Realizar pago
                                                        </button>
                                                        <button className="btn btn-outline-info"
                                                                onClick={() => this.onDetallePagosHandler(d.original)}
                                                                disabled={this.state.isLoading}>
                                                            Pagos y NC
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>

                <Modal title="Realizar pagos" isOpen={this.state.isOpenPagoModal} onClose={this.onCloseModal}>
                    <FormPagoComprobante deuda={this.state.selectedDeuda}/>
                </Modal>

                <Modal title="Detalles de pagos y créditos" isOpen={this.state.isOpenDetalleModal}
                       onClose={this.onCloseModal}>
                    <DetalleDeudaComponent deuda={this.state.selectedDeuda}/>
                </Modal>

            </div>
        );
    }
}
