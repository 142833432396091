import React from 'react';
import PageLoading from './PageLoading';
import ClienteForm from "./ClientesForm";
import swal from "sweetalert";
import {getApiDniStrategies} from "../../Global";

class ClientesNuevo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            msjerror: "",
            form: {
                IdCliente: "1",
                IdTipoCliente: "1",
                IdTipoPersona: "1",
                IdTipoDocumento: "1",
                NroTipoDocumento: "",
                RazonSocial: "",
                NombreComercial: "",
                ApellidoPaterno: "",
                ApellidoMaterno: "",
                PrimerNombre: "",
                SegundoNombre: "",
                Direccion: "",
                Telefono: "",
                Celular: "",
                CorreoElectronico: "",
                PersonaContacto: "",
                BuenContribuyente: false,
                AgentePercepcion: false,
                AgenteRetencion: false,
                Prico: false,
                DAOT: false,
                CIIU: null,
                IdTipoMoneda: null,
                LineaCredito: null,
                FormaPago: null,
                EstadoSunat: null,
                Situacion: null,
                Ubigeo: "",
                Region: "",
                Provincia: "",
                Distrito: "",
                IdEmpresa: "1",
                Estado: "Activo",
            },
            data: {
                results: []
            },
            dataMoneda: {
                results: []
            },
            respuestaInsert: undefined,
            datareg: [],
            dataprov: [],
            datadist: [],
            apiDniUrl: process.env.REACT_APP_API_V1_CONSULTA_DNI,
        };
    }

    componentDidMount() {
        // this.listaUbigeos();
        // this.listaMonedas();
    }

    listaUbigeos = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/clientes/ubigeos/`);
            const data = await response.json();
            this.setState({
                loading: false,
                data: data,
            });

            const dataR = await this.state.data.results.filter((item) => {
                return item.CodProvincia === "00"
            });
            this.setState({
                datareg: dataR,
            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    listaMonedas = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/clientes/monedas/`);
            const dataMoneda = await response.json();
            this.setState({
                loading: false,
                dataMoneda: dataMoneda,

            });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    };

    crearCliente = async () => {
        this.setState({loading: true, error: null});
        if (this.state.form.PrimerNombre.length > 0 || this.state.form.RazonSocial.length > 0) {
            try {
                const config = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        IdCliente: "1",
                        IdTipoCliente: this.state.form.IdTipoCliente,
                        IdTipoPersona: this.state.form.IdTipoPersona,
                        IdTipoDocumento: this.state.form.IdTipoDocumento,
                        NroTipoDocumento: this.state.form.NroTipoDocumento,
                        RazonSocial: this.state.form.RazonSocial,
                        NombreComercial: this.state.form.NombreComercial,
                        ApellidoPaterno: this.state.form.ApellidoPaterno,
                        ApellidoMaterno: this.state.form.ApellidoMaterno,
                        PrimerNombre: this.state.form.PrimerNombre,
                        SegundoNombre: this.state.form.SegundoNombre,
                        Ubigeo: this.state.form.Ubigeo,
                        Direccion: this.state.form.Direccion,
                        Telefono: this.state.form.Telefono,
                        Celular: this.state.form.Celular,
                        CorreoElectronico: this.state.form.CorreoElectronico,
                        PersonaContacto: this.state.form.PersonaContacto,
                        BuenContribuyente: this.state.form.BuenContribuyente ? 'Si' : 'No',
                        AgentePercepcion: this.state.form.AgentePercepcion ? 'Si' : 'No',
                        AgenteRetencion: this.state.form.AgenteRetencion ? 'Si' : 'No',
                        Prico: this.state.form.Prico ? 'Si' : 'No',
                        DAOT: this.state.form.DAOT ? 'Si' : 'No',
                        CIIU: this.state.form.CIIU,
                        IdTipoMoneda: this.state.form.IdTipoMoneda ? this.state.form.IdTipoMoneda : null,
                        LineaCredito: this.state.form.LineaCredito,
                        FormaPago: this.state.form.FormaPago ? this.state.form.FormaPago : null,
                        EstadoSunat: this.state.form.EstadoSunat ? this.state.form.EstadoSunat : null,
                        Situacion: this.state.form.Situacion ? this.state.form.Situacion : null,
                        IdEmpresa: 1,
                        Estado: this.state.form.Estado,
                    })
                };
                const response = await fetch(`/api/clientes`, config);
                const rpta = await response.json();
                this.setState({
                    loading: false,
                    respuestaInsert: rpta.rpta[0][0].estado,
                });

                if (this.state.respuestaInsert === 'Insertado') {
                    if (this.props.sw) {
                        this.props.listaClientes();
                    } else {
                        this.props.AsignarValorDoc({...this.state.form, IdCliente: rpta.client.IdCliente});
                    }
                    this.props.onClose();
                }

            } catch (error) {
                console.log("error ", error)
                this.setState({
                    loading: false,
                    error: error,
                });
            }
        }

    };

    handleChange = (e) => {
        if (e.target.name === "NroTipoDocumento" && /[a-zA-Z]/.test(e.target.value)) {

        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    [e.target.name]: e.target.value,

                },
            });
        }
    }

    handleChangeCheckBox = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.checked

            },
        });
    }

    handleChangeRegion = (e) => {
        const dataP = this.state.data.results.filter((item) => {
            return item.CodRegion === e.target.value && item.CodDistrito === "00" && item.CodProvincia !== "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Ubigeo: e.target.value,
                Region: e.target.value,
                Provincia: null,
            },
            datadist: [],
        });
        //console.log(this.state);
    };

    handleChangeProvincia = (e) => {
        const dataD = this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === e.target.value && item.CodDistrito !== "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Ubigeo: `${this.state.form.Region}${e.target.value}`,
                Provincia: e.target.value,
                Distrito: null,
            },

        });

    };

    handleChangeDistrito = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
                Ubigeo: `${this.state.form.Region}${this.state.form.Provincia}${e.target.value}`,

            },
        });

    };

    TraerClientePorRUC = async (ruc) => {
        try {
            const prefijo = `https://cors-anywhere.herokuapp.com/`
            const response = await fetch(`${prefijo}https://api.sunat.cloud/ruc/${ruc}`, {mode: 'cors'});
            const cliente = await response.json();
            switch (ruc.charAt(0)) {
                case "2":
                    this.setState({
                        form: {
                            ...this.state.form,
                            IdTipoPersona: "2",
                            IdTipoDocumento: "6",
                            NroTipoDocumento: cliente.ruc,
                            RazonSocial: cliente.razon_social,
                            NombreComercial: cliente.nombre_comercial,
                            EstadoSunat: cliente.contribuyente_estado == 'ACTIVO' ? 'Activo' : '',
                            Situacion: cliente.contribuyente_condicion == 'HABIDO' ? 'Habido' : '',
                            Direccion: cliente.domicilio_fiscal
                        },
                    });
                    break;

                case "1":
                    this.setState({
                        form: {
                            ...this.state.form,
                            IdTipoPersona: "1",
                            IdTipoDocumento: "6",
                            NroTipoDocumento: cliente.ruc,
                            RazonSocial: cliente.razon_social,
                            NombreComercial: cliente.nombre_comercial,
                            EstadoSunat: cliente.contribuyente_estado == 'ACTIVO' ? 'Activo' : '',
                            Situacion: cliente.contribuyente_condicion == 'HABIDO' ? 'Habido' : '',
                            Direccion: cliente.domicilio_fiscal
                        },
                    });
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.log(`Error --- >${error}`);
        }

    };

    TraerClientePorRUC_API = async (ruc) => {
        if (ruc.length === 11) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_CONSULTA_RUC}/${ruc}`, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API_RUC}`
                    }
                });

                const cliente = await response.json();

                if (cliente) {
                    switch (ruc.charAt(0)) {
                        case "2":
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    IdTipoPersona: "2",
                                    IdTipoDocumento: "6",
                                    NroTipoDocumento: cliente.ruc,
                                    RazonSocial: cliente.razon_social,
                                    NombreComercial: cliente.razon_social,
                                    EstadoSunat: cliente.estado === 'ACTIVO' ? 'Activo' : '',
                                    Situacion: cliente.condicion_domicilio === 'HABIDO' ? 'Habido' : '',
                                    Direccion: cliente.direccion,
                                    Ubigeo: cliente.ubigeo
                                },
                            });
                            break;

                        case "1":
                            this.setState({
                                form: {
                                    ...this.state.form,
                                    IdTipoPersona: "1",
                                    IdTipoDocumento: "6",
                                    NroTipoDocumento: cliente.ruc,
                                    RazonSocial: cliente.razon_social,
                                    NombreComercial: cliente.razon_social,
                                    EstadoSunat: cliente.estado === 'ACTIVO' ? 'Activo' : '',
                                    Situacion: cliente.condicion_domicilio === 'HABIDO' ? 'Habido' : '',
                                    Direccion: cliente.direccion,
                                    Ubigeo: cliente.ubigeo
                                },
                            });
                            break;

                        default:
                            break;
                    }

                    await this.CargarProvincias(cliente.ubigeo.toString().substr(0, 2));
                    await this.CargarDistritos(cliente.ubigeo.toString().substr(2, 2));
                    this.setState({
                        form: {
                            ...this.state.form,
                            Region: this.state.form.Ubigeo.toString().substr(0, 2),
                            Provincia: this.state.form.Ubigeo.toString().substr(2, 2),
                            Distrito: this.state.form.Ubigeo.toString().substr(4, 2),
                        },
                    });
                } else {
                    this.setState({
                        msjerror: "Contribuyente No Existe en BD SUNAT"
                    })
                }

            } catch (error) {
                console.log(`Error --- >${error}`);
            }
        } else {
            await this.TraerClientePorDNI(ruc)
        }
    };

    TraerClientePorDNI = async (dni) => {
        if (dni.length === 8) {
            try {
                let apiDniUrl = this.state.apiDniUrl;
                let dniObj = this.getApiDniObjs().filter(o => o.url === apiDniUrl)[0];
                let data = await dniObj.getData(dni, apiDniUrl);

                this.setState({
                    form: {
                        ...this.state.form,
                        IdTipoPersona: "1",
                        IdTipoDocumento: "1",
                        ApellidoPaterno: data.apPaterno ? data.apPaterno : '---',
                        ApellidoMaterno: data.apMaterno ? data.apMaterno : '---',
                        PrimerNombre: data.nombres[0] ? data.nombres[0] : '',
                        SegundoNombre: data.nombres[1] ? data.nombres[1] : '',
                    },
                });

            } catch (error) {
                console.log(`Error --- >${error}`);
            }
        } else {
            await this.TraerClientePorRUC_API(dni)
        }
    };

    CargarProvincias = async (codreg) => {
        const dataP = await this.state.data.results.filter((item) => {
            return item.CodRegion === codreg && item.CodDistrito === "00" && item.CodProvincia != "00"
        });
        // console.log(dataP);
        this.setState({
            dataprov: dataP,
            form: {
                ...this.state.form,
                Region: codreg,
                Provincia: null
            },
            datadist: [],
        });
        //console.log(this.state);
    };

    CargarDistritos = async (codprov) => {
        const dataD = await this.state.data.results.filter((item) => {
            return item.CodRegion === this.state.form.Region && item.CodProvincia === codprov && item.CodDistrito != "00"
        });
        this.setState({
            datadist: dataD,
            form: {
                ...this.state.form,
                Provincia: codprov,
                Distrito: null
            },

        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        if (this.state.form.NroTipoDocumento === "" || !this.state.form.NroTipoDocumento) {
            swal({
                title: 'Atención',
                text: `El cliente debe tener nro de documento`,
                icon: 'info'
            })
        } else {
            this.crearCliente();
        }
    };

    handleSubmitVolver = async (e) => {
        e.preventDefault();
        if (this.props.sw) {
            this.props.history.push('/listaclientes');
        }
    };

    handleSubmitDNI = async (e) => {
        e.preventDefault();
        if (this.state.form.NroTipoDocumento === "" || !this.state.form.NroTipoDocumento) {
            swal({
                title: 'Atención',
                text: `Ingrese nro de documento`,
                icon: 'info'
            })
        } else {
            this.TraerClientePorDNI(this.state.form.NroTipoDocumento);
        }
    };

    handleSubmitRUC = async (e) => {
        e.preventDefault();
        if (this.state.form.NroTipoDocumento === "" || !this.state.form.NroTipoDocumento) {
            swal({
                title: 'Atención',
                text: `Ingrese nro de documento`,
                icon: 'info'
            })
        } else {
            this.setState({
                error: null,
                msjerror: ""
            });
            await this.TraerClientePorRUC_API(this.state.form.NroTipoDocumento);

        }
    };

    onChangeDniApi = (e) => {
        let apiDniObjs = this.getApiDniObjs();
        this.setState({apiDniUrl: e.target.checked ? apiDniObjs[0].url : apiDniObjs[1].url});
    }

    getApiDniObjs = () => {
        return getApiDniStrategies();
    }

    render() {
        if (this.state.error) {
            return `Error: ${this.state.error.message}`;
        }

        if (this.state.loading === true && this.state.data === undefined) {
            return <PageLoading/>;
        }


        return (
            <ClienteForm
                onClose={this.props.onClose}
                onChange={this.handleChange}
                onChangeCheckBox={this.handleChangeCheckBox}
                onChangeRegion={this.handleChangeRegion}
                onChangeProvincia={this.handleChangeProvincia}
                onChangeDistrito={this.handleChangeDistrito}
                onSubmit={this.handleSubmit}
                onSubmitVolver={this.handleSubmitVolver}
                onSubmitDNI={this.handleSubmitDNI}
                onSubmitRUC={this.handleSubmitRUC}
                formValues={this.state.form}
                error={this.state.error}
                datareg={this.state.datareg}
                dataprov={this.state.dataprov}
                datadist={this.state.datadist}
                dataMoneda={this.state.dataMoneda}
                respuestaInsert={this.state.respuestaInsert}
                accion="Registrar Cliente"
                msjerror={this.state.msjerror}
                onChangeDniApi={this.onChangeDniApi}
                apiDniObjs={this.getApiDniObjs()}
            />
        )
    }
}

export default ClientesNuevo;

