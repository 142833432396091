import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from "react-redux";
import PageLoading from "../componentes/PageLoading";

const AddSesionProp = ({component: Component, ...props}) => {

    const sesion = useSelector(state => state.sesion);

    if (sesion.loading === true)
        return <PageLoading/>

    return <Component sesion={sesion} {...props}/>

}

const RouterConSesion = ({component, ...props}) => <Route {...props} render={ routeProps => <AddSesionProp component={component} {...routeProps} />} />

export default RouterConSesion;