import React, { useState } from 'react';
import ReactTable from "react-table";
import matchSorter from 'match-sorter';
import "react-table/react-table.css";
import Form from "./Form";
import useRoles from '../../helpers/useRoles';
import { BotonLetras, BotonEditar, BotonDelete } from '../Plantillas/Botones';

const Roles = () => {

    const [Rol, setRol] = useState(null);
    const roles = useRoles(null, [Rol]);

    const columns = [
        {
            Header:     "Nombre",
            accessor:   "Nombre",
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["Nombre"] }),
            filterAll: true,
            style: { alignSelf: "center", paddingInline: "20px" }
        },
        {
            Header:     "Empresas",
            accessor:   "Empresas",
            filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: [
                    /**
                     * En este callback simplemente devuelvo un array con todas las razones sociales asociadas al Rol de turno,
                     * y match sorter se encarga de buscar en cada una de item de ese array de Razones Sociales.
                     * **row.Empresas** tiene el formato [{"IdEmpresa": 1, "RazonSocial": "Empresa1"}, {"IdEmpresa": 9, "RazonSocial": "Empresa2"}]
                     * Suponiendo el array de objetos anterior, el array devuelto deberia ser: ["Empresa1", "Empresa2"]
                     */
                    row => row.Empresas.map(
                        empresa => empresa.RazonSocial
                    )
                ] } ),
            Cell: f=>f.value.map(empresa=>(
                <li key={empresa.IdEmpresa}>{empresa.RazonSocial}</li>
            )),
            filterAll: true,
            style: { alignSelf: "center", paddingInline: "35px" }
        },

        // Columna donde están los botones para editar y eliminar usuario
        {
            Header: "Opciones",
            filterable: false,
            Cell: (f) => {
                return (
                    <div className="d-flex justify-content-center">
                        <BotonEditar className="mr-4" onClick={ ()=>setRol(f.original) } />
                        <BotonDelete onClick={()=>{}} />
                    </div>
                );
            },
            style: { alignSelf: "center"}
        },
    ]

    if (Rol) return <Form {...{setRol, Rol}} />

    return (
        <div className="container py-4">
            <div className="d-flex justify-content-end mb-3">
                <BotonLetras onClick={ ()=>setRol({Nombre:"", Empresas:[]}) }>
                    <i className="fa fa-plus"/> Nuevo Rol
                </BotonLetras>
            </div>

            { roles &&
                <ReactTable
                    data={roles}
                    columns={columns}
                    filterable
                />
            }
        </div>
    )
}


export default Roles;