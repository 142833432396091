import { useHistory } from 'react-router-dom';

function Landing({sesion}) {

  const history = useHistory();

  if (sesion.isLoggedIn) {
    history.push("/dashboard")
  } else {
    history.push("/login")
  }

  return null;
}

export default Landing;