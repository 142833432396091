import React from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from 'match-sorter';
import jsPDF from "jspdf";
import {Style, Section} from "../../pages/RegistroVentas/ClasesImpresion.js";
import Notify from '../Notify.js';
import {BotonPDFpequeno} from "../Plantillas/Botones";
import {loadLogoByRUC} from '../../helpers/funcionesGlobales.js';
import PageLoading from '../PageLoading.js';

class turnosDetalleList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataturnosSucursal: {
                turnosSucursal: []
            },
            dataPdf: {
                InfoPDF: []
            },
            data: {
                movCajas: []
            },
            MontoEntradas: 0,
            MontoSalidas: 0,
            loading: false,
            error: null,
            extImg: 'png'
        }
        this.obtenerTurnos = this.obtenerTurnos.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.TraerInfoPDF = this.TraerInfoPDF.bind(this);
    }

    componentDidMount() {
        this.obtenerTurnos();
    }

    obtenerTurnos = async () => {
        try {
            const response = await fetch(`/api/turnosList/`);
            const data = await response.json();
            this.setState({
                loading: true,
                dataturnosSucursal: data,
                extImg: data.extImg
            })
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    obtenerMovimientos = async (IdTurnoCaja) => {
        try {
            const response = await fetch(`/api/cajas/movimientosPorTurno/${IdTurnoCaja}`);// ${this.state.IdTurno}El idTurno debe ser dinámico, se debe capturar el Idturno ni bien se crea una apertura - Puede ser enviado por props
            const data = await response.json();
            this.setState({
                data: data
            })
            console.log(data)
            await this.getMontosEntradasSalidas();

        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    NumeroALetras(num, centavos) {
        var data = {
            numero: num,
            enteros: Math.floor(num),
            centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
            letrasCentavos: "",
        };
        //Revisar en caso no salgan los soles  centimos correctamente: https://www.lawebdelprogramador.com/codigo/JavaScript/5192-Conversor-de-numeros-a-letras.html
        if (centavos == undefined || centavos == false) {
            data.letrasMonedaPlural = "DOLARES";
            data.letrasMonedaSingular = "DOLAR";
        } else {
            data.letrasMonedaPlural = "CENTIMOS";
            data.letrasMonedaSingular = "CENTIMO";
        }

        if (data.centavos > 0)
            data.letrasCentavos = "CON " + this.NumeroALetras(data.centavos, true);

        if (data.enteros == 0)
            return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
        if (data.enteros == 1)
            return this.Millones(data.enteros) + " " + data.letrasMonedaSingular + " " + data.letrasCentavos;
        else
            return this.Millones(data.enteros) + " " + data.letrasMonedaPlural + " " + data.letrasCentavos;
    }//NumeroALetras()

    Unidades(num) {

        switch (num) {
            case 1:
                return "UN";
            case 2:
                return "DOS";
            case 3:
                return "TRES";
            case 4:
                return "CUATRO";
            case 5:
                return "CINCO";
            case 6:
                return "SEIS";
            case 7:
                return "SIETE";
            case 8:
                return "OCHO";
            case 9:
                return "NUEVE";
        }

        return "";
    }

    Decenas(num) {

        var decena = Math.floor(num / 10);
        var unidad = num - (decena * 10);

        switch (decena) {
            case 1:
                switch (unidad) {
                    case 0:
                        return "DIEZ";
                    case 1:
                        return "ONCE";
                    case 2:
                        return "DOCE";
                    case 3:
                        return "TRECE";
                    case 4:
                        return "CATORCE";
                    case 5:
                        return "QUINCE";
                    default:
                        return "DIECI" + this.Unidades(unidad);
                }
            case 2:
                switch (unidad) {
                    case 0:
                        return "VEINTE";
                    default:
                        return "VEINTI" + this.Unidades(unidad);
                }
            case 3:
                return this.DecenasY("TREINTA", unidad);
            case 4:
                return this.DecenasY("CUARENTA", unidad);
            case 5:
                return this.DecenasY("CINCUENTA", unidad);
            case 6:
                return this.DecenasY("SESENTA", unidad);
            case 7:
                return this.DecenasY("SETENTA", unidad);
            case 8:
                return this.DecenasY("OCHENTA", unidad);
            case 9:
                return this.DecenasY("NOVENTA", unidad);
            case 0:
                return this.Unidades(unidad);
        }
    }//Unidades()

    DecenasY(strSin, numUnidades) {
        if (numUnidades > 0)
            return strSin + " Y " + this.Unidades(numUnidades)

        return strSin;
    }//DecenasY()

    Centenas(num) {

        var centenas = Math.floor(num / 100);
        var decenas = num - (centenas * 100);

        switch (centenas) {
            case 1:
                if (decenas > 0)
                    return "CIENTO " + this.Decenas(decenas);
                return "CIEN";
            case 2:
                return "DOSCIENTOS " + this.Decenas(decenas);
            case 3:
                return "TRESCIENTOS " + this.Decenas(decenas);
            case 4:
                return "CUATROCIENTOS " + this.Decenas(decenas);
            case 5:
                return "QUINIENTOS " + this.Decenas(decenas);
            case 6:
                return "SEISCIENTOS " + this.Decenas(decenas);
            case 7:
                return "SETECIENTOS " + this.Decenas(decenas);
            case 8:
                return "OCHOCIENTOS " + this.Decenas(decenas);
            case 9:
                return "NOVECIENTOS " + this.Decenas(decenas);
        }

        return this.Decenas(decenas);
    }//Centenas()

    Seccion(num, divisor, strSingular, strPlural) {
        var cientos = Math.floor(num / divisor)
        var resto = num - (cientos * divisor)

        var letras = "";

        if (cientos > 0)
            if (cientos > 1)
                letras = this.Centenas(cientos) + " " + strPlural;
            else
                letras = strSingular;

        if (resto > 0)
            letras += "";

        return letras;
    }//Seccion()

    Miles(num) {
        var divisor = 1000;
        var cientos = Math.floor(num / divisor)
        var resto = num - (cientos * divisor)

        var strMiles = this.Seccion(num, divisor, "MIL", "MIL");
        var strCentenas = this.Centenas(resto);

        if (strMiles == "")
            return strCentenas;

        return strMiles + " " + strCentenas;

        //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
    }//Miles()

    Millones(num) {
        var divisor = 1000000;
        var cientos = Math.floor(num / divisor)
        var resto = num - (cientos * divisor)

        var strMillones = this.Seccion(num, divisor, "UN MILLON", "MILLONES");
        var strMiles = this.Miles(resto);

        if (strMillones == "")
            return strMiles;

        return strMillones + " " + strMiles;
    }

    TraerInfoPDF = async (IdTurnoCaja) => {
        try {
            const response = await fetch(`/api/cajas/cierreTurno/InfoPDFTL/${IdTurnoCaja}`);
            const data = await response.json();
            this.setState({
                dataPdf: data
            })
            await this.obtenerMovimientos(IdTurnoCaja)
        } catch (error) {
            this.setState({loading: false, error: error})
        }
    }

    getMontosEntradasSalidas = () => {
        let sumEntTemp = 0;
        let sumSalTemp = 0;
        this.state.data.movCajas.map((obj) => {
            if (obj.Tipo == "Entrada") {
                sumEntTemp = sumEntTemp + obj.Monto

            } else if (obj.Tipo == "Salida") {
                sumSalTemp = sumSalTemp + obj.Monto
            }
        })

        this.setState({
            MontoEntradas: sumEntTemp,
            MontoSalidas: sumSalTemp
        })
    }

    handleSavePDF = async (item) => {
        Notify("Documento creado. Espere por favor", "success", 1500)
        await this.TraerInfoPDF(item.IdTurnoCaja);
        /* Info Tamaño Ticket */
        const mmPageSize = [80, 3276];
        const ptPageSize = mmPageSize.map(coord => coord * 2.83465);

        var doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: ptPageSize
        });

        let nombreArchivo = `${item.FechaFin}` + "-" + `${this.state.dataPdf[0][0][0].NombreCaja}`; //Nombre del archivo (fecha + nombre caja)


        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

        const logoP = loadLogoByRUC(this.state.dataPdf[0][0][0].Ruc, 'logo', this.state.extImg);
        const logo = await logoP;
        doc.addImage(logo, "JPEG", 9, 5, 60, 18);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, 25, 76, 25);

        const ComprobanteStyle = new Style(10, "bold", 1.3, 'center');
        const ComprobanteSection = new Section(
            doc,
            3,
            26,
            ComprobanteStyle,
            74,
            null,
            1
        );

        // const ComprobanteSection endY new Section(
        //     doc,
        //     29,
        //     ComprobanteSection.endY + 4,
        //     ComprobanteStyle,
        //     ComprobanteSection.width
        // );

        ComprobanteSection.write(["CIERRE DE CAJA".toUpperCase(), `${this.state.dataPdf[0][0][0].NombreCaja}`.toUpperCase()]); //mostrar dos string en el mismo write
        ComprobanteSection.drawBorder(false, true);


        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, ComprobanteSection.endY + 2.5, 76, ComprobanteSection.endY + 2.5);

        /** Empresa Info**/

        const EmpresaTittleStyle = new Style(9, "normal", 1);
        const EmpresaTittleSection = new Section(
            doc,
            4,
            ComprobanteSection.endY + 3.2,
            EmpresaTittleStyle,
            ComprobanteSection.width
        );

        EmpresaTittleSection.write(`${this.state.dataPdf[0][0][0].Ruc}`);
        EmpresaTittleSection.write(`${this.state.dataPdf[0][0][0].RazonSocial}`.toUpperCase());

        const EmpresaDataStyle = new Style(7, "normal", 1);
        const EmpresaDataSection = new Section(
            doc,
            EmpresaTittleSection.x,
            EmpresaTittleSection.endY + 3.2,
            EmpresaDataStyle,
            EmpresaTittleSection.width
        );
        EmpresaDataSection.write(`${this.state.dataPdf[0][0][0].Direccion}`.toUpperCase());
        EmpresaDataSection.write(`${this.state.dataPdf[0][0][0].Nombre}`.toUpperCase());

        const usuarioStyle = new Style(8, "normal", 1);
        const usuarioSection = new Section(
            doc,
            EmpresaDataSection.x,
            EmpresaDataSection.endY + 4,
            usuarioStyle,
            EmpresaDataSection.width
        );

        usuarioSection.write(`FECHA DE EMISIÓN: ${item.FechaFin}`);
        usuarioSection.write(`USUARIO: ${this.state.dataPdf[0][0][0].Usuario}`);
        usuarioSection.write(`EMPLEADO: ${this.state.dataPdf[0][0][0].nomEmpleado}`);
        usuarioSection.write(` `);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, usuarioSection.endY, 76, usuarioSection.endY); supervisor

        const MontosStyle = new Style(10, "bold", 1.3, 'center');
        const MontosSection = new Section(
            doc,
            3,
            usuarioSection.endY + 2,
            MontosStyle,
            pageWidth - 6,
            null,
            1
        );

        MontosSection.write(`            TIPO                             MONTO`);
        MontosSection.drawBorder(false, true);
        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, MontosSection.endY, 76, MontosSection.endY);

        const TipoMontoNumberStyle = new Style(9, "normal", 1, 'right');
        const TipoMontoNumberSection = new Section(
            doc,
            4,
            MontosSection.endY + 4,
            TipoMontoNumberStyle,
            MontosSection.width
        );

        const TipoMontoTextoStyle = new Style(9, "normal", 1, 'left');
        const TipoMontoTextoSection = new Section(
            doc,
            4,
            MontosSection.endY + 4,
            TipoMontoTextoStyle,
            MontosSection.width
        );
        // console.log(item.MontoVentasEfectivoSistema)
        let MontoVentasEfectivoSistema = item.MontoFinalEfectivoSistema - item.MontoInicial
        let MontoVentasContadoTotalSistema = parseFloat(MontoVentasEfectivoSistema) + parseFloat(item.MontoFinalTarjetaMasterCardSistema) + parseFloat(item.MontoFinalTarjetaVisaSistema) + parseFloat(item.MontoFinalTarjetaAmericanSistema) + parseFloat(item.MontoFinalOtraTarjetaSistema)


        let TOTAL = parseFloat(MontoVentasContadoTotalSistema) + parseFloat(item.MontoFinalCreditoSistema)
        TipoMontoTextoSection.write(`${MontoVentasEfectivoSistema != 0 ? "Ventas Efectivo            " + this.state.dataPdf[0][0][0].Simbolo : ''}`); //Ventas Efectivo            ${this.state.dataPdf[0][0][0].Simbolo}
        TipoMontoNumberSection.write(`${MontoVentasEfectivoSistema != 0 ? (MontoVentasEfectivoSistema).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(`${item.MontoFinalTarjetaMasterCardSistema != 0 ? "T. MasterCard              " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${item.MontoFinalTarjetaMasterCardSistema != 0 ? (parseFloat(item.MontoFinalTarjetaMasterCardSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(`${item.MontoFinalTarjetaVisaSistema != 0 ? "T. Visa                          " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${item.MontoFinalTarjetaVisaSistema != 0 ? (parseFloat(item.MontoFinalTarjetaVisaSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(`${item.MontoFinalTarjetaAmericanSistema != 0 ? "T. American                  " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${item.MontoFinalTarjetaAmericanSistema != 0 ? (parseFloat(item.MontoFinalTarjetaAmericanSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(`${item.MontoFinalOtraTarjetaSistema != 0 ? "Otra Tarjeta                  " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${item.MontoFinalOtraTarjetaSistema != 0 ? (parseFloat(item.MontoFinalOtraTarjetaSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(` `);
        TipoMontoNumberSection.write(` `);

        TipoMontoTextoSection.write(`${MontoVentasContadoTotalSistema != 0 ? "Ventas Contado           " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${MontoVentasContadoTotalSistema != 0 ? (parseFloat(MontoVentasContadoTotalSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(`${item.MontoFinalCreditoSistema != 0 ? "Ventas Crédito             " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${item.MontoFinalCreditoSistema != 0 ? (parseFloat(item.MontoFinalCreditoSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        TipoMontoTextoSection.write(` `);
        TipoMontoNumberSection.write(` `);

        TipoMontoTextoSection.write(`${TOTAL != 0 ? "TOTAL VENTAS          " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        TipoMontoNumberSection.write(`${TOTAL != 0 ? (parseFloat(TOTAL)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        // TipoMontoNumberSection.write(`Son          ${this.state.dataPdf[0][0][0].Simbolo} ${this.numeroALetras(TOTAL)}`);

        const TotalStyle = new Style(8, "normal", 1);
        const TotalSection = new Section(
            doc,
            4,
            TipoMontoNumberSection.endY + 1,
            TotalStyle,
            TipoMontoNumberSection.width
        );

        //TotalSection.write(`T. CONTADO                           ${(this.state.MontoFinalTotal).toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, TotalSection.endY, 76, TotalSection.endY);

        TotalSection.write(`${TOTAL != 0 ? "SON " + this.NumeroALetras(parseFloat(TOTAL)) : 'No se realizaron Ventas'}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, TotalSection.endY, 76, TotalSection.endY);

        const EfectivoEnCajaStyle = new Style(10, "bold", 1, 'center');
        const EfectivoEnCajaSection = new Section(
            doc,
            3,
            TotalSection.endY + 4,
            EfectivoEnCajaStyle,
            pageWidth - 6,
            null,
            1
        );

        EfectivoEnCajaSection.write("MONTO EN CAJA");
        EfectivoEnCajaSection.drawBorder(false, true);
        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, EfectivoEnCajaSection.endY, 76, EfectivoEnCajaSection.endY);

        const DatosTextoStyle = new Style(9, "normal", 1, "left");
        const DatosTextoSection = new Section(
            doc,
            4,
            EfectivoEnCajaSection.endY + 4,
            DatosTextoStyle,
            EfectivoEnCajaSection.width
        );

        const DatosNumberStyle = new Style(9, "normal", 1, "right");
        const DatosNumberSection = new Section(
            doc,
            4,
            EfectivoEnCajaSection.endY + 4,
            DatosNumberStyle,
            EfectivoEnCajaSection.width
        );

        DatosTextoSection.write(`${item.MontoInicial != 0 ? "Monto Inicial                 " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DatosNumberSection.write(`${item.MontoInicial != 0 ? (parseFloat(item.MontoInicial)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DatosTextoSection.write(`${this.state.MontoEntradas != 0 ? "Entradas                       " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DatosNumberSection.write(`${this.state.MontoEntradas != 0 ? (parseFloat(this.state.MontoEntradas)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DatosTextoSection.write(`${this.state.MontoSalidas != 0 ? "Salidas                          " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DatosNumberSection.write(`${this.state.MontoSalidas != 0 ? (parseFloat(this.state.MontoSalidas)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);


        //DatosTextoSection.write(`Efectivo                              ${this.state.dataPdf[0][0][0].Simbolo}${(this.state.MontoFinalEfectivoSistema).toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}`);
        //DatosNumberSection.write(`Efectivo                              ${this.state.dataPdf[0][0][0].Simbolo}${(this.state.MontoFinalEfectivoSistema).toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, DatosSection.endY, 76, DatosSection.endY);
        DatosTextoSection.write(` `);
        DatosNumberSection.write(` `);

        DatosTextoSection.write(`${item.MontoFinalEfectivoSistema != 0 ? "EFECTIVO EN CAJA   " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DatosNumberSection.write(`${item.MontoFinalEfectivoSistema != 0 ? (parseFloat(item.MontoFinalEfectivoSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, DatosNumberSection.endY, 76, DatosNumberSection.endY);

        const MFisicoTitleStyle = new Style(10, "bold", 1, 'center');
        const MFisicoTitleSection = new Section(
            doc,
            3,
            DatosNumberSection.endY + 4,
            MFisicoTitleStyle,
            pageWidth - 6,
            null,
            1
        );

        MFisicoTitleSection.write("   FISICO CAJA");
        MFisicoTitleSection.drawBorder(false, true);
        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, MFisicoTitleSection.endY, 76, MFisicoTitleSection.endY);

        const MFisicoTextoStyle = new Style(9, "normal", 1, "left");
        const MFisicoTextoSection = new Section(
            doc,
            4,
            MFisicoTitleSection.endY + 4,
            MFisicoTextoStyle,
            MFisicoTitleSection.width
        );

        const MFisicoNumberStyle = new Style(9, "normal", 1, "right");
        const MFisicoNumberSection = new Section(
            doc,
            4,
            MFisicoTitleSection.endY + 4,
            MFisicoNumberStyle,
            MFisicoTitleSection.width
        );

        let MontoTotalFisico = parseFloat(item.MontoFinalEfectivoFisico) +
            parseFloat(item.MontoFinalTarjetaMasterCardFisico) +
            parseFloat(item.MontoFinalTarjetaVisaFisico) +
            parseFloat(item.MontoFinalTarjetaAmericanFisico) +
            parseFloat(item.MontoFinalOtraTarjetaFisico)
        let MontoTotalSistema = parseFloat(item.MontoFinalEfectivoSistema) +
            parseFloat(item.MontoFinalTarjetaMasterCardSistema) +
            parseFloat(item.MontoFinalTarjetaVisaSistema) +
            parseFloat(item.MontoFinalTarjetaAmericanSistema) +
            parseFloat(item.MontoFinalOtraTarjetaSistema)

        MFisicoTextoSection.write(`${item.MontoFinalEfectivoFisico != 0 ? "Arqueo(Efec.EnCaja)  " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        MFisicoNumberSection.write(`${item.MontoFinalEfectivoFisico != 0 ? (parseFloat(item.MontoFinalEfectivoFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        MFisicoTextoSection.write(`${item.MontoFinalTarjetaMasterCardFisico != 0 ? "T. Mastercard              " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        MFisicoNumberSection.write(`${item.MontoFinalTarjetaMasterCardFisico != 0 ? (parseFloat(item.MontoFinalTarjetaMasterCardFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        MFisicoTextoSection.write(`${item.MontoFinalTarjetaVisaFisico != 0 ? "T. Visa                         " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        MFisicoNumberSection.write(`${item.MontoFinalTarjetaVisaFisico != 0 ? (parseFloat(item.MontoFinalTarjetaVisaFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        MFisicoTextoSection.write(`${item.MontoFinalTarjetaAmericanFisico != 0 ? "T. American                 " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        MFisicoNumberSection.write(`${item.MontoFinalTarjetaAmericanFisico != 0 ? (parseFloat(item.MontoFinalTarjetaAmericanFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        MFisicoTextoSection.write(`${item.MontoFinalOtraTarjetaFisico != 0 ? "Otra Tarjeta                 " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        MFisicoNumberSection.write(`${item.MontoFinalOtraTarjetaFisico != 0 ? (parseFloat(item.MontoFinalOtraTarjetaFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        // MFisicoTextoSection.write(` `);
        // MFisicoNumberSection.write(` `);

        // MFisicoTextoSection.write(`${MontoTotalFisico != 0 ? "MONTO TOTAL          "+this.state.dataPdf[0][0][0].Simbolo:''}`);
        // MFisicoNumberSection.write(`${MontoTotalFisico != 0 ? (parseFloat(MontoTotalFisico)).toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2}):''}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, MFisicoNumberSection.endY, 76, MFisicoNumberSection.endY);

        const DiferenciaTitleStyle = new Style(10, "bold", 1, 'center');
        const DiferenciaTitleSection = new Section(
            doc,
            3,
            MFisicoNumberSection.endY + 4,
            DiferenciaTitleStyle,
            pageWidth - 6,
            null,
            1
        );

        DiferenciaTitleSection.write("     DIFERENCIA ");
        DiferenciaTitleSection.write("SISTEMA - FISICO");
        DiferenciaTitleSection.drawBorder(false, true);
        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, DiferenciaTitleSection.endY, 76, DiferenciaTitleSection.endY);

        const DiferenciaTextoStyle = new Style(9, "normal", 1, "left");
        const DiferenciaTextoSection = new Section(
            doc,
            4,
            DiferenciaTitleSection.endY + 4,
            DiferenciaTextoStyle,
            DiferenciaTitleSection.width
        );
        const DiferenciaNumberStyle = new Style(9, "normal", 1, "right");
        const DiferenciaNumberSection = new Section(
            doc,
            4,
            DiferenciaTitleSection.endY + 4,
            DiferenciaNumberStyle,
            DiferenciaTitleSection.width
        );
        console.log(item)
        debugger
        DiferenciaTextoSection.write(`${MontoTotalSistema != 0 ? "M. TOTAL SISTEMA  " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${MontoTotalSistema != 0 ? (parseFloat(MontoTotalSistema)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);


        DiferenciaTextoSection.write(`${MontoTotalFisico != 0 ? "M. TOTAL FISICO     " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${MontoTotalFisico != 0 ? (parseFloat(MontoTotalFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);


        DiferenciaTextoSection.write(`${(item.MontoFinalEfectivoSistema - item.MontoFinalEfectivoFisico) != 0 ? "Efectivo                      " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${(item.MontoFinalEfectivoSistema - item.MontoFinalEfectivoFisico) != 0 ? (parseFloat(item.MontoFinalEfectivoSistema) - parseFloat(item.MontoFinalEfectivoFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DiferenciaTextoSection.write(`${(item.MontoFinalTarjetaMasterCardSistema - item.MontoFinalTarjetaMasterCardFisico) != 0 ? "T. Mastercard             " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${(item.MontoFinalTarjetaMasterCardSistema - item.MontoFinalTarjetaMasterCardFisico) != 0 ? (parseFloat(item.MontoFinalTarjetaMasterCardSistema) - parseFloat(item.MontoFinalTarjetaMasterCardFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DiferenciaTextoSection.write(`${(item.MontoFinalTarjetaVisaSistema - item.MontoFinalTarjetaVisaFisico) != 0 ? "T. Visa                        " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${(item.MontoFinalTarjetaVisaSistema - item.MontoFinalTarjetaVisaFisico) != 0 ? (parseFloat(item.MontoFinalTarjetaVisaSistema) - parseFloat(item.MontoFinalTarjetaVisaFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DiferenciaTextoSection.write(`${(item.MontoFinalTarjetaAmericanSistema - item.MontoFinalTarjetaAmericanFisico) != 0 ? "T. American                " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${(item.MontoFinalTarjetaAmericanSistema - item.MontoFinalTarjetaAmericanFisico) != 0 ? (parseFloat(item.MontoFinalTarjetaAmericanSistema) - parseFloat(item.MontoFinalTarjetaAmericanFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        DiferenciaTextoSection.write(`${(item.MontoFinalOtraTarjetaSistema - item.MontoFinalOtraTarjetaFisico) != 0 ? "Otra Tarjeta                " + this.state.dataPdf[0][0][0].Simbolo : ''}`);
        DiferenciaNumberSection.write(`${(item.MontoFinalOtraTarjetaSistema - item.MontoFinalOtraTarjetaFisico) != 0 ? (parseFloat(item.MontoFinalOtraTarjetaSistema) - parseFloat(item.MontoFinalOtraTarjetaFisico)).toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }) : ''}`);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, DiferenciaNumberSection.endY, 76, DiferenciaNumberSection.endY);

        const RedondeoTitleStyle = new Style(10, "bold", 1, 'center');
        const RedondeoTitleSection = new Section(
            doc,
            3,
            DiferenciaTextoSection.endY + 4,
            RedondeoTitleStyle,
            pageWidth - 6,
            null,
            1
        );

        RedondeoTitleSection.write("REDONDEOS DEL TURNO");
        RedondeoTitleSection.drawBorder(false, true);

        const RedondeoTextoStyle = new Style(9, "normal", 1, "left");
        const RedondeoTextoSection = new Section(
            doc,
            4,
            RedondeoTitleSection.endY + 4,
            RedondeoTextoStyle,
            RedondeoTitleSection.width
        );
        // console.log("Redondeo RV Anulados: ",this.state.dataPdf[0][4][0].redondeoRVAnulados)
        // debugger
        RedondeoTextoSection.write(`Redondeo Comprobante Aceptados: ${this.state.dataPdf[0][3][0].redondeoRVAceptados.toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`);
        RedondeoTextoSection.write(`Redondeo Comprobante Anulados:  ${this.state.dataPdf[0][4][0].redondeoRVAnulados.toLocaleString("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })}`);


        const CorrelativosTitleStyle = new Style(10, "bold", 1, 'center');
        const CorrelativosTitleSection = new Section(
            doc,
            3,
            RedondeoTextoSection.endY + 4,
            CorrelativosTitleStyle,
            pageWidth - 6,
            null,
            1
        );

        CorrelativosTitleSection.write("COMPROBANTES EMITIDOS");
        CorrelativosTitleSection.drawBorder(false, true);

        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, CorrelativosTitleSection.endY, 76, CorrelativosTitleSection.endY);

        const CorrelativosStyle = new Style(9, "normal", 1);
        const CorrelativosSection = new Section(
            doc,
            4,
            CorrelativosTitleSection.endY + 4,
            CorrelativosStyle,
            CorrelativosTitleSection.width
        );

        if (this.state.dataPdf[0][1].length != 0) {
            this.state.dataPdf[0][1].map((obj) => {
                CorrelativosSection.write(`${obj.Descripcion}`);
                CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo}`);
            })
        }
        if (this.state.dataPdf[0][2].length != 0) {
            this.state.dataPdf[0][2].map((obj) => {
                CorrelativosSection.write(`${obj.Descripcion}`);
                CorrelativosSection.write(`                     ${obj.Serie} ${obj.Primero} - ${obj.Ultimo}`);
            })
        }


        // const TotalEfectivo2Style = new Style(9, "normal", 1);
        // const TotalEfectivo2Section = new Section(
        //     doc,
        //     4,
        //     TotalEfectivoSection.endY + 6,
        //     TotalEfectivo2Style,
        //     TotalEfectivoSection.width
        // );
        // TotalEfectivo2Section.write(`Firma`);
        // doc.setDrawColor(0, 0, 0);
        // doc.line(4, TotalEfectivo2Section.endY + 4, 76, TotalEfectivo2Section.endY + 4)
        // doc.line(4, TotalEfectivo2Section.endY + 4.1, 76, TotalEfectivo2Section.endY + 4.1)


        //Guardar el pdf
        doc.autoPrint(); //Imprimir
        window.open(doc.output("bloburl"), "_blank");

        doc.save(nombreArchivo + ".pdf");
    }


    render() {
        if (this.state.loading === false) {
            return <PageLoading/>;
        }
        return (

            <React.Fragment>
                <div id="productosContainer">
                    <div className="container">
                        <div className="row mb-3">
                            <ReactTable
                                data={this.state.dataturnosSucursal.turnosSucursal}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Acciones",
                                                accessor: "Editar",
                                                width: 100,
                                                filterable: false,
                                                Cell: (f) => {
                                                    return (
                                                        <React.Fragment>
                                                            <div align="center">
                                                                <BotonPDFpequeno
                                                                    // to={"/almacenes/edit/" + f.original.IdAlmacen}
                                                                    title="Guardar PDF"
                                                                    onClick={() => this.handleSavePDF(f.original)}
                                                                    className="mr-2"
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    );
                                                },
                                            },
                                            {
                                                Header: "Usuario - Empleado",
                                                id: "Usuario",
                                                width: 300,
                                                accessor: d => d.Usuario,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Usuario"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Caja",
                                                id: "Nombre",
                                                width: 200,
                                                accessor: d => d.Nombre,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Nombre"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Inicio",
                                                id: "FechaInicio",
                                                width: 200,
                                                accessor: d => d.FechaInicio,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["FechaInicio"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cierre",
                                                id: "FechaFin",
                                                width: 200,
                                                accessor: d => d.FechaFin,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["FechaFin"]}),
                                                filterAll: true
                                            },
                                        ]
                                    },
                                ]}
                                defaultPageSize={5}
                                //pageSize={10}
                                className="-striped -highlight"
                                // Text de paginación
                                previousText='Anterior'
                                nextText='Siguiente'
                                loadingText='Cargando...'
                                noDataText='No se encontraron registros'
                                pageText='Página'
                                ofText='de'
                                rowsText='filas'
                                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default turnosDetalleList;
