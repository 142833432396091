import React from 'react'

function SucursalesLista(props) {
    return (
        <div>
            <select
                required
                className="form-control"
                aria-haspopup="true"
                name="sucursal"
                id={props.id}
                value={props.id || ""}
                onChange={props.SucursalChange}
                disabled={props.disabled || props.disabled === true ? false : true}
            >
                <option value={""}>Seleccionar Sucursal...</option>
                {props.dataSucursales.map(sucursal => {
                    return (
                        <option
                            key={sucursal.IdSucursal}
                            value={sucursal.IdSucursal}
                            title={sucursal.Nombre}
                        >
                            {sucursal.Nombre}
                        </option>
                    );
                })}
            </select>
        </div>

    )
}

export default SucursalesLista
