import React from "react";
import '../styles/tabla.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BotonAdd,BotonGuardarLetras, BotonCancelar } from "../Plantillas/Botones";

class EmpresasAddFormDatosGenerales extends React.Component {
    
    render(){
        if(this.props.state.AddOrEdit == "Add"){
            return (
                <React.Fragment>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                            <b className="ml-1">*Representante Legal </b>
                            <input name="RepresentanteLegal" 
                                className="input__linea col-3" 
                                placeholder="Representante Legal..."
                                defaultValue={this.props.state.RepresentanteLegal} 
                                onChange={this.props.handleChange} 
                                type="text" />
                            <b className="ml-4">*Sector </b>
                            <select  id="Sector" className="input__linea col-3" name="Sector" onChange={this.props.handleChange} value={this.props.state.Sector} aria-haspopup="true" aria-expanded="false" >
                                <option value="Comercial" defaultValue>Comercial</option>
                                <option value="Servicios" >Servicios</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Otros">Otros</option>
                            </select>
                            </div>
                            <div className="labelValidAlmacen">
                                {this.props.state.Errores.RepresentanteLegal.length > 0 && (<span>{this.props.state.Errores.RepresentanteLegal}</span>)}
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >*Teléfono de Encargado </b>
                                <input 
                                    className="input__linea col-6" 
                                    id="Telefono"
                                    name="Telefono" 
                                    placeholder="Teléfono..."
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.Telefono} 
                                    type="text"/>
                                <BotonAdd
                                    type="button"
                                    onClick={this.props.mostrarTelefonosOpcionales}
                                />
                            </div> 
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.Telefono.length > 0 && (<span>{this.props.state.Errores.Telefono}</span>)}
                            </div>
                        </div>
                        <div hidden={this.props.state.mostrarTelefonosOpcionales} className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >Teléfono Dos </b>
                                <input 
                                    name="TelefonoDos" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.TelefonoDos}
                                    type="text"
                                    placeholder="Teléfono 2 - Opcional..."/>
                            
                                <b className="ml-4" >Teléfono Tres</b>
                                <input 
                                    name="TelefonoTres" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.TelefonoTres}
                                    type="text"
                                    placeholder="Teléfono 3 - Opcional..."/>
                            </div>
                            <div className="row form-group col-12 ">
                                <div className="labelValidAlmacen col-3">
                                    {this.props.state.Errores.TelefonoDos.length > 0 && (<span>{this.props.state.Errores.TelefonoDos}</span>)}
                                </div>
                                <div className="labelValidAlmacen col-3">
                                    {this.props.state.Errores.TelefonoTres.length > 0 && (<span>{this.props.state.Errores.TelefonoTres}</span>)}
                                </div>
                            </div>
                        </div>
                        <div className="almacenDiv">
                                <b className="ml-4">Página Web </b>
                                <div className="input__Almacen">
                                    <input 
                                        className="input__linea" 
                                        name="PaginaWeb" 
                                        onChange={this.props.handleChange} 
                                        value={this.props.state.PaginaWeb}
                                        placeholder="Página Web..."
                                        type="text"/>
                                    </div>
                                    <div className="labelValidAlmacen">
                                        {this.props.state.Errores.PaginaWeb.length > 0 && (<span>{this.props.state.Errores.PaginaWeb}</span>)}
                                    </div>
                                </div>
                        
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Correo Electrónico </b>
                                <input name="CorreoElectronico" 
                                    className="input__linea col-6" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronico} 
                                    placeholder="Correo electrónico..."
                                    type="email" />
                                <BotonAdd
                                    type="button"
                                    onClick={this.props.mostrarCorreosOpcionales}
                                />
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronico.length > 0 && (<span>{this.props.state.Errores.CorreoElectronico}</span>)}
                            </div>
                        </div>
                        <div hidden={this.props.state.mostrarCorreosOpcionales} className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >Correo Elect. Dos </b>
                                <input name="CorreoElectronicoDos" 
                                    className="input__linea col-3 " 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronicoDos} 
                                    type="email" 
                                    placeholder="Segundo correo - opcional..."/>
                                <b className="ml-4" >Correo Elect. Tres </b>
                                <input name="CorreoElectronicoTres" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronicoTres} 
                                    type="email" 
                                    placeholder="Tercer correo - Opcional..." />
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronicoDos.length > 0 && (<span>{this.props.state.Errores.CorreoElectronicoDos}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronicoTres.length > 0 && (<span>{this.props.state.Errores.CorreoElectronicoTres}</span>)}
                            </div>
                        </div>
                                                
                        <div className="almacenDiv">       
                            <div className="input__Almacen">
                                <b className="ml-1">*Moneda </b> 
                                {/* <select  className="input__linea Moneda" aria-haspopup="true" name="Moneda" value={this.props.state.IdMoneda} onChange={this.props.MonedaChange} >{
                                    this.props.state.dataM.results.map((obj) => {
                                        return <option key={obj.IdMoneda} value={obj.IdMoneda}>{obj.Nombre}</option> 
                                    })}
                                </select> */}
                                {this.props.state.dataM.results.map((obj) => {
                                    return <React.Fragment>
                                        <input
                                            id={"Mon" + obj.IdMoneda}
                                            type="radio"
                                            className="Moneda ml-4"
                                            value={obj.IdMoneda}
                                            name="groupOfDefaultRadios"
                                            defaultChecked={
                                                this.props.MonedaChecked(obj.IdMoneda)
                                            }
                                            onClick={
                                                this.props.MonedaChange
                                            }/>
                                        <label htmlFor={"Mon" + obj.IdMoneda}>{obj.Nombre}</label>
                                        </React.Fragment>
                                    })}
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">Sistema de Contabilidad </b>
                                <input
                                    id="Manual"
                                    type="radio"
                                    className="ml-4"
                                    value="Manual"
                                    name="SistemaContabilidad"
                                    defaultChecked={
                                        this.props.ContabilidadChecked("MANUAL")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Manual">Manual</label>
                                <input
                                    id="Computarizado"
                                    type="radio"
                                    className="ml-4"
                                    value="Computarizado"
                                    name="SistemaContabilidad"
                                    defaultChecked={
                                        this.props.ContabilidadChecked("COMPUTARIZADO")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Computarizado">Computarizado</label>
                                <input
                                    id="Mixto"
                                    type="radio"
                                    className="ml-4"
                                    value="Mixto"
                                    name="groupOfDefaultRadios2"
                                    defaultChecked={
                                        this.props.ContabilidadChecked("MIXTO")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Mixto">Mixto</label>
                                {/* <input  name="SistemaContabilidad" className="empresa_input form-control col-3" onChange={this.props.handleChange} value={this.props.state.SistemaContabilidad} type="text" /> */}
                            </div>  
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">Sistema de Emisión </b>
                                <input
                                    id="ManualEm"
                                    type="radio"
                                    className="ml-4"
                                    value="Manual"
                                    name="groupOfDefaultRadios3"
                                    defaultChecked={
                                        this.props.EmisionChecked("MANUAL")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaEmision
                                    }/>
                                <label htmlFor="ManualEm">Manual</label>
                                <input
                                    id="Electronico"
                                    type="radio"
                                    className="ml-4"
                                    value="Electronico"
                                    name="groupOfDefaultRadios3"
                                    defaultChecked={
                                        this.props.EmisionChecked("ELECTRONICO")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaEmision
                                    }/>
                                <label htmlFor="Electronico">Electrónico</label>
                                {/* <input  name="SistemaEmision" className="empresa_input form-control col-3" onChange={this.props.handleChange} value={this.props.state.SistemaEmision} type="text" /> */}
                            </div> 
                        </div> 
                        <div className="almacenDiv">
                            <b className="ml-4">Tipo Plan Sunat</b>
                            <div className="input__Almacen">
                                <select  className="input__linea planSunat" aria-haspopup="true" name="planSunat" value={this.props.state.IdPlanSunat} onChange={this.props.PlanChange} >{
                                    this.props.state.dataP.planesSunat.map((obj) => {
                                        return <option key={obj.IdPlanSunat} value={obj.IdPlanSunat}>{obj.IdPlanSunat + " - "}{obj.Plan}</option> 
                                    })}</select>
                            </div>
                        </div>
                        {/* <div className="row form-group col-12">
                            <label className="col-3">Tipo Plan Sunat</label>
                            <select  className="btn btn-outline-dark dropdown-toggle col-9 planSunat" aria-haspopup="true" name="planSunat" value={this.props.state.IdPlanSunat} onChange={this.props.PlanChange} >{
                                this.props.state.dataP.planesSunat.map((obj) => {
                                    return <option key={obj.IdPlanSunat} value={obj.IdPlanSunat}>{obj.IdPlanSunat + " - "}{obj.Plan}</option> 
                                })}</select>
                        </div> */}

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="Retenedor"
                                            className="toggle-state"
                                            onClick={this.props.RetenedorChange}
                                            value={this.props.state.Retenedor}
                                            defaultChecked={this.props.state.checkRetenedor}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Agente Retenedor
                                    </label>

                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="Percepcion"
                                            className="toggle-state"
                                            onClick={this.props.PercepcionChange}
                                            value={this.props.state.Percepcion}
                                            defaultChecked={this.props.state.checkPercepcion}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Agente Percepción
                                    </label>

                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="EsRuss"
                                            className="toggle-state"
                                            onClick={this.props.EsRussChange}
                                            value={this.props.state.EsRuss}
                                            defaultChecked={this.props.state.checkEsRuss}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Russ (No se podrá generar facturas)
                                    </label>
                                </div>
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="Retenedor" value={this.props.state.Retenedor} defaultChecked={this.props.state.checkRetenedor} onClick={this.props.RetenedorChange}/> Agente Retenedor</label> */}
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="Percepcion" value={this.props.state.Percepcion} defaultChecked={this.props.state.checkPercepcion} onClick={this.props.PercepcionChange}/> Agente Percepcion</label> */}
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="EsRuss" value={this.props.state.EsRuss} defaultChecked={this.props.state.checkEsRuss} onClick={this.props.EsRussChange}/> Russ</label> */}
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Fecha de Inscripción </b>
                                <input name="FechaDeInscripcion" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    defaultValue={this.props.state.FechaDeInscripcion}
                                    max={this.props.state.Hoy}
                                    type="date"  />
                                {/* <DatePicker 
                                    name="FechaDeInscripcion" 
                                    selected={this.props.state.FechaActual} 
                                    onChange={this.props.handleChange}
                                    dateFormat="dd/MM/yyyy"
                                />
                                */}
                                <b className="ml-1">*Fecha Constitución </b>
                                <input name="FechaDeConstitucion" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.FechaDeConstitucion} 
                                    max={this.props.state.Hoy}
                                    type="date"/>
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.FechaDeInscripcion.length > 0 && (<span>{this.props.state.Errores.FechaDeInscripcion}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.FechaDeConstitucion.length > 0 && (<span>{this.props.state.Errores.FechaDeConstitucion}</span>)}
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Usuario Sol Principal</b>
                                <input  name="UsuarioSolPrincipal" className="input__linea col-3" value={this.props.state.UsuarioSolPrincipal} onChange={this.props.handleChange} type="text" />
                                <b className="ml-1">*Clave Sol Principal</b>
                                <input  name="ClaveSolPrincipal" className="input__linea col-3" value={this.props.state.ClaveSolPrincipal} onChange={this.props.handleChange} type="text" />
                            </div> 
                        </div> 
                </React.Fragment>
            );
        }else {
            return (
            <React.Fragment>
                <div className="almacenDiv">
                    <b className="col-3" >Ruc</b> 
                    <div className="input__Almacen">
                        <input 
                            name="Ruc" 
                            className="input__linea" 
                            defaultValue={this.props.state.Ruc} 
                            type="text" 
                            disabled/>
                    </div>
                </div>

                <div className="almacenDiv">
                    <b className="col-3" >Razón Social  </b>
                    <div className="input__Almacen">
                    <input 
                        name="RazonSocial" 
                        className="input__linea" 
                        onChange={this.props.handleChange} 
                        value={this.props.state.RazonSocial}
                        disabled
                        type="text"/>
                    </div>
                    <div className="labelValidAlmacen">
                        {this.props.state.Errores.RazonSocial.length > 0 && (
                        <span>{this.props.state.Errores.RazonSocial}</span>
                        )}
                    </div>
                </div>
                <div className="almacenDiv">
                            <div className="input__Almacen">
                            <b className="ml-1">*Representante Legal </b>
                            <input name="RepresentanteLegal" 
                                className="input__linea col-3" 
                                placeholder="Representante Legal..."
                                defaultValue={this.props.state.RepresentanteLegal} 
                                onChange={this.props.handleChange} 
                                type="text" />
                            <b className="ml-4">*Sector </b>
                            <select  id="Sector" className="input__linea col-3" name="Sector" onChange={this.props.handleChange} value={this.props.state.Sector} aria-haspopup="true" aria-expanded="false" >
                                <option value="Comercial" defaultValue>Comercial</option>
                                <option value="Servicios" >Servicios</option>
                                <option value="Industrial">Industrial</option>
                                <option value="Otros">Otros</option>
                            </select>
                            </div>
                            <div className="labelValidAlmacen">
                                {this.props.state.Errores.RepresentanteLegal.length > 0 && (<span>{this.props.state.Errores.RepresentanteLegal}</span>)}
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >*Teléfono de Encargado </b>
                                <input 
                                    className="input__linea col-6" 
                                    id="Telefono"
                                    name="Telefono" 
                                    placeholder="Teléfono..."
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.Telefono} 
                                    type="text"/>
                                <BotonAdd
                                    type="button"
                                    onClick={this.props.mostrarTelefonosOpcionales}
                                />
                            </div> 
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.Telefono.length > 0 && (<span>{this.props.state.Errores.Telefono}</span>)}
                            </div>
                        </div>
                        <div hidden={this.props.state.mostrarTelefonosOpcionales} className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >Teléfono Dos </b>
                                <input 
                                    name="TelefonoDos" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.TelefonoDos}
                                    type="text"
                                    placeholder="Teléfono 2 - Opcional..."/>
                            
                                <b className="ml-4" >Teléfono Tres</b>
                                <input 
                                    name="TelefonoTres" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.TelefonoTres}
                                    type="text"
                                    placeholder="Teléfono 3 - Opcional..."/>
                            </div>
                            <div className="row form-group col-12 ">
                                <div className="labelValidAlmacen col-3">
                                    {this.props.state.Errores.TelefonoDos.length > 0 && (<span>{this.props.state.Errores.TelefonoDos}</span>)}
                                </div>
                                <div className="labelValidAlmacen col-3">
                                    {this.props.state.Errores.TelefonoTres.length > 0 && (<span>{this.props.state.Errores.TelefonoTres}</span>)}
                                </div>
                            </div>
                        </div>
                        <div className="almacenDiv">
                                <b className="ml-4">Página Web </b>
                                <div className="input__Almacen">
                                    <input 
                                        className="input__linea" 
                                        name="PaginaWeb" 
                                        onChange={this.props.handleChange} 
                                        value={this.props.state.PaginaWeb}
                                        placeholder="Página Web..."
                                        type="text"/>
                                    </div>
                                    {/* <div className="labelValidAlmacen">
                                        {this.props.state.Errores.PaginaWeb.length > 0 && (<span>{this.props.state.Errores.PaginaWeb}</span>)}
                                    </div> */}
                                </div>
                        
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Correo Electrónico </b>
                                <input name="CorreoElectronico" 
                                    className="input__linea col-6" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronico} 
                                    placeholder="Correo electrónico..."
                                    type="email" />
                                <BotonAdd
                                    type="button"
                                    onClick={this.props.mostrarCorreosOpcionales}
                                />
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronico.length > 0 && (<span>{this.props.state.Errores.CorreoElectronico}</span>)}
                            </div>
                        </div>
                        <div hidden={this.props.state.mostrarCorreosOpcionales} className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1" >Correo Elect. Dos </b>
                                <input name="CorreoElectronicoDos" 
                                    className="input__linea col-3 " 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronicoDos} 
                                    type="email" 
                                    placeholder="Segundo correo - opcional..."/>
                                <b className="ml-4" >Correo Elect. Tres </b>
                                <input name="CorreoElectronicoTres" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.CorreoElectronicoTres} 
                                    type="email" 
                                    placeholder="Tercer correo - Opcional..." />
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronicoDos.length > 0 && (<span>{this.props.state.Errores.CorreoElectronicoDos}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.CorreoElectronicoTres.length > 0 && (<span>{this.props.state.Errores.CorreoElectronicoTres}</span>)}
                            </div>
                        </div>
                                                
                        <div className="almacenDiv">       
                            <div className="input__Almacen">
                                <b className="ml-1">*Moneda </b> 
                                {/* <select  className="input__linea Moneda" aria-haspopup="true" name="Moneda" value={this.props.state.IdMoneda} onChange={this.props.MonedaChange} >{
                                    this.props.state.dataM.results.map((obj) => {
                                        return <option key={obj.IdMoneda} value={obj.IdMoneda}>{obj.Nombre}</option> 
                                    })}
                                </select> */}
                                {this.props.state.dataM.results.map((obj) => {
                                    return <React.Fragment>
                                        <input
                                            id={"Mon" + obj.IdMoneda}
                                            type="radio"
                                            className="Moneda ml-4"
                                            value={obj.IdMoneda}
                                            name="groupOfDefaultRadios"
                                            checked={
                                                this.props.MonedaChecked(obj.IdMoneda)
                                            }
                                            onClick={
                                                this.props.MonedaChange
                                            }/>
                                        <label htmlFor={"Mon" + obj.IdMoneda}>{obj.Nombre}</label>
                                        </React.Fragment>
                                    })}
                            </div>
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">Sistema de Contabilidad </b>
                                <input
                                    id="Manual"
                                    type="radio"
                                    className="ml-4"
                                    value="Manual"
                                    name="groupOfDefaultRadios2"
                                    checked={
                                        this.props.ContabilidadChecked("Manual")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Manual">Manual</label>
                                <input
                                    id="Computarizado"
                                    type="radio"
                                    className="ml-4"
                                    value="Computarizado"
                                    name="groupOfDefaultRadios2"
                                    checked={
                                        this.props.ContabilidadChecked("Computarizado")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Computarizado">Computarizado</label>
                                <input
                                    id="Mixto"
                                    type="radio"
                                    className="ml-4"
                                    value="Mixto"
                                    name="groupOfDefaultRadios2"
                                    checked={
                                        this.props.ContabilidadChecked("Mixto")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaContabilidad
                                    }/>
                                <label htmlFor="Mixto">Mixto</label>
                                {/* <input  name="SistemaContabilidad" className="empresa_input form-control col-3" onChange={this.props.handleChange} value={this.props.state.SistemaContabilidad} type="text" /> */}
                            </div>  
                        </div>
                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">Sistema de Emisión </b>
                                <input
                                    id="ManualEm"
                                    type="radio"
                                    className="ml-4"
                                    value="Manual"
                                    name="SistemaEmision"
                                    defaultChecked={
                                        this.props.EmisionChecked("Manual")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaEmision
                                    }/>
                                <label htmlFor="ManualEm">Manual</label>
                                <input
                                    id="Electronico"
                                    type="radio"
                                    className="ml-4"
                                    value="Electronico"
                                    name="SistemaEmision"
                                    defaultChecked={
                                        this.props.EmisionChecked("Electronico")
                                    }
                                    onClick={
                                        this.props.handleChangeSistemaEmision
                                    }/>
                                <label htmlFor="Electronico">Electrónico</label>
                                {/* <input  name="SistemaEmision" className="empresa_input form-control col-3" onChange={this.props.handleChange} value={this.props.state.SistemaEmision} type="text" /> */}
                            </div> 
                        </div> 
                        <div className="almacenDiv">
                            <b className="ml-4">Tipo Plan Sunat</b>
                            <div className="input__Almacen">
                                <select  className="input__linea planSunat" aria-haspopup="true" name="planSunat" value={this.props.state.IdPlanSunat} onChange={this.props.PlanChange} >{
                                    this.props.state.dataP.planesSunat.map((obj) => {
                                        return <option key={obj.IdPlanSunat} value={obj.IdPlanSunat}>{obj.IdPlanSunat + " - "}{obj.Plan}</option> 
                                    })}</select>
                            </div>
                        </div>
                        {/* <div className="row form-group col-12">
                            <label className="col-3">Tipo Plan Sunat</label>
                            <select  className="btn btn-outline-dark dropdown-toggle col-9 planSunat" aria-haspopup="true" name="planSunat" value={this.props.state.IdPlanSunat} onChange={this.props.PlanChange} >{
                                this.props.state.dataP.planesSunat.map((obj) => {
                                    return <option key={obj.IdPlanSunat} value={obj.IdPlanSunat}>{obj.IdPlanSunat + " - "}{obj.Plan}</option> 
                                })}</select>
                        </div> */}

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="Retenedor"
                                            className="toggle-state"
                                            onClick={this.props.RetenedorChange}
                                            value={this.props.state.Retenedor}
                                            defaultChecked={this.props.state.checkRetenedor}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Agente Retenedor
                                    </label>

                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="Percepcion"
                                            className="toggle-state"
                                            onClick={this.props.PercepcionChange}
                                            value={this.props.state.Percepcion}
                                            defaultChecked={this.props.state.checkPercepcion}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Agente Percepción
                                    </label>

                                    <label align="left" className="checkbox-toggle">
                                        <div className="toggle">
                                            <input
                                            type="checkbox"
                                            name="EsRuss"
                                            className="toggle-state"
                                            onClick={this.props.EsRussChange}
                                            value={this.props.state.EsRuss}
                                            defaultChecked={this.props.state.checkEsRuss}
                                            />
                                            <div className="toggle-inner">
                                            <div className="indicator"/>
                                            </div>
                                            <div className="active-bg"/>
                                        </div>
                                        Russ (No se podrá generar facturas)
                                    </label>
                                </div>
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="Retenedor" value={this.props.state.Retenedor} defaultChecked={this.props.state.checkRetenedor} onClick={this.props.RetenedorChange}/> Agente Retenedor</label> */}
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="Percepcion" value={this.props.state.Percepcion} defaultChecked={this.props.state.checkPercepcion} onClick={this.props.PercepcionChange}/> Agente Percepcion</label> */}
                            {/* <label className="col-3 checkbox-inline"><input type="checkbox" name="EsRuss" value={this.props.state.EsRuss} defaultChecked={this.props.state.checkEsRuss} onClick={this.props.EsRussChange}/> Russ</label> */}
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Fecha de Inscripción </b>
                                <input name="FechaDeInscripcion" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    defaultValue={this.props.state.FechaDeInscripcion} 
                                    type="date"  />
                                {/* <DatePicker 
                                    name="FechaDeInscripcion" 
                                    selected={this.props.state.FechaActual} 
                                    onChange={this.props.handleChange}
                                    dateFormat="dd/MM/yyyy"
                                />
                                */}
                                <b className="ml-1">*Fecha Constitución </b>
                                <input name="FechaDeConstitucion" 
                                    className="input__linea col-3" 
                                    onChange={this.props.handleChange} 
                                    value={this.props.state.FechaDeConstitucion} 
                                    type="date"/>
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.FechaDeInscripcion.length > 0 && (<span>{this.props.state.Errores.FechaDeInscripcion}</span>)}
                            </div>
                            <div className="labelValidAlmacen col-3">
                                {this.props.state.Errores.FechaDeConstitucion.length > 0 && (<span>{this.props.state.Errores.FechaDeConstitucion}</span>)}
                            </div>
                        </div>

                        <div className="almacenDiv">
                            <div className="input__Almacen">
                                <b className="ml-1">*Usuario Sol Principal</b>
                                <input  name="UsuarioSolPrincipal" className="input__linea col-3" value={this.props.state.UsuarioSolPrincipal} onChange={this.props.handleChange} type="text" />
                                <b className="ml-1">*Clave Sol Principal</b>
                                <input  name="ClaveSolPrincipal" className="input__linea col-3" value={this.props.state.ClaveSolPrincipal} onChange={this.props.handleChange} type="text" />
                            </div> 
                        </div> 
            </React.Fragment>
            );
        }
    }
}
export default EmpresasAddFormDatosGenerales;