import React, {Component} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import "react-moment";
import {BotonCancelar} from "../componentes/Plantillas/Botones";
import "react-moment";
import moment from "moment";
import {notificarError, notificationDarDeAlta} from "../componentes/Almacenes/AlmacenNotify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ProductosMasVendidos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ProductosMasVendidos: [],
            FechaInicio: moment(new Date()).format('YYYY-MM-DD'),
            FechaFin: moment(new Date()).format('YYYY-MM-DD'),
            idSucursal: 0,
            sucursales: [],
            dataExcelReport: this.getDataProductosExportExcel([]),
        };
        this.getProductosMasVendidos = this.getProductosMasVendidos.bind(this);
        this.handleSavePDF = this.handleSavePDF.bind(this);

        this.handleFechaInicio = this.handleFechaInicio.bind(this);
        this.handleFechaFin = this.handleFechaFin.bind(this);
        this.onClickExtraerMasVendidos = this.onClickExtraerMasVendidos.bind(this);
    }

    componentDidMount() {
        this.fetchSucursales();

    }

    fetchSucursales() {
        fetch('/api/sucursales/SucursalEmpresa')
            .then(res => res.json())
            .then(data => this.setState({sucursales: data}))
            .then(() => {
                if (this.state.sucursales.length)
                    this.setState({idSucursal: this.state.sucursales[0].IdSucursal})
            })
    }

    async getProductosMasVendidos(Inicio, Fin) {
        try {
            const response = await fetch(
                `/api/dashboard/get/productos-mas-vendidos-sucursal/${Inicio}/${Fin}/${this.state.idSucursal}`
            );
            const ProductosMasVendidos = await response.json();
            this.setState({
                ProductosMasVendidos: ProductosMasVendidos.respuesta,
            });
        } catch (error) {
            this.setState({error});
        }
    }

    handleFechaInicio(e) {
        let fechaInicio = e.target.value.length ? e.target.value : moment(new Date()).format('YYYY-MM-DD')
        let fechaFin = this.state.FechaFin.length ? this.state.FechaFin : moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            FechaInicio: fechaInicio,
            FechaFin: fechaFin
        })
    }

    handleFechaFin(e) {
        let fechaInicio = this.state.FechaInicio.length ? this.state.FechaInicio : moment(new Date()).format('YYYY-MM-DD')
        let fechaFin = e.target.value.length ? e.target.value : moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            FechaInicio: fechaInicio,
            FechaFin: fechaFin
        })
    }

    async handleSavePDF() {
        let doc = new jsPDF({
            format: "a4"
        });

        var pageHeight =
            doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
        var pageWidth =
            doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        doc.setFontSize(14);
        doc.text("REPORTE DE PRODUCTOS MÁS VENDIDOS", 55, 10);

        let prods = await this.getProdsForReporte();

        doc.autoTable({
            body: prods,
            theme: "grid",
            columnStyles: {
                text: {
                    columnWidth: "wrap",
                    overflow: "linebreak",
                    halign: "right",
                    valign: "middle"
                },
                codigo: {
                    columnWidth: 25,
                    halign: "right",
                    valign: "middle",
                    overflow: "linebreak"
                },
                nombreProducto: {
                    columnWidth: 30,
                    halign: "left",
                    valign: "middle",
                    overflow: "linebreak"
                },
                fechaVenta: {
                    columnWidth: 30,
                    overflow: "linebreak"
                },
                cantidad: {
                    columnWidth: 20,
                    halign: "right"
                }
            },
            columns: [
                {header: "CÓDIGO", dataKey: "codigo"},
                {header: "NOMBRE PRODUCTO", dataKey: "nombreProducto"},
                {header: "FECHA DE VENTA", dataKey: "fechaVenta"},
                {header: "CANTIDAD", dataKey: "cantidad"},
                {header: "PRECIO VENTA", dataKey: "precioVenta"},
            ]
        });
        doc.setFontSize(10);
        doc.setFontType("italic");
        doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

        doc.save("ProductosMasVendidos.pdf");
    }

    getDataProductosExportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Código",
                        width: {wpx: 90}
                    },
                    {
                        title: "Producto",
                        width: {wpx: 400}
                    },
                    {
                        title: "Fecha de venta",
                        width: {wpx: 100}
                    },
                    {
                        title: "Cantidad",
                        width: {wpx: 100}
                    },
                    {
                        title: "Precio venta",
                        width: {wpx: 100}
                    },
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.codigo,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.nombreProducto,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.fechaVenta,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.cantidad,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.precioVenta,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    onClickExtraerMasVendidos(e) {
        if (this.state.idSucursal === 0)
            notificarError("Debe seleccionar una sucursal.")
        else {
            this.getProductosMasVendidos(this.state.FechaInicio, this.state.FechaFin)
                .then(() => this.fetchDataForExcel())
        }
    }

    async fetchDataForExcel() {
        let data = await this.getDataProductosExportExcel(await this.getProdsForReporte())
        this.setState({dataExcelReport: data})
    }

    async getProdsForReporte() {
        let prods = this.state.ProductosMasVendidos;
        let newProds = [];
        for (let i = 0; i < prods.length; i++) {
            let ventas = await this.fetchVentasProducto(prods[i].IdProducto);
            for (let j = 0; j < ventas.length; j++) {
                newProds.push({
                    codigo: prods[i].Codigo,
                    nombreProducto: prods[i].Nombre,
                    descripcion: prods[i].Descripcion,
                    fechaVenta: moment(ventas[j].FechaEmision).format('DD/MM/YYYY HH:mm:ss'),
                    cantidad: ventas[j].Cantidad,
                    precioVenta: ventas[j].precioVenta,
                })
            }
        }
        return newProds;
    }

    async fetchVentasProducto(idProd) {
        let res = await fetch(`/api/dashboard/ventas-por-prod/${this.state.FechaInicio}/${this.state.FechaFin}/${idProd}/${this.state.idSucursal}`);
        let data = await res.json();
        return data.ventas;
    }


    render() {

        return (
            <>
                {this.state.ProductosMasVendidos ? (
                    <div>
                        <div className="container mt-5">
                            <div className="">
                                <BotonCancelar to="/dashboard"/>
                            </div>
                            <div className="d-flex  justify-content-between align-items-center">
                                <h3 className="">Productos más vendidos</h3>
                                <div
                                    className="d-flex justify-content-center"
                                >
                                    <span className="align-self-center">Inicio: </span>
                                    <input
                                        type="date"
                                        onChange={this.handleFechaInicio}
                                        value={this.state.FechaInicio}
                                        className="mr-1 mt-3 mb-3 form-control"
                                    />
                                    <span className="align-self-center">Fin: </span>
                                    <input
                                        type="date"
                                        onChange={this.handleFechaFin}
                                        value={this.state.FechaFin}
                                        className="mr-1 mt-3 mb-3 form-control"
                                    />
                                </div>
                                <div>
                                    <button className="boton__Rojo mr-2" onClick={this.handleSavePDF}>
                                        {<i className="fas fa-file-pdf"></i>}
                                    </button>
                                    <ExcelFile
                                        element={
                                            <button className="boton__verdeOscuro">
                                                <i className="fas fa-file-excel"></i>
                                            </button>
                                        }
                                        filename="ReporteProductosMasVendidos"
                                    >
                                        <ExcelSheet
                                            dataSet={this.state.dataExcelReport}
                                            name="Productos"
                                        ></ExcelSheet>
                                    </ExcelFile>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-sm-10">
                                    <label htmlFor="idSucursal">Sucursal</label>
                                    <select name="idSucursal" id="idSucursal" className="form-control"
                                            onChange={e => this.setState({idSucursal: e.target.value})}>
                                        {
                                            [...this.state.sucursales].map(s =>
                                                (<option key={`opt_${s.IdSucursal}`}
                                                         value={s.IdSucursal}>{s.Nombre}</option>)
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-2 mt-4">
                                    <button onClick={this.onClickExtraerMasVendidos}
                                            className="btn btn-outline-primary">Generar
                                    </button>
                                </div>
                            </div>

                            <ReactTable
                                id="table-productos-to-xls"
                                data={this.state.ProductosMasVendidos}
                                filterable
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value
                                }
                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "Código",
                                                id: "Codigo",
                                                accessor: d => d.Codigo,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {keys: ["Codigo"]}),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Producto",
                                                id: "Nombre",
                                                accessor: d => d.Nombre,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Nombre"]
                                                    }),
                                                filterAll: true
                                            },
                                            {
                                                Header: "Cantidad",
                                                id: "Cantidad",
                                                accessor: d => d.Cantidad,
                                                filterMethod: (filter, rows) =>
                                                    matchSorter(rows, filter.value, {
                                                        keys: ["Cantidad"]
                                                    }),
                                                filterAll: true,
                                                getProps: (state, rowInfo, column) => {
                                                    return {
                                                        style: {
                                                            textAlign: "right"
                                                        }
                                                    };
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                previousText="Anterior"
                                nextText="Siguiente"
                                noDataText="No se encontraron registros"
                                loadingText="Cargando..."
                                ofText="de"
                                rowsText="filas"
                                pageText="Página"
                                pageSizeOptions={[10, 20, 25, 50, 100]}
                            />
                        </div>
                    </div>
                ) : (
                    <div>Cargando Productos</div>
                )}
            </>
        );
    }
}

export default ProductosMasVendidos;
