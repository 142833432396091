import React from "react";
import PageLoading from "../PageLoading.js";
import SelectSearch from "react-select-search";
import "../../pages/styles/SelectSearch.css";
import "react-moment";
import moment from "moment";
import ReactExport from "react-data-export";
import {BotonExcel, BotonLetras, BotonPDF, BotonCancelar} from "../Plantillas/Botones.js";
import {notificationKardexProductoNull, notificationKardexFechas} from "./KardexNotify.js";
import '../styles/Kardex.css';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import TablaPDF from "./TablaPDF.js";
import {getDataExportExcel} from "./TablaKardexExcel.js";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {ID_TIPO_MOV_ALMACEN} from "../../Global";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class KardexSC extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dataProductos: [],
            isLoading: false,
            error: null,
            IdProducto: null,
            nombreProducto: "",
            FechaInicio: "",
            FechaFin: "",
            dataVentas: {
                items: []
            },
            RUC: "",
            RazonSocial: "",
            Establecimiento: "",
            CodigoExistencia: "",
            CodigoUnidad: "",
            Descripcion: "",
            CodigoProducto: "",
            TotalCantidadEntradas: 0,
            TotalCostoUnitarioEntradas: 0,
            TotalCostoTotalEntradas: 0,
            TotalCantidadSalidas: 0,
            TotalCostoUnitarioSalidas: 0,
            TotalCostoTotalSalidas: 0,
            TotalCantidadSaldo: 0,
            TotalCostoUnitarioSaldo: 0,
            TotalCostoTotalSaldo: 0,
            displayButton: "none",
            prod: {}
        }

        this.FechaChange = this.FechaChange.bind(this)
        this.handleProductoChange = this.handleProductoChange.bind(this)
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getDataEmpresa()
    }

    async getProductosById(IdProducto) {
        this.setState({isLoading: true, error: null});
        try {
            await fetch(`/api/kardex/InfoProducto/${IdProducto}`)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        isLoading: false,
                        CodigoExistencia: data[0].CodigoExistencia,
                        CodigoUnidad: data[0].CodigoUnidad,
                        Descripcion: data[0].Descripcion,
                        CodigoProducto: data[0].CodigoProducto
                    })
                });
        } catch (error) {
            this.setState({
                isLoading: false,
                error: error,
            });
            console.log(error)
        }
    }

    async getDataEmpresa() {
        this.setState({loading: true, error: null});
        try {
            await fetch(`/api/kardex/InfoEmpresa`)
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        isLoading: false,
                        RUC: data[0].Ruc,
                        RazonSocial: data[0].RazonSocial,
                        Establecimiento: data[0].NombreUbigeo.concat(`- ${data[0].Direccion}`)
                    })
                });
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
            console.log(error)
        }
    }

    handleProductoChange(e) {
        this.setState({
            IdProducto: e.value,
            nombreProducto: e.nombre,
            displayButton: "none",
            prod: e,
        });
        this.getProductosById(e.value)
    }

    FechaChange(e) {
        const {name, value} = e.target;
        //console.log(name,value)
        this.setState({
            [name]: value,
            displayButton: "none"
        });
    }

    searchProductos(query) {
        return new Promise((resolve, reject) => {
            fetch(`/api/productos?search=${query}`)
                .then(response => response.json())
                .then(json => json.productos)
                .then(prods => {
                    const mappedProds = prods.map(p => {
                        return {
                            ...p,
                            label: p.nombre,
                            value: p.id
                        }
                    })
                    resolve(mappedProds)
                }).catch(reject);
        })
    }


    async GetKardex() {
        if (this.state.IdProducto == null) {
            notificationKardexProductoNull()
        } else {
            if (this.state.FechaInicio > this.state.FechaFin) {
                notificationKardexFechas()
            } else {
                try {
                    this.setState({isLoading: true, error: null});
                    const response = await fetch(
                        `/api/kardex/getProductoKardexSC/IdProducto=${this.state.IdProducto}&FechaInicio=${this.state.FechaInicio}&FechaFin=${this.state.FechaFin}`
                    )
                    let items = await response.json();
                    items.items = items.items.map(i => {
                        return {...i, TipoOperacion: i.TipoOperacion !== null ? i.TipoOperacion : "01"}
                    })

                    this.setState({
                        isLoading: false,
                        dataVentas: items
                    })

                    await Promise.all([
                        this.calculoCostos(items.items),
                        this.setState({dataVentas: items})
                    ]).then(() => this.calculoTotales())

                    this.setState({displayButton: "inline"})

                } catch (error) {
                    this.setState({
                        isLoading: false,
                        error: error,
                    });
                    console.log(error)
                }
            }
        }

    }

    calculoCostos(items) {
        if (items.length) {
            let ultimoCosto = 0
            items[0].CostoTotalEntrada = items[0].CantidadEntrada * items[0].CostoUnitarioEntrada
            items[0].CantidadSaldo += items[0].CantidadEntrada
            items[0].CostoTotalSaldo = items[0].CostoTotalEntrada
            items[0].CostoUnitarioSaldo = items[0].CostoTotalSaldo / items[0].CantidadSaldo
            if (isNaN(items[0].CostoUnitarioSaldo)) {
                items[0].CostoUnitarioSaldo = 0
            }
            ultimoCosto = items[0].CostoUnitarioSaldo

            for (let i = 1; i < items.length; i++) {

                if (items[i].TipoOperacion === 1 || items[i].TipoOperacion === "01" || items[i].idTipoMov === ID_TIPO_MOV_ALMACEN.SALIDA) {
                    items[i].CostoUnitarioSalida = ultimoCosto
                    items[i].CostoTotalSalida = items[i].CantidadSalida * items[i].CostoUnitarioSalida
                    items[i].CantidadSaldo = items[i - 1].CantidadSaldo - items[i].CantidadSalida
                    items[i].CostoTotalSaldo = items[i - 1].CostoTotalSaldo - items[i].CostoTotalSalida
                    items[i].CostoUnitarioSaldo = items[i].CostoTotalSaldo / items[i].CantidadSaldo

                    if (isNaN(items[i].CostoUnitarioSaldo)) {
                        items[i].CostoUnitarioSaldo = 0
                    }
                    if (items[i].CostoTotalSaldo < 0) {
                        items[i].CostoTotalSaldo *= -1
                    }
                    if (items[i].CostoUnitarioSaldo < 0) {
                        items[i].CostoUnitarioSaldo *= -1
                    }
                } else {
                    items[i].CostoTotalEntrada = items[i].CantidadEntrada * items[i].CostoUnitarioEntrada
                    if (!items[i - 1]) {
                        items[i].CantidadSaldo = items[i].CantidadEntrada
                        items[i].CostoUnitarioSaldo = items[i].CostoUnitarioEntrada
                        items[i].CostoTotalSaldo = items[i].CantidadSaldo * items[i].CostoUnitarioSaldo
                    } else {
                        items[i].CantidadSaldo = items[i].CantidadEntrada + items[i - 1].CantidadSaldo
                        items[i].CostoTotalSaldo = items[i].CostoTotalEntrada + items[i - 1].CostoTotalSaldo
                        items[i].CostoUnitarioSaldo = items[i].CostoTotalSaldo / items[i].CantidadSaldo
                    }
                    if (isNaN(items[0].CostoUnitarioSaldo)) {
                        items[0].CostoUnitarioSaldo = 0
                    }
                    if (items[i].CostoTotalSaldo < 0) {
                        items[i].CostoTotalSaldo *= -1
                    }
                    if (items[i].CostoUnitarioSaldo < 0) {
                        items[i].CostoUnitarioSaldo *= -1
                    }
                    ultimoCosto = items[i].CostoUnitarioSaldo
                }
            }
        }
    }

    calculoTotales = () => {

        const lista = this.state.dataVentas.items

        if (lista.length) {

            const TotalCantidadEntradas = (total, {CantidadEntrada}) => total + CantidadEntrada
            const TotalCostoUnitarioEntradas = (total, {CostoUnitarioEntrada}) => total + CostoUnitarioEntrada
            const TotalCostoTotalEntradas = (total, {CostoTotalEntrada}) => total + CostoTotalEntrada

            const TotalCantidadSalidas = (total, {CantidadSalida}) => total + CantidadSalida
            const TotalCostoUnitarioSalidas = (total, {CostoUnitarioSalida}) => total + CostoUnitarioSalida
            const TotalCostoTotalSalidas = (total, {CostoTotalSalida}) => total + CostoTotalSalida

            const TotalCantidadSaldo = (total, {CantidadSaldo}) => total + CantidadSaldo
            const TotalCostoUnitarioSaldo = (total, {CostoUnitarioSaldo}) => total + CostoUnitarioSaldo
            const TotalCostoTotalSaldo = (total, {CostoTotalSaldo}) => total + CostoTotalSaldo

            this.setState({
                TotalCantidadEntradas: lista.reduce(TotalCantidadEntradas, 0),
                TotalCostoUnitarioEntradas: lista.reduce(TotalCostoUnitarioEntradas, 0),
                TotalCostoTotalEntradas: lista.reduce(TotalCostoTotalEntradas, 0),

                TotalCantidadSalidas: lista.reduce(TotalCantidadSalidas, 0),
                TotalCostoUnitarioSalidas: lista.reduce(TotalCostoUnitarioSalidas, 0),
                TotalCostoTotalSalidas: lista.reduce(TotalCostoTotalSalidas, 0),

                TotalCantidadSaldo: lista.reduce(TotalCantidadSaldo, 0),
                TotalCostoUnitarioSaldo: lista.reduce(TotalCostoUnitarioSaldo, 0),
                TotalCostoTotalSaldo: lista.reduce(TotalCostoTotalSaldo, 0)
            })
        }
    }

    async handleSavePDF() {
        const doc = new jsPDF({
            orientation: 'landscape'
        })
        doc.setFontSize(10);
        doc.text(`PERIODO: ${moment(this.state.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.state.FechaFin).format("DD/MM/YYYY")}`, 10, 20)
        doc.text(`RUC: ${this.state.RUC}`, 10, 25)
        doc.text(`APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL: ${this.state.RazonSocial}`, 10, 30)
        doc.text(`ESTABLECIMIENTO: ${this.state.Establecimiento}`, 10, 35)
        doc.text(`CÓDIGO DE LA EXISTENCIA:  ${this.state.CodigoProducto}`, 10, 40)
        doc.text(`TIPO: (TABLA 5): ${this.state.CodigoExistencia} ${this.state.Descripcion}`, 10, 45)
        doc.text(`DESCRIPCIÓN: ${this.state.nombreProducto}`, 10, 50)
        doc.text(`CÓDIGO DE LA UNIDAD DE MEDIDA: ${this.state.CodigoUnidad}`, 10, 55)
        doc.text(`MÉTODO DE EVALUACIÓN: `, 10, 60)
        //doc.setFontSize(5);
        doc.autoTable({
            html: '#tableKardex',
            startY: 70,
            styles: {
                overflow: "linebreak",
                lineWidth: 0.1,
                tableWidth: "auto",
                columnWidth: "auto",
                halign: "center",
                fontSize: 8
            },
            theme: "plain"
        })
        doc.text(`(1) Dirección del Establecimiento o Código según el Registro Único de Contribuyentes.`, 10, doc.autoTable.previous.finalY + 10)
        doc.save(`${moment(this.state.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.state.FechaFin).format("DD/MM/YYYY")}.pdf`)
    }

    render() {
        const dataExport = getDataExportExcel(this.state, " ")

        if (this.state.isLoading === true) {
            return (
                <React.Fragment>
                    <div>
                        <PageLoading/>
                    </div>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>

                    <div className="ProcesarBoton mt-1">
                        <BotonCancelar
                            onClick={this.props.history.goBack}
                            className="ml-4"

                        />

                        <div className="ProcesarBotones">
                            <BotonPDF
                                className="ml-2 mr-2"
                                title="Guardar PDF"
                                onClick={() => this.handleSavePDF()}
                                style={{display: `${this.state.displayButton}`}}
                            />

                            <ExcelFile
                                element={
                                    <BotonExcel
                                        type="button"
                                        title="Guardar Excel"
                                        style={{display: `${this.state.displayButton}`}}/>
                                }
                                filename={`${this.state.nombreProducto} / ${moment(this.state.FechaInicio).format("DD/MM/YYYY")} - ${moment(this.state.FechaFin).format("DD/MM/YYYY")}`}
                            >
                                <ExcelSheet
                                    dataSet={dataExport}
                                    name="kardexFile"
                                >
                                </ExcelSheet>
                            </ExcelFile>

                            <BotonLetras
                                className="mr-4 ml-2"
                                type="button"
                                onClick={() => this.GetKardex()}
                            >
                                Cargar
                            </BotonLetras>
                        </div>
                    </div>

                    <div className="kardex__container">
                        <div className="kardex">

                            <div className="reporteVentasProd">
                                <div className="KardexDocumento mb-4">
                                    <b className="ml-4">*Fecha Inicio</b>
                                    <div className="input__KardexDivSmall">
                                        <input
                                            className="FechaInicio input__linea"
                                            name="FechaInicio"
                                            onChange={this.FechaChange}
                                            type="date"
                                            value={this.state.FechaInicio}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="KardexDocumento mb-4">
                                    <b className="ml-4">*Fecha Fin</b>
                                    <div className="input__KardexDivSmall">
                                        <input
                                            className="FechaFin input__linea"
                                            name="FechaFin"
                                            onChange={this.FechaChange}
                                            type="date"
                                            value={this.state.FechaFin}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="KardexDocumento">
                                <AsyncSelect
                                    defaultOptions={true}
                                    onChange={this.handleProductoChange}
                                    value={this.state.prod}
                                    loadOptions={this.searchProductos}
                                />
                            </div>

                        </div>
                    </div>

                    <TablaPDF
                        data={this.state.dataVentas.items}
                        TotalCantidadEntradas={this.state.TotalCantidadEntradas}
                        TotalCostoTotalEntradas={this.state.TotalCostoTotalEntradas}
                        TotalCantidadSalidas={this.state.TotalCantidadSalidas}
                        TotalCostoTotalSalidas={this.state.TotalCostoTotalSalidas}
                    />

                </React.Fragment>
            )
        }
    }

}

export default KardexSC
