import React from 'react';
import "react-moment";
import moment from "moment";

class TablaPDF extends React.Component {
    render() {
        const kardex = this.props.data
        const zero = Number(0).toLocaleString("en", {
            minimumFractionDigits: 6,
            maximumFractionDigits: 6
        });
        return (
            <React.Fragment>
                <table id="tableKardex" className="table" hidden>
                    <thead>
                    <tr>
                        <th colSpan="4" rowSpan="2">
                            DOCUMENTO DE TRASLADO, COMPROBANTE DE PAGO,
                            DOCUMENTO INTERNO O SIMILAR
                        </th>

                        <th rowSpan="3">TIPO OPERACIÓN (TABLA 12)</th>

                        <th colSpan="3">
                            ENTRADAS
                        </th>
                        <th colSpan="3">
                            SALIDAS
                        </th>
                        <th colSpan="3">
                            SALDO
                        </th>
                    </tr>

                    <tr>
                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>

                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>

                        <th rowSpan="2">
                            CANTIDAD
                        </th>
                        <th rowSpan="2">
                            COSTO UNITARIO
                        </th>
                        <th rowSpan="2">
                            COSTO TOTAL
                        </th>
                    </tr>

                    <tr>
                        <th>Fecha</th>
                        <th>Tipo Documento</th>
                        <th>Serie</th>
                        <th>Número</th>
                    </tr>
                    </thead>

                    <tbody>
                    {kardex.map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{moment(item.FechaEmision).format("DD/MM/YYYY")}</td>
                                <td>{item.TipoDocumento}</td>
                                <td>{item.Serie}</td>
                                <td>{item.NumeroComprobante}</td>
                                <td>{item.TipoOperacion}</td>
                                <td>
                                    {isNaN(item.CantidadEntrada) ? zero : item.CantidadEntrada.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoUnitarioEntrada) ? zero : item.CostoUnitarioEntrada.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoTotalEntrada) ? zero : item.CostoTotalEntrada.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CantidadSalida) ? zero : item.CantidadSalida.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoUnitarioSalida) ? zero : item.CostoUnitarioSalida.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoTotalSalida) ? zero : item.CostoTotalSalida.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CantidadSaldo) ? zero : item.CantidadSaldo.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoUnitarioSaldo) ? zero : item.CostoUnitarioSaldo.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                                <td>
                                    {isNaN(item.CostoTotalSaldo) ? zero : item.CostoTotalSaldo.toLocaleString("en", {
                                        minimumFractionDigits: 6,
                                        maximumFractionDigits: 6
                                    })}
                                </td>
                            </tr>
                        )
                    })}
                    <tr>
                        <th colSpan="5">TOTALES</th>
                        <td>
                            {isNaN(this.props.TotalCantidadEntradas) ? zero : this.props.TotalCantidadEntradas.toLocaleString("en", {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6
                            })}
                        </td>
                        <td></td>
                        <td>
                            {isNaN(this.props.TotalCostoTotalEntradas) ? zero : this.props.TotalCostoTotalEntradas.toLocaleString("en", {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6
                            })}
                        </td>
                        <td>
                            {isNaN(this.props.TotalCantidadSalidas) ? zero : this.props.TotalCantidadSalidas.toLocaleString("en", {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6
                            })}
                        </td>
                        <td></td>
                        <td>
                            {isNaN(this.props.TotalCostoTotalSalidas) ? zero : this.props.TotalCostoTotalSalidas.toLocaleString("en", {
                                minimumFractionDigits: 6,
                                maximumFractionDigits: 6
                            })}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </React.Fragment>
        )
    }
}

export default TablaPDF
