import React from "react";
import "../styles/tabla.css"
import { BotonBuscar,BotonGuardarLetras, BotonCancelar } from "../Plantillas/Botones";
class EmpresasAddFormDatosSunat extends React.Component {
    render(){
        return (
            <React.Fragment>

            <div className="almacenDiv">
                <b className="ml-4" >*Ruc</b> 
                <div className="input__Almacen">
                    <input required
                        name="RucPre" 
                        className="input__linea"
                        placeholder="Ruc..."
                        onChange={this.props.handleChange} 
                        type="text" 
                        autoFocus/>
                    <BotonBuscar
                        type="submit"
                        onClick={this.props.handleSubmitRUC}
                    />
                    {/* <button type="submit" className="col-2 ml-5" onClick={this.props.handleSubmitRUC}>
                        SUNAT
                    </button> */}
                    <div className="labelValidAlmacen">
                        {this.props.state.Errores.Ruc.length > 0 && (<span>{this.props.state.Errores.Ruc}</span>)}
                    </div>
                </div>
            </div>

            <div className="almacenDiv">
                <b className="ml-4">Razón Social</b>
                <div className="input__Almacen">
                    <input required
                        name="RazonSocial" 
                        className="input__linea" 
                        placeholder="Razón Social..."
                        onChange={this.props.handleChange} 
                        defaultValue={this.props.state.RazonSocial}
                        disabled
                        type="text"/>
                    <div className="labelValidAlmacen">
                        {this.props.state.Errores.RazonSocial.length > 0 && (
                        <span>{this.props.state.Errores.RazonSocial}</span>
                        )}
                    </div>
                </div>
            </div>
                
            <div className="almacenDiv">
                <b className="ml-4" >Domicilio Fiscal</b>
                <div className="input__Almacen">
                    <input  required 
                        name="Direccion" 
                        className="input__linea" 
                        placeholder="Domicilio Fiscal..."
                        onChange={this.props.handleChange} 
                        value={this.props.state.Direccion}
                        disabled
                        type="text"/>
                    <div className="labelValidAlmacen">
                        {this.props.state.Errores.Direccion.length > 0 && (
                            <span>{this.props.state.Errores.Direccion}</span>
                        )}
                    </div>
                </div>
            </div>

                <div className="almacenDiv ">
                    <b className="ml-4">Nombre Comercial</b>
                    <div className="input__Almacen">
                    <input 
                        required
                        className="input__linea" 
                        placeholder="Nombre Comercial..."
                        name="NombreComercial" 
                        value={this.props.state.NombreComercial}
                        onChange={this.props.handleChange} 
                        disabled
                        type="text"/>
                    </div>
                </div>
                
                <div className="almacenDiv">
                    <div className="input__Almacen">
                        <b className="ml-1">Condición del Contribuyente </b>
                        <input required 
                        placeholder="Condición del Contribuyente"
                            name="ContribuyenteCondicion" 
                            disabled 
                            className="ml-2 input__linea col-2" 
                            value={this.props.state.ContribuyenteCondicion} 
                            type="text" />
                            
                        <b className="ml-4">Estado del Contribuyente </b>
                        <input required 
                        placeholder="Estado del Contribuyente"
                            name="ContribuyenteEstado" 
                            disabled 
                            className="ml-2 input__linea col-2" 
                            value={this.props.state.ContribuyenteEstado} 
                            type="text" />
                    </div>
                </div>  

                <div className="almacenDiv">
                    <b className="ml-4">Ubigeo </b>
                    <div className="input__Almacen">
                        <input required 
                            name="Ubigeo" 
                            disabled 
                            placeholder="Nombre Ubigeo - Código Ubigeo"
                            className="input__linea" 
                            value={this.props.state.ubigeoNombrecodigo} 
                            type="text" />
                    </div>
                </div>      
                
            </React.Fragment>
        );
    }
}
export default EmpresasAddFormDatosSunat;