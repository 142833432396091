import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import matchSorter from "match-sorter";
import jsPDF from "jspdf";
import ReactExport from "react-data-export";
import "react-moment";
import { BotonCancelar } from "../componentes/Plantillas/Botones";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class DetalleProductosAgotarStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProductosAgotarStock: null
    };

    this.getProductosAgotarStock = this.getProductosAgotarStock.bind(this);
    this.handleSavePDF = this.handleSavePDF.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.getProductosAgotarStock();
  }

  async getProductosAgotarStock() {
    try {
      const response = await fetch(`/api/dashboard/get/productosagotarstock`);
      const ProductosAgotarStock = await response.json();
      this.setState({
        ProductosAgotarStock: ProductosAgotarStock.respuesta,
        isLoading: false
      });
      return ProductosAgotarStock;
    } catch (error) {
      this.setState({ error, isLoading: false });
    }
  }

  getDataProductosExportExcel(dataSet) {
    return [
      {
        columns: [
          {
            title: "Código",
            width: { wpx: 90 }
          },
          {
            title: "Nombre Producto",
            width: { wpx: 400 }
          },
          {
            title: "Descripción",
            width: { wpx: 300 }
          },
          {
            title: "Stock",
            width: { wpx: 100 }
          },
          {
            title: "Stock Mínimo",
            width: { wpx: 100 }
          },
          {
            title: "Nombre Almacen",
            width: { wpx: 250 }
          },
          {
            title: "Nombre Sucursal",
            width: { wpx: 250 }
          }
        ],

        data: dataSet.map(row => {
          return [
            {
              value: row.Codigo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreProducto,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Descripcion || "",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.Stock,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.StockMinimo,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreAlmacen,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            },
            {
              value: row.NombreSucursal,
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "F6F8FA" } }
              }
            }
          ];
        })
      }
    ];
  }
  handleSavePDF() {
    let doc = new jsPDF({
      orientation: "landscape",
      format: "a4"
    });

    var pageHeight =
      doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    var pageWidth =
      doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.setFontSize(14);
    doc.text("REPORTE DE PRODUCTOS PRÓXIMOS A AGOTAR EXISTENCIA", 80, 10);
    doc.autoTable({
      body: this.state.ProductosAgotarStock,
      theme: "grid",
      columnStyles: {
        text: {
          columnWidth: "wrap",
          overflow: "linebreak",
          halign: "right",
          valign: "middle"
        },
        Codigo: {
          columnWidth: 25,
          halign: "right",
          valign: "middle",
          overflow: "linebreak"
        },
        NombreProducto: {
          columnWidth: 30,
          halign: "left",
          valign: "middle",
          overflow: "linebreak"
        },
        Descripcion: {
          columnWidth: 30,
          halign: "left",
          valign: "middle",
          overflow: "linebreak"
        },
        Stock: {
          columnWidth: 20,
          halign: "right"
        },
        StockMinimo: {
          columnWidth: 20,
          halign: "right"
        },
        NombreAlmacen: {
          columnWidth: 30,
          halign: "left"
        },
        NombreSucursal: {
          columnWidth: 30,
          halign: "left"
        }
      },
      columns: [
        { header: "CÓDIGO", dataKey: "Codigo" },
        { header: "NOMBRE PRODUCTO", dataKey: "NombreProducto" },
        { header: "DESCRIPCION", dataKey: "Descripcion" },
        { header: "STOCK", dataKey: "Stock" },
        { header: "STOCK MÍNIMO", dataKey: "StockMinimo" },
        { header: "NOMBRE ALMACEN", dataKey: "NombreAlmacen" },
        { header: "NOMBRE SUCURSAL", dataKey: "NombreSucursal" }
      ]
    });
    doc.setFontSize(10);
    doc.setFontType("italic");
    doc.text("Contatrib ERP", pageWidth - 7, pageHeight - 7, "right");

    doc.save("ProductosAgotarStock.pdf");
  }

  render() {
    let dataExcel = null;
    if (this.state.ProductosAgotarStock) {
      dataExcel = this.getDataProductosExportExcel(
        this.state.ProductosAgotarStock
      );
    }

    return (
      <>
        {dataExcel ? (
          <div>
            <div className="container mt-5">
              <div className="">
                <BotonCancelar to="/dashboard" />
              </div>
              <div className="d-flex  justify-content-between align-items-center">
                <h3 className="">
                  Detalle de Productos próximos a agotar Stock
                </h3>
                <div className="mb-2">
                  <button className="boton__Rojo mr-2" onClick={this.handleSavePDF}>
                    {<i className="fas fa-file-pdf"></i>}
                  </button>
                  <ExcelFile
                    element={
                      <button className="boton__verdeOscuro">
                        <i className="fas fa-file-excel"></i>
                      </button>
                    }
                    filename="ReporteProductosPorAgotarStock"
                  >
                    <ExcelSheet
                      dataSet={dataExcel}
                      name="Productos"
                    ></ExcelSheet>
                  </ExcelFile>
                </div>
              </div>
              <ReactTable
                id="table-productos-to-xls"
                data={this.state.ProductosAgotarStock}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: "Código",
                        id: "Codigo",
                        accessor: d => d.Codigo,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Codigo"] }),
                        filterAll: true
                      },
                      {
                        Header: "Sucursal",
                        id: "NombreSucursal",
                        accessor: d => d.NombreSucursal,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["NombreSucursal"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Almacen",
                        id: "NombreAlmacen",
                        accessor: d => d.NombreAlmacen,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["NombreAlmacen"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Producto",
                        id: "NombreProducto",
                        accessor: d => d.NombreProducto,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["NombreProducto"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Descripcion",
                        id: "Descripcion",
                        accessor: d => d.Descripcion,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, {
                            keys: ["Descripcion"]
                          }),
                        filterAll: true
                      },
                      {
                        Header: "Stock",
                        id: "Stock",
                        accessor: d => d.Stock,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["Stock"] }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right"
                            }
                          };
                        }
                      },
                      {
                        Header: "Stock Mínimo",
                        id: "StockMinimo",
                        accessor: d => d.StockMinimo,
                        filterMethod: (filter, rows) =>
                          matchSorter(rows, filter.value, { keys: ["StockMinimo"] }),
                        filterAll: true,
                        getProps: (state, rowInfo, column) => {
                          return {
                            style: {
                              textAlign: "right"
                            }
                          };
                        }
                      }
                    ]
                  }
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                previousText="Anterior"
                nextText="Siguiente"
                noDataText="No se encontraron registros"
                loadingText="Cargando..."
                ofText="de"
                rowsText="filas"
                pageText="Página"
                pageSizeOptions={[10, 20, 25, 50, 100]}
              />
            </div>
          </div>
        ) : (
          <div>Cargando Productos</div>
        )}
      </>
    );
  }
}

export default DetalleProductosAgotarStock;
