import React from 'react';

export class CuotasCreditoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cantCuotas: 0,
            cantDias: 0
        }
        this.onClickAceptar = this.onClickAceptar.bind(this);
    }

    onClickAceptar() {
        this.props.onAceptarCuotasCredito({...this.state})
    }


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="cantCuotas">Cantidad de cuotas</label>
                        <input
                            onChange={(e) => this.setState({cantCuotas: e.target.value})}
                            type="number" min={1} max={999} className="form-control" id="cantCuotas"/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <label htmlFor="cantDias">Cantidad de días</label>
                        <input
                            onChange={(e) => this.setState({cantDias: e.target.value})}
                            type="number" min={1} max={999} className="form-control" id="cantDias"/>
                    </div>
                </div>

                <div className="row">
                    <div className="btn btn-group col-sm">
                        <button className="btn btn-outline-primary" onClick={this.onClickAceptar}>
                            Aceptar
                        </button>
                    </div>
                </div>

            </div>
        )
    }
}
