import React, {Component} from "react";
import swal from "sweetalert";
import {
    calcTotal,
    calcularTotales,
    CONTADO,
    decimalAdjust,
    ICBPER,
    IGV,
    IVAP,
    NUMBER_KEYS,
    PRECIO_VALE,
    TIPO_STOCK,
    VALE
} from "../../Global";

import Modal from "../../componentes/Modal.js";

import {BOLETA, FACTURA, RUC} from "../../Global.js";
import {
    notificationBoletaVarios,
    notificationCantidadNegativa,
    notificationClienteNoExiste,
    notificationConfigBoletaRUC,
    notificationDescuentoGratuito,
    notificationDescuentoMayorTotal,
    notificationFacturaRUC,
    notificationNoHayProducto,
    notificationPreProcesarValeVacia,
    notificationStock0
} from '../../componentes/Preventas/PreVentaNotify'
import printA4 from "../../helpers/A4";
import printTicket from "./Ticket";
import BuscarClientePorDoc from '../../componentes/Preventas/BuscarClientePorDoc'
import ModalCliente from '../../componentes/clientes/ClienteModal';
import ClienteNuevo from '../../componentes/clientes/ClientesNuevo';
import calcularVenta, {setPreciosYDescuento, setValorUnitario} from "../../helpers/calcularVenta.js";
import ModalBuscarCliente from "../../componentes/Preventas/ModalBuscarCliente";
import Notify from "../../componentes/Notify";
import ModalImprimirReporte from "./ModalImprimirReporte";
import ReactExport from "react-data-export";
import {
    FindPrecioEspecial,
    FindPrecioFamiliar,
    FindPrecioMenor,
    FindPrecioPorMayor,
    GetPrecioCosto,
    getPrecioPorMayor
} from "../../componentes/Preventas/PreciosPreventa";
import _ from "lodash";
import ListaProductoPreVale from "../../componentes/Vales/ListaProductosPreVale";
import $ from "jquery";
import DetallesProductoPreventa from "../../componentes/Preventas/DetallesProductoPreventa";
import Noty from "noty";
import PreventaModalDescuentos from "../../componentes/Preventas/PreventaModalDescuentos";
import {isNumber} from "../../helpers/utils";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ProcesarVales extends Component {
    constructor(props) {
        super(props);
        const extraConf = JSON.parse(window.localStorage.getItem('extraConf'))
        this.state = {
            dataProductos: { //dataProducto.productosVale
                productosVale: []
            },
            dataProductosPreMostrar: { //dataDetalle.item
                productosVale: []
            },
            dataProductosMostrar: { //detallePreventa.detalleItem
                productosVale: []
            },
            crearRegistro: true,
            loading: true,
            BoletaRuc: "",
            error: null,
            buscar: "",
            Documento: "99999999",
            dataCliente: [],
            IdCliente: "",
            Estado: "GENERANDO",
            Codigo: "ABC",
            IdMoneda: extraConf.moneda.IdMoneda,
            TipoCambio: 1,
            TasaIGV: IGV,
            TasaICBPER: ICBPER,
            Total: 1,
            IdModalidadPago: 1,
            IdTipoDocumentoSunat: 4,
            Alias: "",
            habilitarProcesar: true,
            IdAlmacen: null,
            almacenes: {
                resuts: []
            },
            RUSS: "",
            filtroTipoStock: 4,
            tipoLista: 1,
            modalClienteIsOpen: false,
            newPreProcesarValeId: "",
            totalMonto: 0,
            Gravado: 0,
            Inafecto: 0,
            ICBPERPreventa: 0,
            Exonerado: 0,
            IGVPreventa: 0,
            Gratuitas: 0,
            IVAP: 0,
            ISC: 0,
            statusModalDescuento: false,
            statusModalSearchCliente: false,
            ItemDescuento: "",
            DescuentoTotal: null,
            Observacion: null,
            statusModalImprimirReporte: false,
            aperturarCaja: false,
            ClickPagar: true,
            contadorClicksaddProductoInList: 0,

            preventa: {},
            detallePreventa: [],
            IdPreventa: this.props.match.params.IdPreventa,
            Pagos: [],
            TipoComprobante: null,
            Descuento: null,
            Vuelto: null,
            IdRegistroVenta: null,
            Venta: null,
            DetallesVenta: [],
            Subtotales: [],
            Gravadas: [],
            Exoneradas: [],
            Inafectas: [],
            ICBPERArray: [],
            TotalGravadas: 0.0,
            TotalExoneradas: 0.0,
            TotalInafectas: 0.0,
            TotalICBPER: 0.0,
            TotalIGV: 0.0,
            IGVs: [],
            TotalPagado: 0.0,
            Redirect: null,
            datosProdsExcel: this.getDataConfForReportExcel([]),
            extImg: 'png',
            detalles: [],
            load: false,
            redondeo: 0,
            afectsIgv: [],
            VenderConStock: true,
            canUpdatePrice: true,
            canUpdateTotal: false,
        };

        this.getProductosPorProcesar = this.getProductosPorProcesar.bind(this);
        this.DocChange = this.DocChange.bind(this);
        this.AddProductoInList = this.AddProductoInList.bind(this)
        this.handleClickBuscar = this.handleClickBuscar.bind(this)
        this.handleCloseBuscar = this.handleCloseBuscar.bind(this)
        this.RestarCantidadDet = this.RestarCantidadDet.bind(this);
        this.AgregarCantidadDet = this.AgregarCantidadDet.bind(this);
        this.RemoveProductoInList = this.RemoveProductoInList.bind(this);
        this.onChangeCheckBoxGratuito = this.onChangeCheckBoxGratuito.bind(this)
        this.AsignarValorDoc = this.AsignarValorDoc.bind(this)
        this.handleCloseImprimirReporte = this.handleCloseImprimirReporte.bind(this)
        this.handleSavePDF = this.handleSavePDF.bind(this);
        this.handlePrintTicket = this.handlePrintTicket.bind(this);
        this.handleEnterKeyUp = this.handleEnterKeyUp.bind(this);
        this.asignarTurno = this.asignarTurno.bind(this);
        this.createPreVale = this.createPreVale.bind(this)
        this.montoTotalDetalle = this.montoTotalDetalle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSecretKeyPress = this.onSecretKeyPress.bind(this);
        this.PrecioChange = this.PrecioChange.bind(this);
        this.precioVentaChange = this.precioVentaChange.bind(this);
        this.onBlurCantidad = this.onBlurCantidad.bind(this);
        this.findPrecioUnitario = this.findPrecioUnitario.bind(this);
        this.selectPrecioChange = this.selectPrecioChange.bind(this);
        this.InputCantidadDetalleChange = this.InputCantidadDetalleChange.bind(this);
        this.onBlurPrecioVenta = this.onBlurPrecioVenta.bind(this);
        this.InputPrecioVentaChange = this.InputPrecioVentaChange.bind(this);
        this.DescuentoChange = this.DescuentoChange.bind(this);
        this.onBlurDescuento = this.onBlurDescuento.bind(this);
        this.handleOpenModalDescuento = this.handleOpenModalDescuento.bind(this);
        this.updateGratuitoWrapper = this.updateGratuitoWrapper.bind(this);
        this.onChangeAfectGratuita = this.onChangeAfectGratuita.bind(this);
        this.setAfectsIgv = this.setAfectsIgv.bind(this);
        this.onChangeTotal = this.onChangeTotal.bind(this);
        this.onBlurTotal = this.onBlurTotal.bind(this);
    }

    componentDidMount = async () => {
        await this.checkCanUpdatePrice();
        await this.checkCanUpdateTotal();
        this.asignarTurno();
        this.getRUSS();
        this.fetchTipoAfectsGratuitas();
        this.getAlmacenes()
            .then(() => {
                this.getProductosPorProcesar();
                this.fetchDataReporteExcel();
            })
        this.getConfigBoletasRuc();
        await Promise.all([
            await this.TraerClientePorDoc(this.state.Documento),
        ]).then(() => this.NewPreProcesarVales())
    }

    async checkCanUpdatePrice() {
        let res = await fetch('/api/preVentas/check-can-update-price');
        if (!res.ok)
            this.setState({canUpdatePrice: false})
    }

    async checkCanUpdateTotal() {
        let res = await fetch('/api/preVentas/check-can-update-total');
        if (res.ok)
            this.setState({canUpdateTotal: true})
    }

    async fetchTipoAfectsGratuitas() {
        try {
            let res = await fetch('/api/preVentas/afect-igv?query=')
            let json = await res.json();
            this.setState({
                afectsIgv: json
            })
        } catch (e) {
            this.setState({error: e})
        }
    }

    async fetchDataReporteExcel() {
        let res = await fetch(`/api/gestionPreProcesarVales/productos-procesar/${this.state.IdAlmacen}/${PRECIO_VALE}`)
        let data = await res.json()
        this.setState({datosProdsExcel: this.getDataConfForReportExcel(data.respuesta)})
    }

    getDataConfForReportExcel(dataSet) {
        return [
            {
                columns: [
                    {
                        title: "Nombre",
                        width: {wpx: 400}
                    },
                    {
                        title: "Stock",
                        width: {wpx: 90}
                    },
                ],

                data: dataSet.map(row => {
                    return [
                        {
                            value: row.Nombre,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        },
                        {
                            value: row.Stock,
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                            }
                        }
                    ];
                })
            }
        ];
    }

    asignarTurno = async () => {
        const response = await fetch(`/api/vales/asignarTurno`);
        const data = await response.json();
        // console.log(data.respuesta)
        this.setState({
            aperturarCaja: data.respuesta
        })
        if (data.respuesta == false) {
            Notify("Por favor, debe aperturar una caja", "error", 2000)
            this.props.history.push('/AperturarTurnosMovcaja')

        }
        // //

    }

    onChangeCheckBoxGratuito = (e, item) => {
        const {checked} = e.target;
        item.checked = checked
        item.Gratuito = checked ? 1 : 0

        if (checked) {
            this.getAfectacionIdGratuita(item)
        } else {
            const itemInicial = this.state.dataProductos.productosVale.find(findItem => {
                return (
                    findItem.IdProducto == item.IdProducto
                    && findItem.IdStock == item.IdStock
                    && findItem.IdPresentacion == item.IdPresentacion
                    // && findItem.IdAlmacen == item.IdAlmacen
                    && findItem.IdTipoStock == item.IdTipoStock
                    && findItem.IdPrecioPlantilla == item.IdPrecioPlantilla
                )
            })
            item.IdAfectacionIgv = itemInicial.IdAfectacionIgv
            this.setState(this.state.dataProductosMostrar)
            // console.log(this.state.dataProductosMostrar)
            this.ActualizarDetallePreProcesarVales(item)
        }
        // console.log(item)
        this.montoTotalDetalle(this.state.dataProductosMostrar.productosVale)
    }

    AsignarValorDoc(dataCliente) {
        // console.log(dataCliente)
        this.setState({
            Documento: dataCliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(dataCliente.NroTipoDocumento)
    }

    getAfectacionIdGratuita = async (item) => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/getIdAfectacionIgvByGrupoIgv/${item.IdGrupoClasificacionIgv}`);
            const IdAfectacionIgv = await response.json();
            item.Descuento = 0
            item.IdAfectacionIgv = IdAfectacionIgv.IdAfectacionIgv.IdAfectacionIgv
            this.setState(this.state.dataProductosMostrar)
            this.ActualizarDetallePreProcesarVales(item)
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getConfigBoletasRuc = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/preVentas/getConfEmpresa/${45}`);
            const data = await response.json();
            this.setState({BoletaRuc: data[0].Estado, extImg: data[1]})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    NewPreProcesarVales = async () => {
        this.setState({loading: true, error: null});
        let idPreVale = window.localStorage.getItem('idPreVale')
        if (idPreVale) {
            const response = await fetch(`/api/gestionPreProcesarVales/preProcesarVales/${idPreVale}`);
            const dataJson = await response.json();
            const preVale = dataJson.respuesta[0];
            this.setState({
                newPreProcesarValeId: idPreVale,
                IdTipoDocumentoSunat: preVale.IdTipoDocumentoSunat
            })
            try {
                const response = await fetch(`/api/gestionPreProcesarVales/detallepreProcesarVale/${idPreVale}`);
                const data = await response.json();
                let detsProd = data.respuesta.map(dp => ({
                    ...dp,
                    mensaje: "",
                    PrecioVenta: dp.PrecioVenta,
                    IdTipoStock: dp.IdTipoStock,
                    IdPrecioPlantilla: dp.IdPrecioPlantilla,
                    Simbolo: dp.Simbolo,
                    Tributos: dp.Tributos,
                    TasaISC: dp.TasaISC,
                    PrecioEspecial: FindPrecioEspecial([], dp),
                    PrecioFamiliar: FindPrecioFamiliar([], dp),
                    PrecioCosto: GetPrecioCosto([], dp),
                    PrecioMenor: FindPrecioMenor([], dp),
                    checked: dp.Gratuito === 1,
                    IdUnidad: dp.IdUnidad,
                    oldPrecios: [],
                    oldCants: [dp.Cantidad]
                }))
                this.montoTotalDetalle(detsProd)
                this.setState({detalles: detsProd})
            } catch (error) {
                this.setState({loading: false, error: error});
            }
        } else
            await this.createPreVale();
    }

    async createPreVale() {
        try {
            await fetch("/api/vales/add", {
                method: "POST",
                body: JSON.stringify({
                    IdCliente: this.state.IdCliente,
                    Estado: this.state.Estado,
                    Codigo: this.state.Codigo,
                    IdMoneda: this.state.IdMoneda,
                    TipoCambio: this.state.TipoCambio,
                    TasaIGV: this.state.TasaIGV,
                    TasaICBPER: this.state.TasaICBPER,
                    Descuento: this.state.Descuento,
                    Total: this.state.totalMonto,
                    IdModalidadPago: this.state.IdModalidadPago,
                    IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                    Alias: this.state.Alias
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            }).then(res => res.json())
                .then(data => {
                    window.localStorage.setItem('idPreVale', data)
                    this.setState({
                        newPreProcesarValeId: data
                    })
                })
                .catch(err => console.log(err));

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    getRUSS = async () => {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/getEmpresaByUser`);
            const data = await response.json();
            // console.log(data[0].EsRuss)
            this.setState({RUSS: data[0].EsRuss})
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    async getAlmacenes() {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch("/api/almacenes/AlmacenBySucursal");
            const data = await response.json();
            this.setState({
                loading: false,
                almacenes: data,
                IdAlmacen: data.results[0].IdAlmacen
            });
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    getBotonTipoComprobante = (IdTipoDocumentoSunat, NombreDocSunat) => {
        /********LOS BOTONES DE BOLETA Y FACTURA SE MUESTRAN NORMAL**** */
        if (IdTipoDocumentoSunat !== 2) {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        className={
                            this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                ? "preventa__tipo--comprobante--item--active"
                                : "preventa__tipo--comprobante--item"
                        }
                        value={this.state.IdTipoDocumentoSunat}
                        onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                    >
                        {NombreDocSunat}
                    </button>
                </React.Fragment>
            )
        } else {

            if (this.state.RUSS === "Inactivo") {
                return (
                    <React.Fragment>
                        <button
                            type="button"
                            className={
                                this.state.IdTipoDocumentoSunat === IdTipoDocumentoSunat
                                    ? "preventa__tipo--comprobante--item--active"
                                    : "preventa__tipo--comprobante--item"
                            }
                            value={this.state.IdTipoDocumentoSunat}
                            onClick={() => this.handleTipoComprobante(IdTipoDocumentoSunat)}
                        >
                            {NombreDocSunat}
                        </button>
                    </React.Fragment>
                )
            }
        }
    }

    InputPrecioVentaChange(event, item) {
        this.precioVentaChange(event.target.value, item)
    }

    precioVentaChange(val, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = val;
        const itemcalculo = this.getItemCalculo(item);

        listaItems[key].oldPrecios.push(listaItems[key].PrecioVenta);
        listaItems[key].PrecioVenta = value
        listaItems[key].ValorUnitario = setValorUnitario(itemcalculo)
        listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento
        this.setState({detalles: listaItems})
    }

    getItemCalculo(item) {
        return {
            "PrecioVenta": item.PrecioVenta,
            "Tributos": item.Tributos,
            "TasaISC": item.TasaISC,
            "Cantidad": item.Cantidad,
            "Gratuito": item.Gratuito,
            "Descuento": item.Descuento
        }
    }

    montoTotalDetalle(items) {
        const itemsTmp = [...items];

        const totales = calcularTotales(itemsTmp);

        console.log("totales ", totales)

        this.setState({
            Gravado: decimalAdjust('floor', totales.gravados, -2),
            Inafecto: decimalAdjust('floor', totales.inafectos, -2),
            Exonerado: decimalAdjust('floor', totales.exonerados, -2),
            IGVPreventa: decimalAdjust('floor', totales.gravados * 0.18, -2),
            Gratuitas: decimalAdjust('floor', totales.gratuitos, -2),
            totalMonto: totales.totalRedondeado,
            ICBPERPreventa: decimalAdjust('floor', totales.icbper, -2),
            redondeo: totales.redondeo
        })
    }

    async ActualizarPreProcesarValesMontoInDB() {
        try {
            let res = await fetch(`/api/vales/PreProcesarValesUpdateMonto/${this.state.newPreProcesarValeId}`, {
                method: "PUT",
                body: JSON.stringify({
                    Total: this.state.totalMonto
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtroPreVale(this.state.newPreProcesarValeId)
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtroPreVale(this.state.newPreProcesarValeId);
            }

        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    BotonTipoLista = (tipoLista, NombreTipoLista) => {
        return (
            <React.Fragment>
                <button
                    type="button"
                    className={
                        this.state.tipoLista === tipoLista
                            ? "preventa__tipo--comprobante--item--active"
                            : "preventa__tipo--comprobante--item"
                    }
                    value={this.state.tipoLista}
                    onClick={() => this.handleTipoLista(tipoLista)}
                >
                    {NombreTipoLista}
                </button>
            </React.Fragment>
        )
    }

    async AddProductoInList(item) {
        this.setState({load: true});
        $("#InputBuscarProd").focus();
        let detalles = [...this.state.detalles];
        let tmpDetails = [];
        let itemFounded = detalles.find(i => (i.IdPresentacion === item.IdPresentacion));
        const _stock = await this.getStock(item, itemFounded ? 1 : 1);
        const Stock = _stock.stock

        if (this.state.VenderConStock && Stock < 0) {
            notificationStock0(item)
            this.setState({load: false});
            return;
        }

        if (itemFounded) {
            itemFounded.Cantidad++;
            itemFounded.oldCants.push(itemFounded.Cantidad)
            FindPrecioPorMayor([], itemFounded)
            itemFounded.Total = calcTotal(itemFounded);
        } else {
            tmpDetails = detalles.map(dp => ({
                ...dp,
                Cantidad: dp.Cantidad,
                PrecioVenta: dp.PrecioVenta,
                Total: dp.Total,
                IdDetallePreventa: dp.IdDetallePreventa,
                Tributos: dp.Tributos,
                PrecioEspecial: FindPrecioEspecial([], dp),
                PrecioFamiliar: FindPrecioFamiliar([], dp),
                PrecioCosto: GetPrecioCosto([], dp),
                PrecioMenor: FindPrecioMenor([], dp),
                precioMayor: getPrecioPorMayor([], dp),
                checked: _.isNumber(dp.checked) ? dp.checked : 0,
                oldPrecios: dp.oldPrecios,
                initialAfectGrat: item.IdAfectacionIgv,
                oldCants: dp.oldCants,
            }))
            FindPrecioPorMayor([], item)
            tmpDetails.push({
                ...item,
                Cantidad: 0,
                PrecioVenta: item.PrecioVenta,
                Total: 0,
                IdDetallePreventa: "",
                Tributos: item.Tributos,
                PrecioEspecial: FindPrecioEspecial([], item),
                PrecioFamiliar: FindPrecioFamiliar([], item),
                PrecioCosto: GetPrecioCosto([], item),
                PrecioMenor: FindPrecioMenor([], item),
                precioMayor: getPrecioPorMayor([], item),
                checked: _.isNumber(item.checked) ? item.checked : 0,
                oldPrecios: [item.PrecioVenta],
                oldCants: [0],
                initialAfectGrat: item.IdAfectacionIgv
            })
        }

        let dets = itemFounded ? detalles : tmpDetails;
        let targetItem = itemFounded ? itemFounded : tmpDetails[tmpDetails.length - 1];

        if (itemFounded)
            await this.ActualizarStockValePreProcesarVale(targetItem, -1)
        else
            await this.AddDetallePreProcesarVales(targetItem)

        await this.ActualizarPreProcesarValesMontoInDB();
        await this.ActualizarDetallePreProcesarVales(targetItem)
        this.setState({detalles: dets})
        this.montoTotalDetalle(dets)
        this.getProductosPorProcesar()
        this.setState({load: false, buscar: ''});
    }

    async getStock(item, variacion) {
        const query = `idPres=${item.IdPresentacion}&idTipoStock=${item.IdTipoStock}&variacion=${variacion}`
        let stockRes = await fetch(`/api/gestionPreProcesarVales/check-stock?${query}`)
        return await stockRes.json();
    }

    async ActualizarStockValePreProcesarVale(item, variacionStock, setAll = false) {
        let stock = item.stocks.find(s => s.IdTipoStock === TIPO_STOCK.STOCK_COMPROMETIDO_VALE);
        try {
            let res = await fetch(`/api/vales/editStockValePreProcesarVales/${stock.IdStock}?idPreVale=${this.state.newPreProcesarValeId}&${setAll ? "setAll=1" : ""}`, {
                method: "PUT",
                body: JSON.stringify({
                    variacionStock: variacionStock * item.TipoCambio
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtroPreVale(this.state.newPreProcesarValeId);
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtroPreVale(this.state.newPreProcesarValeId);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    async generarOtroPreVale(idPreVale) {
        const generarOtra = await swal({
            title: "Ooops!",
            text: "Al parecer se ha eliminado el pre vale, ¿Desea generar una con los mismo datos?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false
        });
        window.localStorage.removeItem('idPreVale');
        if (generarOtra)
            await this.clonePreVale(idPreVale);
        else
            window.location.reload()
    }

    async clonePreVale(idPreVale) {
        this.setState({load: true});
        let res = await fetch(`/api/vales/clone-pre-vale/?idPreVale=${idPreVale}`);
        let json = await res.json();
        if (json.idPreVale)
            window.localStorage.setItem('idPreVale', json.idPreVale);
        window.location.reload();
    }

    async ActualizarDetallePreProcesarVales(item) {
        try {
            let res = await fetch(`/api/vales/editDet/${item.IdDetallePreProcesarVales}`, {
                method: "PUT",
                body: JSON.stringify({
                    IdPreProcesarVale: this.state.newPreProcesarValeId,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    PrecioVenta: item.PrecioVenta,
                    Precio: item.Precio,
                    ValorUnitario: item.ValorUnitario,
                    Descuento: item.Descuento,
                    Gratuito: item.Gratuito,
                    IdAfectacionIgv: item.IdAfectacionIgv
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });

            if (res.status >= 400)
                await this.generarOtroPreVale(this.state.newPreProcesarValeId);
            else {
                let json = await res.json();
                if (!json.status) await this.generarOtroPreVale(this.state.newPreProcesarValeId);
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    async AddDetallePreProcesarVales(item) {
        this.setState({loading: true, error: null});
        try {
            let res = await fetch("/api/vales/addDetalle", {
                method: "POST",
                body: JSON.stringify({
                    IdPreProcesarVale: this.state.newPreProcesarValeId,
                    Cantidad: item.Cantidad,
                    Total: item.Total,
                    IdStock: item.IdStock,
                    IdPresentacion: item.IdPresentacion,
                    IdAfectacionIgv: item.IdAfectacionIgv,
                    Descuento: 0,
                    PrecioReferencial: item.Precio,
                    PrecioVenta: item.PrecioVenta,
                    TipoCambio: item.TipoCambio,
                    IdAlmacen: item.IdAlmacen,
                    ValorUnitario: item.ValorUnitario
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })
            if (res.status >= 400)
                await this.generarOtraVenta(this.state.newPreProcesarValeId);
            else {
                let json = await res.json();
                if (json.status) {
                    item.IdDetallePreProcesarVales = json.respuesta;
                    let detsItem = [...this.state.detalles];
                    let index = detsItem.indexOf(detsItem);
                    detsItem[index] = item
                    this.setState({detalles: detsItem})
                    await this.ActualizarStockValePreProcesarVale(item, 0)
                } else
                    await this.generarOtraVenta(this.state.newPreProcesarValeId);
            }
        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async RemoveDetallePreProcesarVale(item) {
        this.setState({loading: true, error: null});

        try {
            let res = await fetch(`/api/vales/deleteDetPreProcesarVale/${item.IdDetallePreProcesarVales}?idPreVale=${this.state.newPreProcesarValeId}`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            })

            if (res.status >= 400)
                await this.generarOtroPreVale(this.state.newPreProcesarValeId)
            else {
                let json = await res.json();
                if (!json.status)
                    await this.generarOtroPreVale(this.state.newPreProcesarValeId)
            }

        } catch (error) {
            this.setState({
                loading: false,
                error: error
            });
        }
    }

    async RemoveProductoInList(item) {
        this.setState({load: true});
        await this.removeDetVenta(item)
        this.getProductosPorProcesar(this.state.buscar);
        this.setState({load: false});
    }

    async removeDetVenta(item) {
        let detsVenta = [...this.state.detalles];
        detsVenta.splice(detsVenta.indexOf(item), 1)
        this.setState({detalles: detsVenta})

        this.montoTotalDetalle(detsVenta)
        await this.ActualizarPreProcesarValesMontoInDB();
        await this.RemoveDetallePreProcesarVale(item)
        await this.ActualizarStockValePreProcesarVale(item, item.Cantidad)
    }

    async AgregarCantidadDet(item) {

        this.setState({load: true});
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const _stock = await this.getStock(items[i], 1);
        const Stock = _stock.stock;

        if (this.state.VenderConStock && Stock < 0) {
            notificationStock0(item)
            this.setState({load: false});
            return;
        }

        items[i].Cantidad++
        items[i].oldCants.push(items[i].Cantidad)
        FindPrecioPorMayor([], items[i])


        items[i].Total = calcTotal(items[i]);
        this.setState({detalles: items})
        this.montoTotalDetalle(items)
        await this.ActualizarPreProcesarValesMontoInDB();
        await this.ActualizarDetallePreProcesarVales(item)
        await this.ActualizarStockValePreProcesarVale(item, -1)

        this.getProductosPorProcesar(this.state.buscar);
        this.setState({load: false});
    }

    async RestarCantidadDet(item) {
        this.setState({load: true});

        const items = [...this.state.detalles]
        const i = items.indexOf(item)

        if (items[i].Cantidad > 1) {
            items[i].Cantidad--
            items[i].oldCants.push(items[i].Cantidad)
            items[i].Total = calcTotal(items[i])

            this.setState({detalles: items})
            this.montoTotalDetalle(items)
            await this.ActualizarPreProcesarValesMontoInDB();
            await this.ActualizarDetallePreProcesarVales(item)
            await this.ActualizarStockValePreProcesarVale(item, 1)

            this.getProductosPorProcesar(this.state.buscar);
        }
        this.setState({load: false});
    }

    InputCantidadDetalleChange(event, item) {
        const items = [...this.state.detalles]
        const i = items.indexOf(item)
        const {value} = event.target;
        if (value >= 0) {
            items[i].Cantidad = items[i].IdUnidad === 58 || items[i].IdUnidad === 59 ? parseInt(value) : value;
            FindPrecioPorMayor([], items[i])

            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            this.setState({detalles: items});
            this.montoTotalDetalle(items);
        } else
            notificationCantidadNegativa()
    }

    handleChange(e) {
        this.setState({buscar: e.target.value});
    }

    async getProductosPorProcesar(param = '') {
        this.setState({loading: true, error: null});
        try {
            const response = await fetch(`/api/vales/productosVale?search=${param}`);
            const data = await response.json();
            this.setState({
                loading: false,
                dataProductos: data
            });
            await this.TributosInProductos(data.productosVale)
        } catch (error) {
            this.setState({loading: false, error: error});
        }
    }

    TributosInProductos = async (productos) => {
        for (let i = 0; i < productos.length; i++) {
            const itemCalculado = setPreciosYDescuento(productos[i])
            productos[i].Precio = itemCalculado.PrecioVenta
            productos[i].Gratuito = parseInt(productos[i].Gratuito)
        }
        this.setState(this.state.dataProductos)
    }

    DocChange = (e) => {

        this.setState({
            Documento: e.target.value
        }, () => {
            if (this.state.Documento.length == 8 ||
                this.state.Documento.length == 11 ||
                this.state.Documento.length == 9) {
                this.TraerClientePorDoc(this.state.Documento)
                if (this.state.Documento.length == 8 ||
                    this.state.Documento.length == 9) {
                    this.handleTipoComprobante(4)
                }
            }
        });
    }

    handleTipoComprobante(IdTipoDocumentoSunat) {
        switch (IdTipoDocumentoSunat) {
            case 2:
                if (this.state.Documento.length === 11 && this.state.IdCliente != 0) {
                    this.setState({
                        IdTipoDocumentoSunat: IdTipoDocumentoSunat,
                        // filtroTipoStock:1,
                        // checkfiltroTipoStock:true
                    });
                } else {
                    notificationFacturaRUC()
                    this.setState({
                        ClickPagar: true
                    })
                }
                break;
            case 4:
                this.setState({
                    IdTipoDocumentoSunat: IdTipoDocumentoSunat,
                    // filtroTipoStock:1,
                    // checkfiltroTipoStock:true
                });
                break;
            default:
                this.setState({
                    IdTipoDocumentoSunat: IdTipoDocumentoSunat
                });
                break;
        }
    }

    TraerClientePorDoc = async (DNI) => {
        try {
            await fetch(`/api/preVentas/traerCliente/${DNI}`)
                .then(res => res.json())
                .then(async data => {

                    this.setState({
                        loading: false,
                        dataCliente: data[0] ? data[0] : 0,
                        IdCliente: data[0] ? data[0].IdCliente : 0,
                        StockPorCliente: []
                    });
                    if (data[0]) {
                        if (this.state.RUSS === "Inactivo") {
                            if (this.state.IdTipoDocumentoSunat !== VALE) {
                                if (data[0].IdTipoDocumento === 1 || data[0].IdTipoDocumento === 7) {
                                    this.setState({
                                        IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                } else {
                                    this.setState({
                                        IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                                        filtroTipoStock: 1,
                                        checkfiltroTipoStock: true
                                    })
                                }
                            }
                        }
                    }
                });

        } catch (error) {
            this.setState({
                loading: false,
                error: error,
            });
        }
    }

    AliasChange = e => {
        this.setState({
            Alias: e.target.value
        });
    }

    handleCloseModal = () => {
        this.setState({modalClienteIsOpen: false})
    }

    StockDisponible = producto => {
        // console.log(producto.Stock)
        const listaProducto = this.state.dataProductos.productosVale
        const findStockVale = listaProducto.find(findItem => {
            return (findItem.IdProducto == producto.IdProducto &&
                findItem.IdPresentacion == producto.IdPresentacion
                && findItem.IdAlmacen == producto.IdAlmacen)
        })
        return ((findStockVale.Stock / producto.TipoCambio))
    }

    async handleGenerarPreProcesarVales(IdPreProcesarVale) {
        this.setState({
            ClickPagar: false
        })
        if (this.state.BoletaRuc === 1) {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                const willGenerar = await swal({
                    title: "¿Está seguro?",
                    text: "¿Generar una Boleta con RUC?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                    closeOnClickOutside: false
                });
                if (willGenerar) {
                    this.GenerarPreProcesarVales(IdPreProcesarVale)
                } else {
                    this.setState({
                        ClickPagar: true
                    })
                }
            } else {
                this.GenerarPreProcesarVales(IdPreProcesarVale)

            }
        } else {
            if (this.state.dataCliente.IdTipoDocumento === RUC && this.state.IdTipoDocumentoSunat === BOLETA) {
                notificationConfigBoletaRUC()
                this.setState({
                    ClickPagar: true
                })
            } else {
                this.GenerarPreProcesarVales(IdPreProcesarVale)
            }
        }

    }

    GenerarPreProcesarVales = async (IdPreProcesarVale) => {
        this.setState({loading: true, error: null});
        if (this.state.detalles.length === 0) {
            notificationPreProcesarValeVacia()
            this.setState({
                ClickPagar: true
            })
        } else {
            if (this.state.IdCliente !== 0) {
                if (this.state.dataCliente.NroTipoDocumento === "99999999"
                    && this.state.IdTipoDocumentoSunat === BOLETA && this.state.totalMonto >= 700) {
                    notificationBoletaVarios()
                    this.setState({
                        ClickPagar: true
                    })
                } else {
                    this.doProcess(IdPreProcesarVale)
                }
            } else {
                notificationClienteNoExiste()
                this.setState({
                    ClickPagar: true
                })
            }
        }
    }

    async doProcess(idPreVale) {
        let tieneDetallesConCantidadCero = this.tieneDetallesConCantidadCero(this.state.detalles);
        let estadoVenta = await this.estadoPreVale(idPreVale);
        if (tieneDetallesConCantidadCero) {
            swal({
                title: "Alerta de venta",
                text: "Tiene ventas con cantidad 0",
                icon: "warning",
                buttons: "Ok"
            });
        } else if (estadoVenta === "CANCELADA")
            await this.generarOtroPreVale(idPreVale);
        else {
            try {
                let res = await fetch(`/api/vales/GenerarPreProcesarVale/${idPreVale}`, {
                    method: "PUT",
                    body: JSON.stringify({
                        Estado: "GENERADO",
                        IdCliente: this.state.IdCliente,
                        Total: this.state.totalMonto,
                        Alias: this.state.Alias,
                        IdTipoDocumentoSunat: this.state.IdTipoDocumentoSunat,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json"
                    }
                })
                if (res.ok) {
                    this.setState({Estado: "GENERADO"})
                    let respuesta = await res.json();
                    let ventacalculada = this.calcularVenta(respuesta)
                    await this.registrarVenta(ventacalculada)
                }

            } catch (error) {
                this.setState({
                    loading: false,
                    error: error
                });
            }
        }
    }

    async registrarVenta(ventaCalculada) {
        let dets = [...this.state.detalles];
        let totales = calcularTotales(dets);
        ventaCalculada['Items'] = dets

        await fetch("/api/ventas/new", {
            method: "POST",
            body: JSON.stringify({
                IdCliente: this.state.IdCliente,
                IdTipoOperacion: this.state.newPreProcesarValeId,
                TipoTabla: "PreProcesarVales",
                Estado: this.state.Estado,
                IdModalidadPago: CONTADO,
                IdTipoComprobante: this.state.IdTipoDocumentoSunat,
                IdMoneda: this.state.IdMoneda,
                TipoCambio: this.state.TipoCambio,
                TasaIGV: this.state.TasaIGV,
                TasaICBPER: this.state.TasaICBPER,
                DescuentoTotal: this.state.DescuentoTotal,
                Gravadas: totales.gravados,
                Exoneradas: totales.exonerados,
                Inafectas: totales.inafectos,
                ICBPER: totales.icbper,
                Exportacion: 0.0,
                Total: totales.total,
                Vuelto: 0.0,
                Observacion: "",
                IGV: totales.gravados * 0.18,
                ISC: ventaCalculada.ISC,
                Gratuitas: totales.gratuitos,
                PorcentajeDescuento: ventaCalculada.PorcentajeDescuento,
                IVAP: ventaCalculada.IVAP,
                TasaIVAP: ventaCalculada.TasaIVAP,
                TasaISC: ventaCalculada.ISC,
                Redondeo: totales.redondeo
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(async responseData => {
                this.setState({isLoading: false})
                const [[table_data], [header]] = responseData;
                let error = false;
                if (header._estado) error = header._estado;
                else if (header._estadoRegistroVentas !== true)
                    error = header._estadoRegistroVentas;
                if (error) {
                    swal("ERROR", error, "error");
                } else {
                    const [{IdRegistroVenta}] = table_data;

                    this.setState({IdRegistroVenta: IdRegistroVenta});

                    await this.saveDetalleVenta(ventaCalculada, IdRegistroVenta);

                    let [Venta, DetalleVenta] = await Promise.all([
                        this.getVenta(IdRegistroVenta),
                        this.getDetalleVenta(IdRegistroVenta),

                    ]);
                    await this.handleEnviarVenta(IdRegistroVenta)
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    async estadoPreVale(idPreVale) {
        let res = await fetch(`/api/vales/status-pre-vale?idPreVale=${idPreVale}`);
        if (res.status === 404) {
            window.localStorage.removeItem('idPreVale');
            window.location.reload();
        }
        let json = await res.json();
        return json.Estado;
    }

    tieneDetallesConCantidadCero(dets) {
        return dets.filter(d => Number(d.Cantidad) === 0 || Number(d.Total) === 0).length;
    }

    async handleEnviarVenta(IdRegistroVentas) {
        await this.sendxml(IdRegistroVentas);

    }

    async sendxml(IdRegistroVentas) {
        // console.log(IdRegistroVentas)
        try {
            const response = await fetch(`/api/configFactElectronica/ce/${IdRegistroVentas}`);
            const xmlresponse = await response.json();
//  console.log(xmlresponse)
            this.setState({xmlresponse: xmlresponse.respuesta, isLoading: false});
            return xmlresponse;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getVenta(IdRegistroVenta) {
        try {
            const response = await fetch(`/api/ventas/get/${IdRegistroVenta}`);
            const Venta = await response.json();
            this.setState({
                Venta: Venta.respuesta[0],
                isLoading: false,
            });
            // Notify("¡CORRECTO! Venta Registrada");
            swal({
                title: '¡CORRECTO!',
                text: `Venta Registrada. ¿Desea imprimir el boucher?`,
                icon: 'success',
                buttons: [
                    'No',
                    'Si'
                ],
            }).then((isConfirm) => {
                window.localStorage.removeItem('idPreVale')
                if (isConfirm) {
                    this.setState({
                        statusModalImprimirReporte: true,
                    })
                    return Venta.respuesta[0];
                } else {
                    this.props.history.push(`/registrosventas`)
                }
            })

        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async getDetalleVenta(IdRegistroVenta) {
        try {
            const response = await fetch(`/api/ventas/detalles/${IdRegistroVenta}`);
            const DetallesVenta = await response.json();
            this.setState({
                DetallesVenta: DetallesVenta.respuesta,
                isLoading: false,
            });
            return DetallesVenta.respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    async saveDetalleVenta(ventaCalculada, IdRegistroVenta) {
        const Items = ventaCalculada.Items.map(detalle => {
            return [
                IdRegistroVenta,
                detalle.IdStock,
                detalle.Cantidad,
                detalle.PrecioReferencial,
                detalle.PrecioVenta,
                detalle.IdAfectacionIgv,
                detalle.IdPresentacion,
                detalle.Total,
                detalle.Descuento,
                detalle.TipoCambio,
                detalle.ISC,
                detalle.IGV,
                detalle.ICBPER,
                detalle.IVAP,
                detalle.TasaISC,
                detalle.ValorUnitario,
                detalle.DescuentoSI,
                detalle.Gratuito
            ];
        });

        try {
            const response = await fetch(`/api/ventas/detalles/new`, {
                method: "POST",
                body: JSON.stringify({
                    Items
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                }
            });
            const respuesta = await response.json();
            this.setState({
                respuestaDetallesVenta: respuesta.respuesta,
                isLoading: false
            });
            return respuesta;
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    calcularVenta = async (respuesta) => {
        const TasaIGV = respuesta.respuesta[0].TasaIGV;
        const TasaICBPER = respuesta.respuesta[0].TasaICBPER;
        const TasaIVAP = IVAP;
        const Descuento = this.state.DescuentoTotal;

        const datosParaCalculo = {
            Descuento,
            TasaIGV,
            TasaICBPER,
            TasaIVAP,
            Items: this.state.dataProductosMostrar.productosVale.map(detalle => {
                return {
                    ValorUnitario: detalle.ValorUnitario,
                    Cantidad: detalle.Cantidad,
                    Descuento: detalle.Descuento,
                    Tributos: detalle.Tributos,
                    TasaISC: detalle.TasaISC,
                    IdStock: detalle.IdStock,
                    IdAfectacionIgv: detalle.IdAfectacionIgv,
                    IdPresentacion: detalle.IdPresentacion,
                    TipoCambio: detalle.TipoCambio,
                    Gratuito: detalle.Gratuito
                }
            })
        }
        return calcularVenta(datosParaCalculo);
    }

    setActiveInputDoc = () => {

    }

    handleObservaciones = (e) => {
        this.setState({
            Observaciones: e.target.value
        });
    }

    async handleOpenModalDescuento(item) {
        this.setState({
            load: true,
            ItemDescuento: item
        })

        await this.setAfectsIgv(item.IdGrupoClasificacionIgv)

        this.setState({
            load: false,
            statusModalDescuento: true,
        })
    }

    async setAfectsIgv(idAfect) {
        let res = await fetch(`/api/preVentas/free-afects?idGrupoIgv=${idAfect}`)
        if (res.ok)
            this.setState({afectsIgv: await res.json()})
    }

    handleOnCloseModalDescuento = () => {
        this.setState({
            statusModalDescuento: false
        })
    }

    handleClickNuevo = () => {
        this.setState({
            modalClienteIsOpen: true,
            itemcliente: {
                ...this.state.itemcliente,
                idcliente: null,
            }
        })

    }

    handleClickBuscar = () => {
        this.setState({
            statusModalSearchCliente: true
        })
    }

    GetCliente = (cliente) => {
        this.handleCloseBuscar()
        this.setState({
            Documento: cliente.NroTipoDocumento
        })
        this.TraerClientePorDoc(cliente.NroTipoDocumento)
    }

    handleCloseBuscar = () => {
        this.setState({
            statusModalSearchCliente: false
        })
    }

    handleCloseImprimirReporte = () => {
        this.setState({
            statusModalImprimirReporte: false
        })
        this.props.history.push(`/registrosventas`)
    }

    async handleSavePDF() {
        await printA4(this.state.DetallesVenta, this.state.Venta, this.state.extImg).then(Notify("Abriendo... espere por favor.", "success", 1500)).then(this.handleCloseImprimirReporte())

    }

    async handlePrintTicket() {
        await printTicket(this.state.DetallesVenta, this.state.Venta, this.state.extImg).then(Notify("Abriendo... espere por favor.", "success", 1500)).then(this.handleCloseImprimirReporte())
    }

    async handleEnterKeyUp(e) {
        if (e.key === "Enter") {
            await this.getProductosPorProcesar(this.state.buscar);
            const productos = this.state.dataProductos.productos;
            if (productos.length === 1) {
                this.AddProductoInList(productos[0])
                this.setState({buscar: "",})
            } else {
                this.setState({buscar: ""})
                notificationNoHayProducto()
            }
            this.getProductosPorProcesar(this.state.buscar);
        } else {
            this.getProductosPorProcesar(this.state.buscar);
        }
    }

    onSecretKeyPress(event, detItem) {
        if (event.ctrlKey && event.shiftKey && event.which === NUMBER_KEYS.THREE_NUMBER_KEY)
            this.findPrecioUnitario(detItem, detItem.precioMayor.Precio, "Mayor")
    }

    PrecioChange(e, item) {
        this.findPrecioUnitario(item, e.target.value, e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)
    }

    async findPrecioUnitario(item, value, precioSelectedText) {
        this.setState({load: true});
        await this.selectPrecioChange(item, value, precioSelectedText)
        this.setState({load: false});
    }

    async selectPrecioChange(item, value, precioSelectedText) {
        let listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        let newItem = listaItems[key];

        const precio = item.precios.find(p => p.NombrePrecio.includes(precioSelectedText));

        newItem.Precio = precio ? precio.Precio : 0
        newItem.PrecioVenta = precio ? precio.Precio : 0
        newItem.ValorUnitario = precio ? precio.ValorUnitario : 0;
        newItem.Total = calcTotal({Cantidad: listaItems[key].Cantidad, PrecioVenta: newItem.PrecioVenta})
        newItem.PrecioReferencial = parseFloat(newItem.PrecioVenta)

        listaItems[key] = newItem;

        this.setState({
            detalles: listaItems
        })

        await this.montoTotalDetalle(listaItems)
        await this.ActualizarPreProcesarValesMontoInDB();
        await this.ActualizarDetallePreProcesarVales(listaItems[key])
        this.getProductosPorProcesar(this.state.buscar);
    }

    async onBlurCantidad(e, item) {
        this.setState({load: true});
        let items = [...this.state.detalles]
        const i = items.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 1;
        let variacionStock = item.IdUnidad === 58 || item.IdUnidad === 59 ? parseInt(value) : value;
        const _stock = await this.getStock(item, variacionStock);
        const Stock = _stock.stock

        variacionStock = this.state.VenderConStock ? (Stock >= 0 ? variacionStock : 1) : variacionStock;

        if (Stock < 0 && this.state.VenderConStock) {
            notificationStock0(item)
            items[i].Cantidad = items[i].oldCants[items[i].oldCants.length - 1];
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
        } else {
            items[i].oldCants.push(variacionStock)
            items[i].Cantidad = variacionStock;
            items[i].Total = calcTotal(items[i]) - items[i].Descuento
            await this.ActualizarStockValePreProcesarVale(items[i], -Number(items[i].Cantidad))
            await this.ActualizarPreProcesarValesMontoInDB();
            await this.ActualizarDetallePreProcesarVales(items[i])
        }

        this.montoTotalDetalle(items)
        this.getProductosPorProcesar(this.state.buscar);
        this.setState({load: false});
    }

    async onBlurPrecioVenta(e, item) {
        const {value} = e.target;
        if (value < item.PrecioCosto) {
            new Noty({
                type: "warning",
                theme: "bootstrap-v4",
                layout: "topCenter",
                text: `El precio ingresado es menor al precio de costo`,
                timeout: 1500
            }).show()
            let precio = item.oldPrecios.reverse().find(g => Number(g) >= Number(item.PrecioCosto));
            this.precioVentaChange(precio || item.PrecioCosto, item)
        } else {
            this.setState({load: true});
            this.montoTotalDetalle(this.state.detalles)
            await this.ActualizarPreProcesarValesMontoInDB();
            await this.ActualizarDetallePreProcesarVales(item);
            this.getProductosPorProcesar(this.state.buscar);
            this.setState({load: false});
        }
    }

    async DescuentoChange(event, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const {value} = event.target;
        const variacionDescuento = value - listaItems[key].Descuento

        if (listaItems[key].Gratuito === 0) {
            if (value < 0 || value === "") {
                listaItems[key].Descuento = 0
                listaItems[key].Total = calcTotal(listaItems[key]) - listaItems[key].Descuento
                this.setState({detalles: listaItems})
                await this.montoTotalDetalle(listaItems)
            } else {
                if (variacionDescuento <= listaItems[key].Total) {
                    listaItems[key].Descuento = value
                    listaItems[key].Total = calcTotal(listaItems[key]) - value
                    this.setState({detalles: listaItems})
                    await this.montoTotalDetalle(listaItems)
                } else {
                    notificationDescuentoMayorTotal()
                }
            }
        } else {
            notificationDescuentoGratuito()
        }
    }

    async onBlurDescuento(e, item) {
        this.setState({load: true});
        const tipoStock = this.state.checkfiltroTipoStock ? TIPO_STOCK.CON_COMPROBANTE : TIPO_STOCK.SIN_COMPROBANTE;
        await this.montoTotalDetalle(this.state.detalles)
        await this.ActualizarPreProcesarValesMontoInDB();
        await this.ActualizarDetallePreProcesarVales(item)
        this.getProductosPorProcesar(tipoStock);
        this.setState({load: false});
    }

    async onChangeAfectGratuita(e, item) {
        this.updateGratuitoWrapper(e.target.value, item);
    }

    async updateGratuitoWrapper(value, item) {
        this.setState({load: true});

        let items = [...this.state.detalles];
        let i = items.indexOf(item);

        const checked = String(value) !== "-1";
        item.checked = checked
        item.Gratuito = checked ? 1 : 0

        if (checked) {
            item.Descuento = 0
            item.IdAfectacionIgv = value
        } else
            item.IdAfectacionIgv = item.initialAfectGrat

        item.Precio = item.PrecioVenta

        items[i] = item;
        this.setState({detalles: items})
        await this.ActualizarDetallePreProcesarVales(item)
        await this.montoTotalDetalle(items)
        await this.ActualizarPreProcesarValesMontoInDB();
        this.getProductosPorProcesar(this.state.buscar);
        this.setState({load: false});
    }

    handleKeyUp(event) {
        if (event.key === "Escape" || event.key === "Enter") {
            this.setState({
                statusModal: false,
                statusModalDescuento: false
            });
        }
    }


    onChangeTotal(e, item) {
        const listaItems = [...this.state.detalles]
        const key = listaItems.indexOf(item)
        const value = isNumber(e.target.value) ? e.target.value : 0;

        listaItems[key].Cantidad = value / listaItems[key].PrecioVenta
        listaItems[key].Total = value
        listaItems[key].Descuento = 0
        this.setState({detalles: listaItems})
    }

    async onBlurTotal(e, item) {
        this.setState({load: true});
        await this.montoTotalDetalle(this.state.detalles)
        await this.ActualizarPreProcesarValesMontoInDB();
        await this.ActualizarDetallePreProcesarVales(item);
        this.getProductosPorProcesar()
        this.setState({load: false});
    }

    render() {
        const productos = this.state.dataProductos.productosVale;

        if (this.state.error)
            return `Error: ${this.state.error.message}!!`;

        return (
            <React.Fragment>
                <div className="preventa__container">
                    <div className="preventa">

                        <div className="encabezado">

                            <div className="preventa__tipo--comprobante">
                                {this.getBotonTipoComprobante(FACTURA, "Factura")}
                                {this.getBotonTipoComprobante(BOLETA, "Boleta")}
                            </div>

                            <BuscarClientePorDoc
                                goBack={this.props.history.goBack}
                                DocChange={this.DocChange}
                                Documento={this.state.Documento}
                                setActiveInputDoc={this.setActiveInputDoc}
                                TraerClientePorDoc={this.TraerClientePorDoc}
                                dataCliente={this.state.dataCliente}
                                handleClickNuevo={this.handleClickNuevo}
                                handleClickBuscar={this.handleClickBuscar}
                                handleCloseBuscar={this.handleCloseBuscar}
                                Alias={this.state.Alias}
                                AliasChange={this.AliasChange}
                            />

                            <div className="PreVBuscarProducto" style={{justifyContent: 'flex-start'}}>
                                <input type="text" placeholder="Buscar Producto"
                                       className="input__linea mr-2"
                                       value={this.state.buscar}
                                       onChange={this.handleChange}
                                       onKeyUp={this.handleEnterKeyUp}
                                       autoFocus/>
                            </div>
                            <div className="form-group encabezadoPV">
                                <ListaProductoPreVale
                                    load={this.state.load}
                                    productos={productos}
                                    checkedTipoStock={false}
                                    AddProductoInList={this.AddProductoInList}
                                />
                            </div>
                        </div>

                        <div className="preventa__container_data" style={{marginTop: '15px'}}>

                            <div className="preventa__cliente2">
                                {
                                    (this.state.dataCliente.IdTipoDocumento) ?
                                        (
                                            (this.state.dataCliente.IdTipoDocumento === 1 || this.state.dataCliente.IdTipoDocumento === 7) ?
                                                this.state.dataCliente.ApellidoPaterno
                                                    .concat(` ${this.state.dataCliente.ApellidoMaterno} ${this.state.dataCliente.PrimerNombre}`)
                                                : this.state.dataCliente.RazonSocial
                                        )
                                        : "CLIENTE NO REGISTRADO"
                                }
                            </div>

                            <DetallesProductoPreventa
                                load={this.state.load}
                                conStockChecked={false}
                                detalles={this.state.detalles}
                                onSecretKeyPress={this.onSecretKeyPress}
                                venderConListaPrecios={true}
                                PrecioChange={this.PrecioChange}
                                InputCantidadDetalleChange={this.InputCantidadDetalleChange}
                                InputPrecioVentaChange={this.InputPrecioVentaChange}
                                RestarCantidadDet={this.RestarCantidadDet}
                                AgregarCantidadDet={this.AgregarCantidadDet}
                                RemoveProductoInList={this.RemoveProductoInList}
                                handleOpenModalDescuento={this.handleOpenModalDescuento}
                                onBlurInputCantidad={this.onBlurCantidad}
                                onBlurPrecioVenta={this.onBlurPrecioVenta}
                                canUpdatePrice={this.state.canUpdatePrice}
                                canUpdateTotal={this.state.canUpdateTotal}
                                noneBg={true}
                                onChangeTotal={this.onChangeTotal}
                                onBlurTotal={this.onBlurTotal}
                            />


                        </div>

                        {<ModalCliente isOpen={this.state.modalClienteIsOpen} onClose={this.handleCloseModal}
                                       title="NUEVO CLIENTE">
                            <ClienteNuevo onClose={this.handleCloseModal}
                                          NumeroDocumento={this.state.Documento}
                                          AsignarValorDoc={this.AsignarValorDoc}
                            />
                        </ModalCliente>}

                        {<Modal
                            isOpen={this.state.statusModalDescuento}
                            onClose={this.handleOnCloseModalDescuento}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <PreventaModalDescuentos
                                load={this.state.load}
                                ItemDescuento={this.state.ItemDescuento}
                                DescuentoChange={this.DescuentoChange}
                                onBlurDescuento={this.onBlurDescuento}
                                afectsIgv={this.state.afectsIgv}
                                onChangeCheckBoxGratuito={this.onChangeAfectGratuita}
                            />
                        </Modal>}
                        {<Modal
                            isOpen={this.state.statusModalSearchCliente}
                            onClose={this.handleCloseBuscar}
                            handleKeyUp={this.handleKeyUp}
                        >
                            <ModalBuscarCliente
                                GetCliente={this.GetCliente}
                            />
                        </Modal>}

                        {<Modal
                            isOpen={this.state.statusModalImprimirReporte}
                            onClose={this.handleCloseImprimirReporte}
                            handleKeyUp={this.handleKeyUp}
                            title="Opciones de impresión"
                        >
                            <ModalImprimirReporte
                                state={this.state}
                                handleSavePDF={this.handleSavePDF}
                                handlePrintTicket={this.handlePrintTicket}
                            />
                        </Modal>}

                        {
                            this.state.datosProdsExcel.length ? (
                                <div>
                                    <ExcelFile
                                        element={
                                            <button className="boton__verdeOscuro">
                                                <i className="fas fa-file-excel"></i>
                                            </button>
                                        }
                                        filename="ProductosProcesar"
                                    >
                                        <ExcelSheet
                                            dataSet={this.state.datosProdsExcel}
                                            name="Productos"
                                        />
                                    </ExcelFile>
                                </div>
                            ) : null
                        }


                    </div>
                    <div className="totals__container">
                        <div className="totals__container--item">
                            {parseFloat(this.state.Gravado) > 0 ?
                                <p>
                                    Gravado{" "}
                                    <strong>
                                        {this.state.Gravado}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Inafecto) > 0 ?
                                <p>
                                    Inafecto{" "}
                                    <strong>
                                        {this.state.Inafecto}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Exonerado) > 0 ?
                                <p>
                                    Exonerado{" "}
                                    <strong>
                                        {this.state.Exonerado}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.Gratuitas) > 0 ?
                                <p>
                                    Gratuitas{" "}
                                    <strong>
                                        {this.state.Gratuitas}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.ICBPERPreventa) > 0 ?
                                <p>
                                    ICBPER{" "}
                                    <strong>
                                        {this.state.ICBPERPreventa}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.IGVPreventa) > 0 ?
                                <p>
                                    IGV{" "}
                                    <strong>
                                        {this.state.IGVPreventa}
                                    </strong>
                                </p>
                                : null
                            }
                            {parseFloat(this.state.redondeo) > 0 ?
                                <p>
                                    Redondeo <strong>
                                    {decimalAdjust('floor', this.state.redondeo, -2)}
                                </strong>
                                </p>
                                : null
                            }
                        </div>
                        <div className="totals__container--item-total">
                               <span>{this.state.totalMonto}
                            </span>
                        </div>
                        <div className="totals__container--item">
                            {this.state.error ? null : (
                                <button
                                    className="generar__boton btn btn-success"
                                    type="submit"
                                    onClick={() => this.handleGenerarPreProcesarVales(this.state.newPreProcesarValeId)}
                                    hidden={this.state.totalMonto <= 0}
                                >
                                    Procesar {this.state.error}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProcesarVales;
