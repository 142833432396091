import React from 'react';
import './styles/tabla.css';
import PageLoading from './PageLoading.js';
import EmpresasFuncionalidadTabla from './empresasComponentes/EmpresasFuncionalidadTabla.js';

class EmpresasConfig extends React.Component{

    constructor(){
        super()
        this.state={
            IdEmpresa: '',
            buscar:'',
            selected: {},
            
            detalleConfiguraciones: {
                detalleConfiguraciones:[]
            },

            preciosEmpresaConfig:{
                preciosEmpresaConfig:[]
            },

            clasificaciones: {
                clasificaciones:[]
            },

            clasificacion:1,
            configFiltrada:[],
            loading: true,
            error:null
        }
        this.toggleRow = this.toggleRow.bind(this);

        this.obtenerDetalleConfiguraciones=this.obtenerDetalleConfiguraciones.bind(this)
        this.obtenerPreciosEmpresasConfig = this.obtenerPreciosEmpresasConfig.bind(this)
        this.obtenerClasificaciones=this.obtenerClasificaciones.bind(this)
        this.ClasificacionChange=this.ClasificacionChange.bind(this)
        this.filtroConfiguracionPorClasificacion = this.filtroConfiguracionPorClasificacion.bind(this)
        this.handleChangeCheckBox = this.handleChangeCheckBox.bind(this)
        this.updateDetalleConfigEmpresa = this.updateDetalleConfigEmpresa.bind(this)
        this.updatePrecioEmpresa = this.updatePrecioEmpresa.bind(this)
        
    }

    componentDidMount = async() =>{
        this.setState({
            IdEmpresa: this.props.ID,
            clasificacion:'- Seleccionar una clasificación -'
        })
        this.obtenerClasificaciones()
        this.obtenerDetalleConfiguraciones(this.props.ID)
        this.obtenerPreciosEmpresasConfig(this.props.ID)
        
    } 
    componentWillUnmount(){
        clearInterval(this.intervalo);
    }


toggleRow(firstName) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[firstName] = !this.state.selected[firstName];
		this.setState({
			selected: newSelected
		});
    }
    

    obtenerClasificaciones = async () =>{
        this.setState({loading:true, error:null,
            //  clasificacion: '- Seleccionar una clasificación -'
            })
        try {
            const response = await fetch('/api/clasificaciones/clasif');
            const data = await response.json();
            this.setState({
                loading: false,
                // clasificacion: 1,
                clasificaciones: data
            })
        }catch(error){
            this.setState({loading: false, error: error})
        }
        
    }

    obtenerDetalleConfiguraciones = async (IdEmpresa) =>{
        this.setState({loading:true, error:null, 
            // clasificacion: 1
        })
        try {
            const response = await fetch(`/api/detalleConfiguraciones/detConfig/${IdEmpresa}`);
            const data = await response.json();
            this.setState({
                loading: false,
                detalleConfiguraciones: data
            })
            
        }catch(error){
            this.setState({loading: false, error: error})
        }
        
    }

    obtenerPreciosEmpresasConfig = async (IdEmpresa) =>{
        this.setState({loading:true, error:null
            // , clasificacion: 1
        })
        try {
            const response = await fetch(`/api/detalleConfiguraciones/preciosEmpresaConfig/${IdEmpresa}`);
            const data = await response.json();
            this.setState({
                loading: false,
                preciosEmpresaConfig: data
            })
            
        }catch(error){
            this.setState({loading: false, error: error})
        }
        
    }

    ClasificacionChange = (e) => {
        this.setState({
            clasificacion: e.target.value,
            buscar:''
        });
        this.filtroConfiguracionPorClasificacion(e.target.value,'');
    }

    handleChangeCheckBox = (e)=>{
        console.log(e.target.name, " - ", e.target.checked)
        this.setState({
            [e.target.name]: e.target.checked
        });
        this.updateDetalleConfigEmpresa(e.target.value, e.target.checked)
    }

    handleChangeCheckBoxPrecio = (e) => {
        this.setState({
            [e.target.name]: e.target.checked
        });
        this.updatePrecioEmpresa(e.target.value, e.target.checked)
    }

    handleChangeList = (e)=>{
        this.setState({
            [e.target.name]: e.target.value
        });
        this.updateDetalleConfigEmpresa(e.target.id, e.target.value)
    }

    handleChange = async(e)=>{
        this.setState({
            buscar: e.target.value
        });
        await this.filtroConfiguracionPorClasificacion(this.state.clasificacion, e.target.value);
    }

    updateDetalleConfigEmpresa = async(IdDetalleConfigEmpresa, Estado) => {
        await fetch(`/api/detalleConfiguraciones/detConfig/edit/${IdDetalleConfigEmpresa}`,{
            method:'PUT',
            body: JSON.stringify({
              Estado:Estado
            }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            
        })
        .then(res => res.json())
        .then(data => this.obtenerDetalleConfiguraciones(this.state.IdEmpresa))
        .catch(err => console.log(err))
        //volver a mostrar la informacion
        this.obtenerDetalleConfiguraciones(this.state.IdEmpresa)
    }

    updatePrecioEmpresa = async(IdPrecioEmpresa, Estado) => {
        await fetch(`/api/detalleConfiguraciones/preciosEmpresaConfig/edit/${IdPrecioEmpresa}`,{
            method:'PUT',
            body: JSON.stringify({
              Estado:Estado
            }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
            
        })
        .then(res => res.json())
        .then(data => this.obtenerPreciosEmpresasConfig(this.state.IdEmpresa))
        .catch(err => console.log(err))
        //volver a mostrar la informacion
        this.obtenerPreciosEmpresasConfig(this.state.IdEmpresa)
    }


    filtroConfiguracionPorClasificacion = (IdClasificacion, buscar) => {
        if(IdClasificacion != 0){
            this.state.configFiltrada = this.state.detalleConfiguraciones.detalleConfiguraciones.filter((conf) => {
                return conf.IdClasificacionConfiguracion == IdClasificacion && conf.Configuracion.toUpperCase().includes(buscar.toUpperCase()) && conf.verConfig == true
            })
        } else if (IdClasificacion == 0){
            this.state.configFiltrada = this.state.preciosEmpresaConfig.preciosEmpresaConfig
        }
    }

render = () => {
        if(this.state.loading === true && this.state.detalleConfiguraciones === undefined){
            return <PageLoading/>;
        }


        // if(this.state.detalleConfiguraciones.detalleConfiguraciones.length === 0){
        //     return (
        //         <div>
        //             <h4>Cargando...</h4>
        //         </div>
        //     );
        // }else{
                        
            if(this.state.detalleConfiguraciones.error){
                return `Error: ${this.state.error.message}`;
            }
            
            return(
                <React.Fragment>
                    {/* <div className="card cardEmpresas bg-light "> */}
                    {/* <div className="ml-4 mt-4">Funcionalidad</div> */}
                    {/* <div className="card-body"> */}

                    {/* <div className="row col-12"> */}
                    <form className="d-flex justify-content-center">
                        <div className="almacenFormSinWidth">
                            <div className="almacenDivSinWidth">
                                <b className="ml-4">Clasificación</b>
                                <div className="input__Almacen">
                                    <select onChange={this.ClasificacionChange} autoFocus className="input__linea col-7" aria-haspopup="true" aria-expanded="false">
                                        
                                        <option defaultValue>- Seleccionar una clasificación -</option>
                                        {
                                            this.state.clasificaciones.clasificaciones.map((obj) => {
                                                
                                                return <option  key={obj.IdClasificacionConfiguracion} 
                                                                value={obj.IdClasificacionConfiguracion} 
                                                                onChange={this.ClasificacionChange} 
                                                        > {obj.Nombre} </option> 

                                            })      
                                        }
                                        <option value={0}>Precios</option>
                                    </select>
                                </div>
                            </div>
                            <div className="almacenDivSinWidth">
                        {/* <div className="col-12" > */}
                                {/* <div > */}
                                    {/* <br/> */}
                                    {/* <div id="tableProdPreventa" > */}
                                        <EmpresasFuncionalidadTabla
                                            state = {this.state}
                                            handleChange = {this.handleChange}
                                            handleChangeCheckBox = {this.handleChangeCheckBox}
                                            handleChangeList = {this.handleChangeList}
                                            handleChangeCheckBoxPrecio = {this.handleChangeCheckBoxPrecio}
                                        />
                                    {/* </div> */}
                                {/* </div> */}
                        {/* </div> */}
                            </div>
                        </div>
                    </form>
                    {/* </div> 
                </div>
                </div>*/}
                </React.Fragment>
            )
        }
    }
// }

export default EmpresasConfig;