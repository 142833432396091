import React from "react";

const _charges = [
    {id: "50", value: "OTROS CARGOS"},
    {id: "47", value: "OTROS CARGOS2"},
    {id: "51", value: "PERCEPCION VENTA INTERNA"},
    {id: "52", value: "PERCEPCION A LA ADQUISICION DE COMBUSTIBLE"},
    {id: "53", value: "PERCEPCION REALIZADA AL AGENTE DE PERCEPCION CON TASA ESPECIAL"},
    {id: "02", value: "RETENCIÓN"},
    {id: "01", value: "DETRACCIÓN"},

]

export default class ChargeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            charges: _charges,
            payload: {}
        }
    }

    render() {
        const {charges, payload} = this.state

        return (
            <>
                <div className="form-group mx-3">
                    <label>Tipo de cargo</label>
                    <select className="form-control" onChange={e => {
                        const val = e.target.value
                        this.setState((state, props) => {
                            return {
                                payload: {
                                    ...state.payload,
                                    chargeCod: val
                                }
                            }
                        }, () => this.props.onChange(this.state.payload))
                    }}>
                        <option value="-1">Seleccionar</option>
                        {charges.map(c => <option key={c.id} value={c.id}>{c.value}</option>)}
                    </select>
                </div>
                <div className="form-group mx-3">
                    <label>Porcentaje</label>
                    <input className="form-control" onChange={e => {
                        const val = e.target.value
                        this.setState((state, props) => {
                            return {
                                payload: {
                                    ...state.payload,
                                    factor: val
                                }
                            }
                        }, () => this.props.onChange(this.state.payload))
                    }} type="number" value={payload.factor || ""}/>
                </div>
                <button onClick={this.props.onOk} className="btn btn-outline-primary mx-3 mb-3">Ok</button>
            </>
        );
    }
}
