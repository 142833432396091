import React from "react";

export default class SucursalPicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sucursales: [],
            IdSucursal: "",
        }
        this.onSelectSucursal = this.onSelectSucursal.bind(this);
        this.fetchSucursales = this.fetchSucursales.bind(this);
    }

    componentDidMount() {
        this.fetchSucursales();
    }

    async fetchSucursales() {
        this.setState({isLoading: true});
        fetch('/api/sucursales/SucursalEmpresa')
            .then(res => res.json())
            .then(data => this.setState({sucursales: data}))
            .finally(() => this.setState({isLoading: false}))
    }

    onSelectSucursal(e) {
        this.setState({IdSucursal: e.target.value})
        this.props.onChangeSucursal(e.target.value)
    }

    render() {
        return (
            <div className="form-group">
                <label>Seleccione sucursal</label>
                <select className="form-control"
                        disabled={this.state.isLoading}
                        onChange={this.onSelectSucursal}
                        value={this.state.IdSucursal}>
                    <option key={`opt_none`} value="">Seleccionar</option>
                    {this.state.sucursales.map(s => <option key={`opt_${s.IdSucursal}`}
                                                            value={s.IdSucursal}>{s.Nombre}</option>
                    )}
                </select>
            </div>
        );
    }
}
