import React from "react";
import {defaultGetHeaders, pfetch} from "../helpers/wrappers";
import {notificarError} from "./Almacenes/AlmacenNotify";

export default class MonedaPicker extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            IdMoneda: 1,
            monedas: [],
            isLoading: false
        }
    }

    componentDidMount() {
        pfetch({
            fetch: {
                url: "/api/presentaciones/monedas",
                headers: defaultGetHeaders()
            },
            before: () => this.setState({isLoading: true}),
            then: (d) => this.setState({monedas: d.respuesta}, () => {
                this.props.onSelectMoneda(this.state.monedas.find(x => String(x.IdMoneda) === "1"))
            }),
            catch: () => notificarError("Ha ocurrido un error al momento de listar las monedas"),
            finally: () => this.setState({isLoading: false})
        })
    }

    render() {
        if (this.state.isLoading)
            return "Cargando monedas"
        return (
            <div>
                <label>Moneda</label>
                <select className="ml-2" onChange={e => {
                    this.props.onSelectMoneda(this.state.monedas.find(x => String(x.IdMoneda) === String(e.target.value)))
                }}>
                    {this.state.monedas.map(m => <option key={m.IdMoneda} value={m.IdMoneda}>{m.Abreviatura}</option>)}
                </select>
            </div>
        );
    }

}
