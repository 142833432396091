import {useSelector, useDispatch} from "react-redux";
import {guardar_sesion} from "../redux/actions"

const useGetSesion = async () => {

    const dispatch = useDispatch();

    // Obtener sesión de Redux
    const sesion = useSelector(state => state.sesion);

    // Salir si ya hay una sesion o si se está cargando
    if (sesion.isLoggedIn !== null || sesion.loading === true)
        return sesion

    // Avisar que se está cargando la sesión
    dispatch(guardar_sesion({loading: true}));

    // Obtener sesión
    const respuesta = await fetch("/api/auth/sesion");

    let sesionReceived = await respuesta.json();
    window.localStorage.setItem('extraConf', JSON.stringify(sesionReceived.conf))

    // Comprobar si la sesión está logueada
    const isLoggedIn = (sesionReceived.error === undefined);
    if (!isLoggedIn)
        sesionReceived = {}

    // Guardar sesión en Redux
    const newSesion = {...sesionReceived, isLoggedIn, loading: false}
    dispatch(guardar_sesion(newSesion));

    return newSesion;

}

export default useGetSesion;
