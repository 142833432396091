import "react-moment";
import moment from "moment";

export function getDataExportExcel(data, titulo) {
    const zero = Number(0).toLocaleString("en", {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6
    });


    return [
        {
            columns: [
                {
                    title: titulo
                }
            ],
            data: [
                [],
                [
                    {
                        value: "PERIODO:",
                        style: {font: {bold: true}}
                    },
                    `${moment(data.FechaInicio).format("DD/MM/YYYY")} - ${moment(data.FechaFin).format("DD/MM/YYYY")}`
                ],
                [
                    {
                        value: "RUC:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    `${data.RUC}`
                ],
                [
                    {
                        value: "APELLIDOS Y NOMBRES, DENOMINACIÓN O RAZÓN SOCIAL:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null, null,
                    {
                        value: `${data.RazonSocial}`,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}}
                        }
                        //font:{color:{ rgb: "2E2EFE" }} color de  letra
                    }
                ],
                [
                    {
                        value: "ESTABLECIMIENTO:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.Establecimiento}`
                    }
                ],
                [
                    {
                        value: "CÓDIGO DE LA EXISTENCIA:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.CodigoProducto}`
                    }
                ],
                [
                    {
                        value: "TIPO (TABLA 5):",
                        style: {font: {bold: true}}
                    }, null, null,
                    {
                        value: `${data.CodigoExistencia} ${data.Descripcion}`
                    }
                ],
                [
                    {
                        value: "DESCRIPCIÓN:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.nombreProducto}`
                    }
                ],
                [
                    {
                        value: "CÓDIGO DE LA UNIDAD DE MEDIDA:",
                        style: {
                            font: {bold: true}
                        }
                    },
                    null, null,
                    {
                        value: `${data.CodigoUnidad}`
                    }
                ],
                [
                    {
                        value: "MÉTODO DE EVALUACIÓN:",
                        style: {
                            font: {bold: true}
                        }
                    }
                ]
            ]
        },
        {//ySteps: -1,
            columns:
                [
                    {title: ""},
                    {
                        title: "",
                        width: {wpx: 150}
                    },
                    {title: ""},
                    {
                        title: "",
                        width: {wpx: 100}
                    },
                    {
                        title: "",
                        width: {wpx: 100}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    },
                    {
                        title: "",
                        width: {wpx: 120}
                    }
                ],
            data:
                [
                    [
                        {
                            value: "               DOCUMENTO DE TRASLADO, COMPROBANTE DE PAGO, ",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null, null, null,
                        {
                            value: "          TIPO DE ",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                                          ENTRADAS",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                                     SALIDAS",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "                             SALDO FINAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null,
                        {
                            value: "",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {left: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        }
                    ],
                    [
                        {
                            value: "                                DOCUMENTO INTERNO O SIMILAR",
                            style: {
                                fill: {patternType: "solid", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }, null, null, null,
                        {
                            value: "      OPERACIÓN",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "       CANTIDAD",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "  COSTO UNITARIO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        },
                        {
                            value: "   COSTO TOTAL",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true},
                                border: {bottom: {style: "thin", color: {rgb: "FFFFFFFF"}}}
                            }
                        }
                    ],
                    [
                        {
                            value: "       FECHA",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "      TIPO (TABLA 10)",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "         SERIE",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "       NÚMERO",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        },
                        {
                            value: "       (TABLA 12)",
                            style: {
                                fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                                font: {bold: true}
                            }
                        }
                    ]
                ]
        },
        {
            // xSteps: 1, // Will start putting cell with 1 empty cell on left most
            // ySteps: 5, //will put space of 5 rows,
            ySteps: -1,
            columns: [],
            data: data.dataVentas.items.map(row => {
                return [
                    {
                        value: moment(row.FechaEmision).format("DD/MM/YYYY"),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                            //font:{color:{ rgb: "2E2EFE" }} color de  letra
                        }
                    },
                    {
                        value: row.TipoDocumento,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "center"}
                        }
                    }/*,{}*/,
                    {
                        value: row.Serie || "",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NumeroComprobante || "",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoOperacion,
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            //   border: {top:{style: "thin", color: "#2E2EFE" }}
                            alignment: {horizontal: "center"}

                        }
                    },
                    {
                        value: row.CantidadEntrada.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.CostoUnitarioEntrada.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.CostoTotalEntrada.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.CantidadSalida.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: isNaN(row.CostoUnitarioSalida) ? zero : row.CostoUnitarioSalida.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: isNaN(row.CostoTotalSalida) ? zero : row.CostoTotalSalida.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: row.CantidadSaldo.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: isNaN(row.CostoUnitarioSaldo) ? zero : row.CostoUnitarioSaldo.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: isNaN(row.CostoTotalSaldo) ? zero : row.CostoTotalSaldo.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                ]
            })
        },
        {
            xSteps: 4,
            ySteps: -1,
            columns: [],
            data: [
                [
                    {
                        value: "        TOTALES",
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "FFFFFF"}},
                            font: {bold: true}
                        }
                    },
                    {
                        value: data.TotalCantidadEntradas.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null,
                    {
                        value: data.TotalCostoTotalEntradas.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    },
                    {
                        value: data.TotalCantidadSalidas.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null,
                    {
                        value: isNaN(data.TotalCostoTotalSalidas) ? zero : data.TotalCostoTotalSalidas.toLocaleString("en", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6
                        }),
                        style: {
                            fill: {patternType: "none", fgColor: {rgb: "F6F8FA"}},
                            alignment: {horizontal: "right"}
                        }
                    }, null, null, null,
                ]
            ]
        },
        {
            columns: [],
            data: [
                [
                    {
                        value: "(1) Dirección del Establecimiento o Código según el Registro Único de Contribuyentes.",
                        style: {
                            font: {bold: true}
                        }
                    }
                ]
            ]
        }
    ]
}
